import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import {VideoCameraTwoTone} from '@ant-design/icons';
import { MY_COLOR_TABLE } from "../../utils/size_constants";
import docuutil from "../../utils/DocuUtil";
import utilfunc from "../../utils/utilfunc";
import DragResize from "./DragResize";
import { useTranslation } from "react-i18next";
import vert_sign from "../../../assets/images/vert_sign.png";
//import sign_area from '../../../assets/images/tooltip1.png';
import sign_area from '../../../assets/images/SIGN AREA1.png';

// image위에 sign에 관련된 것을 overlay하여 그린다.
// 다음 세가지 경로에서 불릴 수 있음
// 1. owner가 sign할 위치를 지정할 때 (ProcSetPos-->DrawSignPos)
// 2. 실제로 sign할 때 (ProcOutReview-->DrawSignPos-->SignPosButton)
// 3. 문서를 보여줄 때 (ShowDocu-->DrawSignPos)

function DrawSignPos(props) {
	const { t } = useTranslation();
	const [SignPosList, setSignPosList] = useState([]);

	useEffect(() => {
		const poslist = [];
		for (let _rcptidx = 0; _rcptidx < props.RecipientList.length; _rcptidx++) {
			const rcpt = props.RecipientList[_rcptidx];
			const pos_info_list = rcpt.pos_info;
			const is_mine = (_rcptidx===props.MyIndex);
			for (let k = 0; k < pos_info_list.length; k++) {
				const pos_info = pos_info_list[k];
				const [xpos, ypos, sign_height_px] = docuutil.pdf_posinfo_to_image_pos(pos_info, props.img_display_height);		// pdf coord --> screen coordinate
				//const vsign_url = rcpt.vsign_url;
				//console.log('----------->SignPosList sign_height_px=', props.img_display_height, sign_height_px)
				const sign_id = utilfunc.sign_to_id(rcpt.sign_url);
				poslist.push({ rcptidx:_rcptidx, xpos, ypos, posindex: k, sign_url: rcpt.sign_url, sign_height_px: sign_height_px, signed:pos_info.signed, vsign_url:rcpt.vsign_url, sign_id:sign_id, is_mine });		//posindex는 각 recipient마다 새로 시작하는 pos_info내의 인덱스
			}
		}
		setSignPosList(poslist);
	}, [props.RecipientList, props.img_display_height]);

	// 이미 sign이 된 경우에는 sign된 것을 그려줌
	function case_signed(signpos, index) {
		const style={
			//display: 'flex',
			// justifyContent:'center',
			// alignItems: 'center',
			// alignContent:'top left'
			position:'absolute', 
			top:`${signpos.ypos}px`, 
			left:`${signpos.xpos}px`, 
			height:`${signpos.sign_height_px}px`, 
			margin:'auto', 
		};
		console.log('------------', signpos.vsign_url);
		return (
			<div key={index} style={style}>
				{signpos.is_mine && !signpos.signed && <div style={{ position: "absolute", top: `${-36}px`}}>
					<img src={sign_area} style={{height:'36px'}}/> 
				</div>}

				{/* <img src={signpos.sign_url} style={{height:'100%', width:'100%', objectFit:'contain', display:'flex'}} alt='a'/> */}
				<div  style={{height:'100%', width:'100%', objectFit:'contain', display:'flex'}}>
					{/* <div style={{height:'100%', width:'10px', backgroundColor:'rgba(15, 64, 112, 1)', writingMode:'vertical-rl', fontSize:'8px', color:'white', lineHeight:'10px'}}>Sign</div> */}
					<img src={vert_sign} style={{height:'100%', width:'100%'}} alt='a'/>
					<img src={signpos.sign_url} style={{height:'100%', width:'100%'}} alt='a'/>
				</div>
				<div style={{bottom: -15, position: 'absolute', whiteSpace: 'nowrap',}}>
					<div style={{fontSize:'10px', fontWeight:'bold'}}>{signpos.sign_id}</div>
				</div>
				<div style={{top: -15, position: 'absolute', whiteSpace: 'nowrap',}}>
					{ signpos?.vsign_url && signpos.vsign_url.length>4 ?
						<div style={{fontSize:'10px', fontWeight:'bold'}}>{t("Vidio Signed by")} <VideoCameraTwoTone/></div> :
						<div style={{fontSize:'10px', fontWeight:'bold'}}>{t("Vidio Signed by")}</div>
					}
				</div>
			</div>
		);
	}

	// function case_unsigned(signpos, index, rcpt_idx){
	// 	const style={
	// 		display: 'flex',
	// 		justifyContent:'center',
	// 		alignItems: 'center',
	// 		position:'absolute', 
	// 		top:`${signpos.ypos}px`, 
	// 		left:`${signpos.xpos}px`, 
	// 		height:`${signpos.sign_height}px`, 
	// 		margin:'auto', 
	// 		alignContent:'center'};
	// 	const tcolor = MY_COLOR_TABLE[rcpt_idx % 8];

	// 	return (
	// 		<div key={index} style={style}>
	// 			<img 
	// 				src={signpos.sign_url} 
	// 				style={{height:'100%', width:'100%', objectFit:'contain'}}
	// 			/>
	// 			<div style={{right: 10, position: 'absolute', backgroundColor:tcolor}}>
	// 				{signpos.rcptidx+1}번째 서명자
	// 			</div>
	// 		</div>
	// 	)
	// }

	// function changePosHeight(rcpt_idx, index, clientX, clientY, height) {
	// 	props.changePosHeight(rcpt_idx, index, clientX, clientY, height);
	// }

	return (
		<div>
			{SignPosList && SignPosList.map((signpos, index) => {
				//signpos.index는 recipient index를 나타냄
				//console.log('signpos---->', signpos);
				//console.log('DrawSignPos:img_display_height=', props.img_display_height, signpos);
				//console.log('DrawSignPos:sign_url.length', signpos.sign_url?.length);
				const rcpt_idx = signpos.rcptidx;
				if (props.onClickDelete) {		// owner가 sign 위치를 선정하는 경우 (ProcSetPos에서 call 할 때만 이 조건을 만족시킴. ProcReview에서 call할 때는 이 조건이 만족안됨)
					const tcolor = MY_COLOR_TABLE[rcpt_idx % 8];
					const show_edit = props.RecipientList && props.RecipientList[rcpt_idx].is_owner;
					// delete X button 과 sign 위치를 보여주는 이미지
					return (
						<div key={index} >
							{!show_edit &&   <div style={{ position: "absolute", top: `${signpos.ypos-36}px`, left: `${signpos.xpos}px` }}>
												<img src={sign_area} style={{height:'36px'}}/> 
											</div>}
							<div style={{ position: "absolute", top: `${signpos.ypos}px`, left: `${signpos.xpos}px` }}>

								<DragResize 
									init_height={signpos.sign_height_px} 
									sign_src = {signpos.sign_url}
									rcpt_idx={rcpt_idx} 
									posindex={signpos.posindex} 
									changePosHeight={props.changePosHeight}
									show_edit = {show_edit}
									backcolor = {tcolor}
									onClickDelete = {props.onClickDelete}
									onClickChangeSign = {props.onClickChangeSign}
									isMobile ={props.isMobile}
								/>
							</div>
						</div>
					)

				} else {		// 실제 signing을 해야 하는 위치인 경우
					//return case_unsigned(signpos, index)
					return case_signed(signpos, index);
				}
			})}
		</div>
	)

}

export default withRouter(DrawSignPos);

import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

function TermsPage() {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <div className="contentPages content">
      <div className="container">
        <Title className="main__title">{t("Terms and Conditions")}</Title>
        <p>{t("These Terms govern access to and use of the VidioSign (“VidioSign”, “we”, “our” or “us”) websites, products, and services (collectively, the “Site”) by individuals or entities who are any of the following (collectively, “you” or “your”):")}</p>
        <ul>
          <li>{t("general website visitors to VidioSign.com and/or other URLs owned or operated by VidioSign (each a “Website Visitor”);")}</li>
          <li>{t("subscribers or licensees to one or more of our products or services (“VidioSign Services”) (each, a “Customer”); and")}</li>
          <li>{t("users of VidioSign Services, including authorized users of Customer accounts and other VidioSign Service end users (each, a “User”).")}</li>
        </ul>
        <p>{t("By using the Site, you accept these Terms (whether on behalf of yourself or a legal entity you represent).")}</p>
        <p>{t("If you are a Customer and have a Master Services Agreement or other similar agreement in effect with VidioSign (the “Corporate Terms”), then these Terms apply to the extent your use of the Site, including any of the VidioSign Services referenced in the Service Schedules below, is not already governed by such Corporate Terms. These Terms are intended to govern agreements with business or corporate Customers. These Terms shall not apply to you if you wish to obtain VidioSign Services for personal use as a consumer (as defined by applicable consumer protection laws) and you shall not be entitled to avail yourself to any rights herein.")}</p>
        <p>{t("BY ACCESSING, USING, OR DOWNLOADING ANY MATERIALS FROM THE SITE, YOU AGREE TO FOLLOW AND BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, YOU ARE NOT AUTHORIZED AND MUST CEASE USING THE SITE IMMEDIATELY.")}</p>

        <h3>1. {t("UPDATES AND COMMUNICATIONS")}</h3>
        <p><strong>1.1</strong> {t(".We may revise these Terms, including changing, deleting, or supplementing with additional terms and conditions from time to time in our sole discretion, including to reflect changes in applicable law. We will post the revised terms on the Site with a “last updated” date. PLEASE REVIEW THIS WEBSITE ON A REGULAR BASIS TO OBTAIN TIMELY NOTICE OF ANY REVISIONS. IF YOU CONTINUE TO USE THE SITE OR THE VIDIOSIGN SERVICES AFTER THE REVISIONS TAKE EFFECT, YOU AGREE TO BE BOUND BY THE REVISED TERMS. You agree that we shall not be liable to you or to any third party for any revision to the Terms.")}</p>
        <p><strong>1.2.</strong> {t("You agree to receive all communications, correspondences, and notices that we provide in connection with our Site, including any VidioSign Services, including, but not limited to, marketing and promotional messages related to us or the VidioSign Services, correspondence regarding our delivery of the VidioSign Services, and providing you information related to your purchase of or subscription to the VidioSign Services (“Communications”), via electronic means, including by e-mail, text, in-product notifications, push notifications, or by posting them on or making them otherwise available through the Site. To the fullest extent permitted under applicable laws, you agree that all Communications we provide to you electronically satisfy any legal requirement that such Communications be in writing or be delivered in a particular manner and you agree to the extent you are a Customer to keep your Account contact information current. At any time, you can opt out of our email marketing and promotional messages sent to you by unsubscribing.")}</p>

        <h3>2. {t("OWNERSHIP")}</h3>
        <p><strong>2.1. {t("Intellectual Property.")}</strong> {t("The Site contains materials that are proprietary and are protected by copyright, trademarks, service marks, patents, and other intellectual property laws and treaties.")}</p>
        <p><strong>2.2. {t("License Restrictions.")}</strong> {t("Information, including software that may be available for downloading through the Site or third-party websites or applications (“Software”) is the copyrighted work of VidioSign, its licensors, and/or such other respective third-party providers. Use of the Software is governed by these Terms and such license and/or other terms as may be required directly of you by the third-party providers. Unauthorized reproduction or distribution of the Software is expressly prohibited by law, and may result in civil and criminal penalties. Violators may be prosecuted.")}</p>
        <p><strong>2.3. {t("Content Screening & Removal.")}</strong> {t("You acknowledge and agree that we may or may not, at our discretion, pre-screen User Content before its appearance on the Site, but that we have no obligation to do so. You further acknowledge and agree that we reserve the right (but do not assume any obligation) in our sole discretion to reject, move, edit, or remove any User Content that is Posted to the Site. Without limiting the foregoing, we have the right to remove any User Content that violates these Terms or is otherwise objectionable in our sole discretion. You acknowledge and agree that we do not verify, adopt, ratify, or sanction User Content, and you agree that you must evaluate and bear all risks associated with your use of User Content or your reliance on the accuracy, completeness, or usefulness of User Content.")}</p>

        <h3>3. {t("RESTRICTIONS ON USE OF THE SITE")}</h3>
        <p><strong>3.1.</strong> {t("By using the Site, you specifically agree not to engage in any activity or transmit any information that, in our sole discretion:")}</p>
        <p><strong>3.1.1.</strong> {t("Is illegal, or violates any federal, state, or local law or regulation;")}</p>
        <p><strong>3.1.2.</strong> {t("Advocates illegal activity or discusses illegal activities with the intent to commit them;")}</p>
        <p><strong>3.1.3.</strong> {t("Violates any third-party right, including, but not limited to, right of privacy, right of publicity, copyright, trademark, patent, trade secret, or any other intellectual property or proprietary rights;")}</p>
        <p><strong>3.1.4.</strong> {t("Is harmful, threatening, abusive, harassing, tortious, indecent, defamatory, sexually explicit or pornographic, discriminatory, vulgar, profane, obscene, libelous, hate speech, violent or inciting violence, inflammatory, or otherwise objectionable;")}</p>
        <p><strong>3.1.5.</strong> {t("Interferes with any other party’s use and enjoyment of the Site;")}</p>
        <p><strong>3.1.6.</strong> {t("Attempts to impersonate another person or entity;")}</p>
        <p><strong>3.1.7.</strong> {t("Is of a commercial nature in a way that violates these Terms, including but not limited to, using the Site for spam, surveys, contests, pyramid schemes, or other advertising materials;")}</p>
        <p><strong>3.1.8.</strong> {t("Falsely states, misrepresents, or conceals your affiliation with another person or entity;")}</p>
        <p><strong>3.1.9.</strong> {t("Accesses or uses a VidioSign Services account of a Customer without such Customer’s permission;")}</p>
        <p><strong>3.1.10.</strong> {t("Distributes computer viruses or other code, files, or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or electronic communications equipment;")}</p>
        <p><strong>3.1.11.</strong> {t("Interferes with, disrupts, disables, overburdens, or destroys the functionality or use of any features of the Site, or the servers or networks connected to the Site;")}</p>
        <p><strong>3.1.12.</strong> {t("“Hacks” or accesses without permission our proprietary or confidential records, those of another user, or those of anyone else;")}</p>
        <p><strong>3.1.13.</strong> {t("Improperly solicits personal or sensitive information from other users including without limitation address, credit card or financial account information, or passwords;")}</p>
        <p><strong>3.1.14.</strong> {t("Decompiles, reverse engineers, disassembles, or otherwise attempts to derive source code from the Site, except as expressly permitted in these Terms or by law, unless and then only to the extent permitted by applicable law without our consent;")}</p>
        <p><strong>3.1.15.</strong> {t("Removes, circumvents, disables, damages, or otherwise interferes with security-related features, or features that enforce limitations on use of the Site;")}</p>
        <p><strong>3.1.16.</strong> {t("Uses automated or manual means to violate the restrictions in any robot exclusion headers on the Site, if any, or bypasses or circumvents other measures employed to prevent or limit access, for example by engaging in practices such as “screen scraping,” “database scraping,” or any other activity with the purpose of obtaining lists of users or other information;")}</p>
        <p><strong>3.1.17.</strong> {t("Modifies, copies, scrapes or crawls, displays, distributes, publishes, licenses, sells, rents, leases, lends, transfers, or otherwise commercializes any materials or content on the Site;")}</p>
        <p><strong>3.2.</strong> {t("You may not frame the Site, place pop-up windows over its pages, or otherwise affect the display of its pages. You may link to the Site, provided that you acknowledge and agree that you will not link the Site to any website containing any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful topic, name, material, or information or that violates any intellectual property, proprietary, privacy, or publicity rights. Any violation of this provision may, in our sole discretion, result in termination of your use of and access to the Site effective immediately.")}</p>
        <p><strong>3.3.</strong> {t("You acknowledge that we have no obligation to monitor your – or anyone else’s – access to or use of the Site for violations of these Terms, or to review or edit any content. However, except as otherwise expressly provided herein, we have the right to do so for the purpose of operating and improving the Site (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes, analytics, and advertising), to ensure your compliance with these Terms and to comply with applicable law or the order or requirement of a court, consent decree, administrative agency or other governmental body.")}</p>

        <h3>4. {t("PRIVACY")}</h3>
        <p><strong>4.1. {t("VidioSign Privacy Notice.")}</strong> {t("You acknowledge your understanding and hereby provide consent that, except as described in these Terms or applicable Corporate Terms, the information you provide to us or that we collect will be processed and otherwise handled as described in the VidioSign Privacy Notice.")}</p>
        <p><strong>4.2. {t("Access & Disclosure.")}</strong> {t("Except as described in these Terms or applicable Corporate Terms: (i) we may access, preserve, or share any of your information when we believe in good faith that such sharing is reasonably necessary to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process (e.g. a subpoena or other legal process); and (ii) we may also share your information in situations involving potential threats to the physical safety of any person, violations of the VidioSign Privacy Notice, the Terms, the Corporate Terms, or any other of our user agreements or terms in effect; or to respond to the claims of violation of the rights of third parties and/or to protect the rights, property and safety of VidioSign, our employees, users, or the public. This may involve the sharing of your information with law enforcement, government agencies, courts, and/or other organizations. Further information on how we process personal data may be found in the VidioSign Privacy Notice.")}</p>

        <h3>4.3. {t("Data.")}</h3>
        <p><strong>4.3.1. {t("Data Generally.")}</strong> {t("You shall be responsible for data that you provide or use in respect of the Site (including VidioSign Services). You are solely responsible for determining the suitability of use of the Site for your business or organization and complying with any regulations, laws, or conventions applicable to the data you provide and your use of the Site.")}</p>
        <p><strong>4.3.2. {t("Personal Data.")}</strong> {t("You warrant that collection and use of any personal information or data you provide while using the Site complies with all applicable data protection laws, rules, and regulations. You acknowledge your understanding and hereby provide your consent that we may process such personal data in accordance with the VidioSign Privacy Notice, except as permitted additionally in these Terms.")}</p>
        <p><strong>4.3.3. {t("Usage Data.")}</strong> {t("We may collect and use data, information, or insights generated or derived from the use of the Site (“Usage Data”) for our business purposes, including industry analysis, benchmarking, analytics, marketing, and developing, training and improving its products and services. Before doing so, VidioSign will de-identify and anonymize all Usage Data in such a manner that does not allow for the identification of Users, Customer Data, or Customer’s Confidential Information, and will disclose such Usage Data in aggregate form only.")} </p>
        <p><strong>4.4. {t("Site Visitor.")}</strong> {t("You hereby represent and warrant to us that: (a) you have all requisite rights and authority to use the Site under these Terms and to grant all applicable rights herein; (b)  any information you submit to us (including Posting to the Site) is true, accurate, and correct; and (c) you will not attempt to gain unauthorized access to the Site, computer systems, or networks under the control or responsibility of us through hacking, cracking, password mining, or any other unauthorized means.")}</p>

        <h3>5. {t("CONFIDENTIALITY.")}</h3>
        <p><strong>5.1. {t("Scope.")}</strong> {t("Our “Confidential Information” means (a) any written information, materials and other documents supplied by us related to the Site which we do not generally disclose publicly, (b) the VidioSign Services themselves, excluding any data you upload to the VidioSign Services for processing;  and (c) any other of our information that we may disclose in writing or orally and is designated as confidential or proprietary at the time of disclosure, or that due to the nature of the information a reasonable person would clearly understand it to be confidential information; and (d) any amendment to the terms and conditions of these Terms between you and us. Confidential Information shall not include any information that: (i) was or becomes generally known to the public through no fault or breach of these Terms you; (ii) was rightfully in your possession at the time of disclosure without restriction on use or disclosure; (iii) was independently developed by you without use of our Confidential Information; or (iv) was rightfully obtained by you from a third party not under a duty of confidentiality and without restriction on use or disclosure.")}</p>
        <p><strong>5.2. {t("Restricted Use and Nondisclosure.")}</strong> {t("During and after the term, with respect to our Confidential Information you will: (a) use our Confidential Information solely for the purpose for which we provided it; (b) not disclose such Confidential Information to a third party, except on a need-to-know basis to your affiliates, attorneys, auditors, consultants, and service providers who are under confidentiality obligations at least as restrictive as those contained herein; and (c) protect such Confidential Information from unauthorized use and disclosure to the same extent (but using no less than a reasonable degree of care) that you protect your own Confidential Information of a similar nature.")}</p>
        <p><strong>5.3. {t("Required Disclosure.")}</strong> {t("If you are required by law to disclose our Confidential Information, you will give us prompt written notice before making the disclosure, unless prohibited from doing so by the legal or administrative process, and assist us to obtain where reasonably available an order protecting our Confidential Information from public disclosure.")}</p>
        <p><strong>5.4. {t("Ownership.")}</strong> {t("Notwithstanding any other provision of these Terms, you acknowledge that, as between you and us, all our Confidential Information you receive from us, including all copies thereof in your possession or control, in any media, is proprietary to and exclusively owned by us. Nothing in these Terms grants you any right, title or interest in or to any of our Confidential Information, except as provided in these Terms. Any incorporation of our Confidential Information into any of your own materials will not render our Confidential Information non-confidential.")}</p>

        <h3>6. {t("TERM AND TERMINATION")}</h3>
        <p><strong>6.1. {t("Termination.")}</strong> {t("You may terminate your use of the Site at any time by ceasing further use of the Site.  VidioSign may terminate your use of the Site as a Website Visitor, including denying you access to the Site at our sole discretion for any reason or no reason, including for violation of these Terms. Termination of Customer’s use of the VidioSign Services is as set out in the Service Schedules below.")}</p>
        <p><strong>6.2. {t("Effect of Termination.")}</strong> {t("If these Terms expire or are terminated for any reason: (a) any and all of your liabilities to us that have accrued before the effective date of the expiration or termination will survive; (b) licenses and use rights granted to you with respect to the Site, including rights to any intellectual property therein or thereto, will immediately terminate in accordance with these Terms; (c) our obligation to provide any further access to the Site to you under these Terms will immediately terminate, except any such rights that are expressly to be provided following expiration or termination of these Terms; and (d) the provisions of Section 2 (Ownership), Section 3 (Restrictions on Use of the Site), Section 4 (Privacy), Section 5 (Confidentiality), Section 6.2 (Effect of Termination), Section 7 (Warranties and Disclaimers), Section 8 (Indemnification Obligations), Section 9 (Limitations of Liability), Section 10 (General), and Section 11 (Supplemental Terms in Certain Countries) will survive, as well as provisions designated to survive under any Service Schedules and accompanying attachments and Exhibits to these Terms.")}</p>

        <h3>7. {t("WARRANTIES AND DISCLAIMERS")}</h3>
        <p><strong>7.1.</strong> {t("THE SITE AND ANY INFORMATION WE SUPPLY YOU ARE PROVIDED “AS IS” AND “AS AVAILABLE”. YOUR USE OF THE SITE AND INFORMATION SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTEND LEGALLY POSSIBLE, VIDIOSIGN AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, SHAREHOLDERS, AGENTS, AFFILIATES, SUBSIDIARIES, AND LICENSORS (“VIDIOSIGN PARTIES”): (a) MAKE NO ADDITIONAL REPRESENTATION OR WARRANTY OF ANY KIND WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER; (b) EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, ACCURACY, AND TITLE; AND (c) DO NOT WARRANT THAT THE SITE OR INFORMATION ARE OR WILL BE ERROR-FREE, WILL MEET YOUR REQUIREMENTS, OR BE TIMELY OR SECURE. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE RESULTING FROM YOUR USE OF THE SITE AND/OR INFORMATION.")}</p>
        <p><strong>7.2.</strong> {t("THE VIDIOSIGN PARTIES MAKE NO WARRANTIES OR REPRESENTATIONS THAT THE SITE OR INFORMATION HAVE BEEN AND WILL BE PROVIDED WITH DUE SKILL, CARE AND DILIGENCE OR ABOUT THE ACCURACY OR THEREOF, AND ASSUME NO RESPONSIBILITY FOR ANY: (i) ERRORS, MISTAKES, OR INACCURACIES RELATED TO THE SITE OR INFORMATION; (ii) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE OR INFORMATION; (iii) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SITE OR INFORMATION, AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED ON THE SITE; (iv) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE; (v) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SITE THROUGH THE ACTIONS OF ANY THIRD PARTY; (vi) ANY LOSS OF YOUR DATA OR OTHER CONTENT FROM THE SITE; AND/OR (vii) ANY ERRORS OR OMISSIONS IN ANY OF YOUR DATA OR CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. YOU WILL NOT HAVE THE RIGHT TO MAKE OR PASS ON ANY REPRESENTATION OR WARRANTY ON BEHALF OF VIDIOSIGN TO ANY THIRD PARTY. BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN THAT EVENT, SUCH WARRANTIES ARE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY AND FOR THE MINIMUM WARRANTY PERIOD ALLOWED BY THE MANDATORY APPLICABLE LAW.")}</p>
        <p><strong>7.3.</strong> {t("THE VIDIOSIGN PARTIES DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, AND THE VIDIOSIGN PARTIES WILL NOT BE A PARTY TO OR IN ANY WAY RESPONSIBLE FOR MONITORING ANY ACTIVITIES BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR ACTIVITIES WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE.")}</p>
        <p><strong>7.4.</strong> {t("Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages such as in this Section 7 or below in Section 9. Accordingly, some of these limitations may not apply to you. If you are a New Jersey resident, or a resident of another state that permits the exclusion of these warranties and liabilities, then the limitations in Section 7 and Section 9 specifically do apply to you.")}</p>

        <h3>8. {t("INDEMNIFICATION OBLIGATIONS")}</h3>
        <p><strong>8.1.</strong> {t("You will defend, indemnify, and hold us, our Affiliates, officers, directors, employees, suppliers, consultants, and agents harmless from any and all third-party claims, liability, damages, and costs (including, but not limited to, attorneys' fees) arising from or related to, as applicable: (a) your access to and use of the Site (including the VidioSign Services); (b) violation of these Terms by you or; (c) infringement of any intellectual property or other right of any person or entity by you; (d) the nature and substance of all documents, data, or other content uploaded by you to the Site (including the VidioSign Services); or (e) any products or services purchased or obtained by you in connection with the Site (including the VidioSign Services).")}</p>
        <p><strong>8.2.</strong> {t("We retain the exclusive right to settle, compromise, and pay, without your prior consent, any and all claims or causes of action that are brought against us. We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter in which we are named as a defendant and/or for which you have indemnity obligations without our prior written consent. We will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.")}</p>

        <h3>9. {t("LIMITATIONS OF LIABILITY")}</h3>
        <p><strong>9.1. {t("Disclaimer of Consequential Damages.")}</strong></p>
        <p><strong>9.1.1.</strong> {t("NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THESE TERMS, VIDIOSIGN WILL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE TO YOU FOR ANY DIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, COVER, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE USE OF THE SITE OR INFORMATION WE SUPPLY YOU, INCLUDING, BUT NOT LIMITED TO, GOODWILL, WORK STOPPAGE, LOST PROFITS, OR LOSS OF BUSINESS, EVEN IF APPRISED OF THE LIKELIHOOD OF SUCH LOSSES, AND WHETHER SUCH CLAIMS ARE MADE BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY.")}</p>
        <p><strong>9.1.2.</strong> {t("TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE VIDIOSIGN PARTIES BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY: (a) USE OF THE SITE OR INFORMATION WE SUPPLY YOU; (b) ERRORS, MISTAKES, OR INACCURACIES OF THE SITE OR INFORMATION; (c) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE OR INFORMATION; (d) ANY UNAUTHORIZED ACCESS TO OR USE OF THE SITE OR INFORMATION, AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED ON THE SITE; (e) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVERS; (f) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY; (g) ANY LOSS OF YOUR DATA OR USER CONTENT FROM THE SITE; (h) ANY ERRORS OR OMISSIONS IN ANY OF YOUR DATA OR USER CONTENT, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE VIDIOSIGN PARTIES ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND/OR (i) THE DISCLOSURE OF INFORMATION PURSUANT TO THESE TERMS, OUR PRIVACY NOTICE, OR ANY OTHER COMMUNICATION WE MAKE OR NOTICE WE PROVIDE.")}</p>
        <p><strong>9.2. {t("Cap on Damages.")}</strong> {t("OUR TOTAL LIABILITY TO YOU FOR ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THESE TERMS OR TO YOUR USE OF THE SITE OR INFORMATION WE SUPPLY YOU (INCLUDING WITHOUT LIMITATION WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE THEORY, WILL NOT EXCEED USD$100.  THE TOTAL AMOUNT PAID BY YOU TO VIDIOSIGN FOR THE VIDIOSIGN SERVICE(S) GIVING RISE TO THE CLAIM UNDER THESE TERMS DURING THE TWELVE (12) MONTH PERIOD PRECEDING THE DATE OF THE FIRST EVENT GIVING RISE TO LIABILITY, OR $100, WHICHEVER IS GREATER. THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE THIS LIMIT. ADDITIONAL OR DIFFERENT LIABILITY AMOUNTS MAY APPLY AS EXPRESSLY SET FORTH IN THE APPLICABLE SERVICE SCHEDULE BELOW. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE FOREGOING, THIS SECTION 9.2 SHALL (I) APPLY ONLY TO THE EXTENT PERMITTED BY PRODUCT LIABILITY LAW; AND (II) SHALL NOT APPLY IN THE EVENT THAT PERSONAL INJURY OR DEATH HAVE BEEN CAUSED INTENTIONALLY BY VIDIOSIGN OR BY VIDIOSIGN’S GROSS NEGLIGENCE.")} </p>
        <p><strong>9.3. {t("Independent Allocations of Risk.")}</strong> {t("Each provision of these Terms that provides for a limitation of liability, disclaimer of warranties, or exclusion of damages represents an agreed allocation of the risks of these Terms between the parties. This allocation is an essential element of the basis of the bargain between the parties. Each of these provisions is severable and independent of all other provisions of these Terms, and each of these provisions will apply even if the warranties in these Terms have failed of their essential purpose.")}</p>

        <h3>10. {t("GENERAL")}</h3>
        <p><strong>10.1. {t("Third Party Content.")}</strong> {t("We may provide, or third parties may provide, links to other third-party websites, services, or resources that are beyond our control. We make no representations as to the quality, suitability, functionality, or legality of any third-party content to which links may be provided, and you hereby waive any claim you might have against us with respect to such services. VIDIOSIGN IS NOT RESPONSIBLE FOR THE CONTENT ON THE INTERNET OR WEB PAGES THAT ARE LOCATED OUTSIDE THE SITE OR POSTS OF USER CONTENT. Your correspondence or business dealings with, or participation in promotions of, advertisers or partners found on or through the Site, including payment and delivery of related goods or services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such advertiser or partner. You agree that we are not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers or links to third-party websites or resources on the Site.")}</p>
        <p><strong>10.2. {t("Disclaimer.")}</strong> {t("VidioSign is not an attorney licensed to practice law in your country or region and does not provide legal advice or accept fees for legal advice. We do not verify the authenticity of the information provided by them. However, the person who uses this service believes that “VidioSign helps contractors to sign electronic contracts easily, adds an accurate block chain time stamp to the submitted information, and provides a service that converts contracts into NFTs.” I understand and agree. VidioSign does not verify any content, including whether the contract is illegal or false. In addition, we do not verify personal information such as the identity of the contracting party. You agree that it is the responsibility of the contractors to verify the contents of the contract and the personal information of the parties to the contract. VidioSign does not have the effect of notarization.")}</p>
        <p><strong>10.3. {t("Relationship.")}</strong> {t("At all times, you and we are independent contractors, and are not the agents or representatives of the other. These Terms are not intended to create a joint venture, partnership, or franchise relationship between the parties. Non-parties do not benefit from and cannot enforce these Terms. There are no third-party beneficiaries to these Terms. You must not represent to anyone that you are an agent of ours or are otherwise authorized to bind or commit us in any way without our prior written authorization.")}</p>
        <p><strong>10.4. {t("Assignability.")}</strong> {t("Unless such restriction is prohibited under applicable law, you may not assign your rights or obligations under these Terms without our prior written consent. If consent is given, these Terms will bind your successors and assigns. Any attempt by you to transfer your rights, duties, or obligations under these Terms except as expressly provided in these Terms is void. We may freely assign our rights, duties, and obligations under these Terms at any time, with or without notice to you.")}</p>
        <p><strong>10.5. {t("Notices.")}</strong> {t("Except as otherwise permitted by these Terms, any notice required or permitted to be given will be effective only if it is in writing and sent using: (a) VidioSign Services; (b) certified or registered mail; or (c) insured courier, to the appropriate party at the address set forth in your registration information or on the Site for VidioSign, with a copy, in the case of VidioSign, to [metas@metabox.land]. You or we may change our address for receipt of notice by notice to the other party in accordance with this Section 10.5. Notices are deemed given upon receipt if delivered using VidioSign Services, two (2) business days following the date of mailing, or one (1) business day following delivery to a courier.")}</p>
        <p><strong>10.6. {t("Force Majeure.")}</strong> {t("Except for any payment obligations, neither you nor we will be liable for failure to perform any obligation under these Terms to the extent such failure is caused by a force majeure event (including acts of God, natural disasters, war, civil disturbance, action by governmental entity, strike, and other causes beyond the party’s reasonable control). The party affected by the force majeure event will provide notice to the other party within a commercially reasonable time and will use commercially reasonable efforts to resume performance as soon as practicable. Obligations not performed due to a force majeure event will be performed as soon as reasonably possible when the force majeure event concludes.")}</p>
        <p><strong> 10.7. {t("Dispute Resolution.")}</strong> {t("Most disputes can be resolved without resort to arbitration. If you have any dispute with us, you agree that before taking any formal action you will contact us at [metas@metabox.land] and provide a brief, written description of the dispute and your contact information (including your username, if your dispute relates to a VidioSign Services account). Except for intellectual property, you and we agree to use reasonable efforts to settle any dispute, claim, question, or disagreement directly through consultation with us, and good faith negotiations shall be a condition to either party initiating a lawsuit or arbitration.")}</p>
        <p><strong>10.8. {t("Entire Terms.")}</strong> {t("These Terms, which include the language and paragraphs preceding Section 1, are the final, complete, and exclusive expression of the agreement between you and VidioSign regarding the VidioSign Services provided under these Terms. These Terms supersede and the parties disclaim any reliance on previous oral and written communications (including any confidentiality agreements pertaining to the Site (including the VidioSign Services) under these Terms) with respect to the subject matter hereof and apply to the exclusion of any other terms that you seek to impose or incorporate, or which are implied by trade, custom, practice or course of dealing. VidioSign hereby rejects any additional or conflicting terms appearing in a purchase order or any other ordering materials submitted by you and conditions assent solely based on these Terms and conditions of these Terms as offered by VidioSign. In the event of an inconsistency or conflict, the order of precedence in descending order of control is as follows: (a) the Subscription Plan; (b) any attachments or appendix(ices) to the Service Schedule(s); (c) the Service Schedule; and (d) these Terms.")}</p>
        <p><strong>10.9. {t("Severability.")}</strong> {t("If any part of these Terms is found to be illegal, unenforceable, or invalid, the remaining portions of these Terms will remain in full force and effect. If any material limitation or restriction on the grant of any license to you under these Terms is found to be illegal, unenforceable, or invalid, the license will immediately terminate.")}</p>
      </div>
    </div>
  );
}

export default TermsPage;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Row, Col, Card, Checkbox } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import docuutil from "../../utils/DocuUtil";
import { useSelector } from "react-redux";
import { USER_SERVER } from "../../../config";
import { useTranslation } from "react-i18next";
import ModalReject from "./Sections/ModalReject";

// /outsign/docuId/emailenc 로 접근하면 router를 통해서 이 페이지로 들어옴
// emailenc는 이 링크를 통해서 서명해야 할 사람의 contact identifier임
// url parsing을 통해서 얻어진 func는 초기 state를 결정하고 이후에는 reloading을 피하기 위해서 setstate를 사용.
function MainVideoConfirm(props) {
	const { t } = useTranslation();
	const { i18n } = useTranslation('Home');
	let user = useSelector((state) => state.user);
	const docuId = props.match.params.docuId;
	const emailenc = props.match.params.emailenc;

	//const [UserDbId, setUserDbId] = useState(null); //{db: User or OuterUser, userId: id}
	const [Docu, setDocu] = useState(null);
	const [MyIndex, setMyIndex] = useState(-1);
	const [Confirm, setConfirm] = useState(false);
	const [ModalRejectVisible, setModalRejectVisible] = useState(false);

	useEffect(() => {
		async function f() {
			const [dbid, docu, user_contact] = await get_docu();
	
			if (docu && user_contact){
				console.log('docuouter:get_docu------->', docu);
				const myidx = docuutil.get_my_index(docu, user_contact);
				//setUserDbId(dbid);
				setDocu(docu);
				setMyIndex(myidx);
			}
		}
		if (user.isLoaded) f();
	}, [user.isLoaded]);


	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 문서에 관한 정보를 읽어들임
	// result.data.rcpt_contact은 emailenc를 decoding한 결과임
	async function get_docu() {
		let dbid = null;
		let docu = null;
		let user_contact = null;

		const dataToSubmit = {
			docuId: docuId,
			emailenc: emailenc,
		};
		const res_docu = await axios.post("/api/docuouter/get_docu", dataToSubmit);

		if (res_docu.data.success) {
			const already_completion = check_already_completion(res_docu.data.docu, res_docu.data.rcpt_contact);
			if (already_completion) return [null, null, null];

			if (user.userData.isAuth) {
				if (res_docu.data.rcpt_contact === user.userData.email) {
					// 서명할 사람이 login한 상태라면
					//alert('The signer is logged in already.');
					dbid = { db: "User", userId: user.userData._id };
					docu = res_docu.data.docu;
					user_contact = { email: res_docu.data.rcpt_contact, phone: null };
				} else {
					// 서명할 사람의 id가 아닌 다른 사람으로 login 된 상태-->logout시키고 진행
					const res_swal1 = await Swal.fire({
											title: t("Caution!"),
											text: t("You are logged in with an ID other than the one to sign. Logout to continue."),
											icon: "question",
											showCancelButton: true,
											cancelButtonText: t("Cancel"),
											confirmButtonText: t("Logout and Proceed"),
										});
					if (res_swal1.isConfirmed) {
						const res_logout = await axios.get(`${USER_SERVER}/logout`);
						if (res_logout.status === 200) {
							alert(t("logout success"));
							window.location.reload();
						} else {
							throw new Error("Error: Logout failed!");
						}
					} else {
						//props.history.push('/');
						props.history.goBack();
					}
				}
			} else {
				// 아무도 login하지 않은 상태
				//alert('not loged');
				const rcpt_name = _get_rcpt_name(res_docu.data.docu, res_docu.data.rcpt_contact);
				const res_user = await axios.post("/api/docuouter/search_user_by_contact", { db: 'OuterUser', contact: res_docu.data.rcpt_contact, name: rcpt_name }								);
				if (res_user.data.success) {
					// todo
					dbid = { db: 'OuterUser', userId: res_user.data.userId };
					docu = res_docu.data.docu;
					user_contact = { email: res_docu.data.rcpt_contact, phone: null };
					console.log("UserDbId set !!!", { db: 'OuterUser', userId: res_user.data.userId });
				} else {
					throw new Error("cannot find user or cannot make outer user");
				}
			}
		} else {
			alert("get_docu: fail to get document information");
		}

		return [dbid, docu, user_contact];
	}

	function check_already_completion(docu, rcpt_contact) {
		const rcptlist = docu.recipient_list;

		// get myid
		let my_rcpt = null;
		for (let r=0; r<rcptlist.length; r++){
			const rcpt = rcptlist[r];
			if (rcpt.contact === rcpt_contact) {
				my_rcpt = rcpt;
				break;
			}
		}

		if (!my_rcpt) throw new Error('cannot find contact from recipient_list in docu');

		if (my_rcpt.video_confirm===1) {
			alert('You have already confirmed to videos for this contract.');
			props.history.push('/');
			return true;
		} else if (my_rcpt.video_confirm===2) {
			alert('You have already denied to confirm at least one of videos for this contract.');
			props.history.push('/');
			return true;
		} else {
			return false;
		}
	}

	// docu의 서명자 중에서 contact가 일치하는 서명자의 이름을 리턴
	function _get_rcpt_name(docu, contact) {
		const rcptlist = docu.recipient_list;
		for (let index = 0; index < rcptlist.length; index++) {
			if (rcptlist[index].contact === contact) {
				return rcptlist[index].name;
			}
		}
		return null;
	}
	

	const render = Docu?.recipient_list && Docu.recipient_list.map((rcpt, index) => {

		return (
			<Col lg={8} xl={6} md={12} key={index}>
				<Card className="videoConfirmCard" title={`${t("Contractor")}: ${rcpt.name} / ${rcpt.contact}`}>
					<video
						src = {rcpt.vsign_url}
						playsInline
						controls
					></video>
				</Card>
			</Col>
		)
	})

	async function onClickComfirm(){
		const rcpt = Docu.recipient_list[MyIndex];
		const rcptId = rcpt._id;
		const name = rcpt.name;
		const contact = rcpt.contact;
		const action = 'video_confirm';
		const ipaddr = 'unknown';
		const machine = 'unknown';
		const dataToSubmit = {docuId, rcptId, push_history:{ name, contact, action, ipaddr, machine }, lang:i18n.language}
		const res = await axios.post("/api/docuouter/video_confirm", dataToSubmit);
		if (res.data.success){
			alert(t("success"));
			props.history.push('/');
		} else {
			alert('Error: ', res.data.errmsg);
		}
	}

	async function onClickDeny(){
		setModalRejectVisible(true);
	}


	function onChangeConfirm(e) {
		setConfirm(e.target.checked);
	}

	function onCancelReject() {
		setModalRejectVisible(false);
	}

	async function onOkReject(value) {
		const name = Docu.recipient_list[MyIndex].name;
		const contact = Docu.recipient_list[MyIndex].contact;
		const action = 'reject_vconfirm';
		const ipaddr = 'unknown';
		const machine = 'unknown';

		const dataToSubmit = {
			docuId: Docu._id,
			myindex: MyIndex,
			reason: value,
			push_history: { name, contact, action, ipaddr, machine },
			lang : i18n.language
		}
		const res = await axios.post('/api/docuouter/reject_vconfirm', dataToSubmit);

		if (res.data.success) {
			Swal.fire({
				icon: "success",
				title: "Confirm Denied.",
				text: `${name} refused to confirm video for contract ${Docu.title}.`,
				confirmButtonText: t('Go to Home')
			});
			props.history.push('/');
		}
		return true;
	}

	return (
		<div>
			<div className="videoConfirmWrap">
				<div className="container">
					<Row gutter={20}>
						{render}
					</Row>
					<Row >
						<Checkbox onChange={onChangeConfirm} checked={Confirm}>{t("I've checked my and others' videos and I'm sure there's no problem getting the contract done.")}</Checkbox>
					</Row>
					<Row justify='center'>
						<Button type='button' className="primaryBtn" disabled={Confirm} onClick={onClickDeny} style={{marginRight:'20px'}}>{t("Deny")}</Button>
						<Button type='button' className="primaryBtn" disabled={!Confirm} onClick={onClickComfirm}>{t("Confirm")}</Button>
					</Row>

					<ModalReject visible={ModalRejectVisible} onCancel={onCancelReject} onOk={onOkReject} explain={t("You should make sure that your and other contractors' videos are OK. If you decline to confirm video, the contract will be terminated and the reason for the refusal will be sent to all signing participants.")}/>
				</div>
			</div>
		</div>
	);
}

export default withRouter(MainVideoConfirm);

import React from "react";
import { Modal, Tabs} from "antd";
import { withRouter } from "react-router-dom";
import GenerateStamp from "../GenerateStamp";
import BgRemoval from "./BgRemoval";
import { useTranslation } from "react-i18next";

function ModalStamp(props) {
  const { t } = useTranslation();
  const items =[
    {
        label:t("General Stamp"), 
        key:"1", 
        children:   <>
                    {t("Create stamps of various shapes and choose the one you like.")}
                    <GenerateStamp stampType='generic' onUpdate={props.onUpdate} UserDbId={props.UserDbId}/>
                    </>
    },
    {
        label:t("Corporate Stamp"), 
        key:"2", 
        children:   <>
                    {t("Create corporate stamps of various shapes and choose the one you like.")}
                    <GenerateStamp stampType='corporate' onUpdate={props.onUpdate} UserDbId={props.UserDbId}/>
                    </>
    },
    {
        label:t("Upload"), 
        key:"3", 
        children:  <BgRemoval onUpdate={props.onUpdate} sign_stamp='stamp' UserDbId={props.UserDbId}/>
    }

  ];

  return (
    <div>
        <Modal
            title = {t("Create My Stamp")}
            open={props.AddMode===2}
            centered ={true}
            cancelButtonProps={{ style: { display: "none" } }}
            okText="Enter"
            width={650}
            destroyOnClose={true}
            onCancel={props.onCancel}
            footer = {null}
            className="customPopup"
        >
            <Tabs className="changeSignInnerPopup" defaultActiveKey="1" items={items}/>
    	</Modal>
    </div>
  );
}

export default withRouter(ModalStamp);

import React, { useEffect } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";
import { getCookie } from "../../../cookie/cookie";
import { loginLineUser } from "../../../_actions/user_actions";
// import LineLogin from "./LineLogin";
// import { LINE_OAUTH_CLIENT_ID } from "../../../config";
// import querystring from 'querystring';

// line server에서 인증과정을 진행하여 성공하면 code와 함께 이 페이지로 옴
// 이 code를 backend에 전송해서 backend가 필요한 정보를 다시 line server에서 받아야 함.
// state=='xyzABC123' 이면 login과정을 진행하는 것이고, 'xyzABC12311'이면 register 과정임.
function LineLoginCallback(props) {
    //const register_or_login = props.match.params.register_or_login;
    const base_state = 'xyzABC123';
    const { t } = useTranslation();
    const { i18n } = useTranslation('Home');
    const dispatch = useDispatch();
    const query = new URLSearchParams(useLocation().search);
    const code = query.get("code");
    const state = query.get("state");

    console.log('query=', query);
    console.log('code=', code);
    console.log('state=', state);

    useEffect(()=>{
        async function asyncfunc() {
            let is_register_mode = false;
            if (!code) {
                return;
            }

            if (state===base_state) {
                is_register_mode = false;
            } else if (state.length===base_state.length+2) {
                is_register_mode = true;
            } else {
                return;
            }

            const loc = window.location.href.split('/');
            const redirect_uri = loc[0] + '//' + loc[2] + '/callback_linelogin';
            const body = {
                code: code,
                nonce: '4394',
                redirect_uri: redirect_uri,
                payload: state,
                lang: i18n.language
            }

            dispatch(loginLineUser(body)).then((response) => {
                respond_to_result(response);
                });
        }
        asyncfunc();
    }, [code, state]);

    function respond_to_result(response) {
        const resdata = response.payload;

        if (resdata.loginSuccess) {

            const token = getCookie('macAddress');
            const platform = getCookie('site-platform');

            if (token !== 'default-token') {
                const dataToSubmit = { _id: resdata.userId, macAddress: token, platform };
                axios.post("/api/users/updateUserById", dataToSubmit).then((response) => {
                    if (response.data.success) {
                        console.log('MacAddress added successfully!');
                    }
                });
            }

            // 처음 login인 경우에는 lastlogintime이 2000년임
            if (resdata.last_login_time.substr(0, 4) === "2000") {
                
                props.history.push({
                    pathname: "/thankyou",
                    search: "?username=" + resdata.username + "&userinfo=" + resdata.userinfo,
                });

            } else {

                let message = t("Logged in successfully.");
                Swal.fire(t("Success"), message, "success");
                props.history.push("/");
            }

        } else {
            let msg;
            if (resdata.errmsg==='user not found') {
                msg = 'We cannot find user.  If you didn\'t SignUp, please Signup First.';
            } else if (resdata.errmsg) {
                msg = resdata.errmsg;
            }else {
                msg = 'Something went wrong!!!'
            }
            Swal.fire({ icon: "error", title: t("Oops..."), text: msg });
            props.history.push('/login');
        }
    }

    return (
        <div>
            Line Login Callback !!!
        </div>
    )
}

export default withRouter(LineLoginCallback);

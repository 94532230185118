import React, { useState } from "react";
import { Modal } from "antd";
import { withRouter } from "react-router-dom";
import SubSignStamp from "../../30_MakeSignature/SubSignStamp";
import { useTranslation } from "react-i18next";


function ModalSelectSign(props) {
    const { t } = useTranslation();
    const [SignUrl, setSignUrl] = useState(null);

    async function onOk() {
        props.changeSign(SignUrl);
    }

    function onCancel() {
        props.changeSign(null);
    }

    function changeSign(url){
        setSignUrl(url);
    }

    //////////////////////////////////////////////////////////////////////////

    return (
        <div>
            <Modal
                title= {t("Change Sign")}
                open={props.visible}
                centered ={true}
                okText={t("OK")}
                width={700}
                destroyOnClose={true}
                onOk={onOk}
                onCancel={onCancel}
                className="signChangePopup customPopup"
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <SubSignStamp changeSign={changeSign} UserDbId={props.UserDbId}/>
            </Modal>
        </div>
  );
}

export default withRouter(ModalSelectSign);

import React from 'react';
import { withRouter } from "react-router-dom";
import querystring from 'querystring';
import { useTranslation } from "react-i18next";
import { LINE_OAUTH_CLIENT_ID } from "../../../config";
import LineLogo from '../../../assets/images/VideoSign/lineLogo.png';

function LineLogin(props) {
    const { state, register_mode } = props;
    const { t } = useTranslation();

    function lineLogin() {
        const loc = window.location.href.split('/');
        console.log('loc=', loc);
        let redirectURI = loc[0] + '//' + loc[2] + '/callback_linelogin';

        // Build query string.
        const query = querystring.stringify({
            response_type: 'code',
            client_id: LINE_OAUTH_CLIENT_ID,
            state: state,
            scope: 'profile openid email',
            nonce: '4394',
            prompt: 'consent',
            max_age: 120,
            bot_prompt: 'normal'
        });

        // Build the Line authorise URL.
        const lineAuthoriseURL =
            'https://access.line.me/oauth2/v2.1/authorize?' + query + '&redirect_uri=' + redirectURI;
            // Redirect to external URL.
        window.location.href = lineAuthoriseURL;
    };

    return (
        <div className="formLineBtn" onClick={lineLogin}>
            <div className="btnImg"><img src={LineLogo} alt="line-logo" /></div>
            <div className="btnText">
                {
                    (register_mode) ? 'LINEで登録' : 'LINEでログイン'
                }
            </div>
        </div>
    );
};

export default withRouter(LineLogin);
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { useLocation, withRouter } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

import Stripe from "./Stripe";

// 파일을 선택하는 화면.  파일을 선택하면 확인과정 없이 바로 서버에 업로드.
function Plans() {

    const buttonRef = useRef(null);

    const { userData, isLoaded } = useSelector((state) => state.user);

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search).get('isModalOpen');
    
    const [clientSecret, setClientSecret] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(searchParams ?? false);
    
    const createSubscription = (priceId) => {

        if (isLoaded) {

            const { stripeCustomerId } = userData;

            const dataToSubmit = { priceId, customerId: stripeCustomerId };
            axios.post("/api/payment/stripe/create-subscription", dataToSubmit).then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    const { clientSecret } = data;
                    setClientSecret(clientSecret);
                    setIsModalOpen(true);
                } else {
                    console.log('Unkown error occured!');
                }
            }).catch((err) => {
                console.log('Error:', err.message);
            });

        } else {

            console.log('User data is not loaded yet!');
        }
    };

    useEffect(() => {

        return () => {

            setClientSecret('');
            setIsModalOpen(false);
        };

    }, []);

    // const handleImperitiveClick = () => {
    //     console.log(Object.keys(buttonRef.current));
    //     console.log("click in ImperitiveParent.js");
    //     buttonRef.current.someExposedProperty();
    // };

    return (<div>
        <Row justify='space-around' style={{ marginTop: '100px' }}>

            {
                (isModalOpen) ? <Col><Stripe clientSecret={clientSecret} /></Col> : <Fragment>
                    <Col>
                        <Row style={{ border: '1px solid black', height: '200px' }}>
                            1 days $2
                        </Row>
                        <Row>
                            <Button onClick={() => createSubscription('price_1MWc9kKHlOGCyFheyiZIrLUd')}>Pay</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ border: '1px solid black', height: '200px' }}>
                            1 days $1
                        </Row>
                        <Row>
                        <Button onClick={() => createSubscription('price_1MWc9kKHlOGCyFheyiZIrLUd')}>Pay</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ border: '1px solid black', height: '200px' }}>
                            365 days $400
                        </Row>
                        <Row>
                        <Button onClick={() => createSubscription('price_1MWc9kKHlOGCyFheyiZIrLUd')}>Pay</Button>
                        </Row>
                    </Col>
                </Fragment>
            }

        </Row>

    </div>);
}

export default withRouter(Plans);

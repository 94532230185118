import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Timeline, Typography } from "antd";
import docuutil from '../../../utils/DocuUtil';
import { useTranslation } from "react-i18next";
import RejectIcon from "../../../../assets/images/VideoSign/reject-icon.svg";
import CompletedIcon from "../../../../assets/images/VideoSign/completed-icon.svg";
import RequestIcon from "../../../../assets/images/VideoSign/request-icon.svg";
import SignedIcon from "../../../../assets/images/VideoSign/reject-icon.svg";
import StarIcon from "../../../../assets/images/VideoSign/star-icon.svg";


function DocuActivity(props) {
    const [HistoryList, setHistoryList] = useState([]);
    const { Text, Title } = Typography;
    const { t } = useTranslation();
    useEffect(() => {
        async function f() {
            const historyList = await docuutil.get_docu_history(10); //현재 user가 생성한 docu와 recipient에 속한 docu를 내림차순으로 sorting하여 return        
            setHistoryList(historyList);
        }
        f();
    }, []); 


    function gen_message(history) {
        const user = `${history.name}`; 

        switch (history.action) {
            case 'upload': return `${user} ${t("uploaded contract document.")}`;
            case 'transfer': return `${t("System send email to")} ${user} ${t('for requesting signature.')}`;
            case 'agree': return `${user} ${t("signed contract.")}`;
            case 'reject': return `${user} ${t("denied contract.")}`;
            case 'reject_legal': return `${user} ${t("rejected e-sign agreement.")}`;
            case 'reject_vconfirm': return `${user} ${t("denied to confirm the recorded video for contract.")}`;
            case 'video_confirm': return `${user} ${t("confirmed recorded video.")}`;
            case 'video_confirm_req': return `${user} ${t("System send email to")} ${user} ${t('for confirming recorded video')}`;
            case 'all_agree': return `${t("All recipient agree to contract.")}`;
            case 'transfer_completion': return `${t("System sent sign completed contract to")} ${user}.`;
            case 'hash_nft': return `${t("NFT of signed document hash has been created.")}`
        }
    }

    function gen_dot(action) {

        switch (action) {
            case 'upload': return <img src={CompletedIcon} alt="completed-icon" />;
            case 'transfer': return <img src={RequestIcon} alt="request-icon" />
            case 'agree': return <img src={StarIcon} alt="signed-icon" />;
            case 'reject': return <img src={RejectIcon} alt="reject-icon" />;
            case 'reject_legal': return <img src={RejectIcon} alt="reject-icon" />;
            case 'reject_vconfirm': return <img src={RejectIcon} alt="reject-icon" />;
            case 'video_confirm': return <img src={StarIcon} alt="signed-icon" />;
            case 'video_confirm_req': return <img src={RequestIcon} alt="signed-icon" />;
            case 'all_agree': return <img src={SignedIcon} alt="signed-icon" />;
            case 'transfer_completion': return <img src={RequestIcon} alt="request-icon" />;
            case 'hash_nft': return <img src={SignedIcon} alt="request-icon" />;
        }
    }

    const render_history = HistoryList && HistoryList.map(function (history, index) {
        return (
            <Timeline.Item key={index} dot={gen_dot(history.action)}>
                <Text className="activityText">{gen_message(history)}</Text>
                <Text className="timeAgo">{docuutil.print_timeinfo(history.time, t)}</Text>
            </Timeline.Item>)
    }
    );

    return (
        <div className="timelineList tabContent">
            <Title className="tabTitle" level={2}>{t("Activity")}</Title>
            <Timeline>
                {render_history}
            </Timeline>

        </div>
    );
}

export default withRouter(DocuActivity);

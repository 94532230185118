import React, { useState } from "react";
import { Row, Col, Button, Spin, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PlanInfo(props) {
    const { Text, Title } = Typography;
    const { t } = useTranslation();

    return (<div style={{textAlign:'center', marginTop:'100px'}}>
		<h1>Plan Info</h1>
	</div>);
}

export default withRouter(PlanInfo);

import React, { useState } from "react";
import { Row, Modal, Input, Typography} from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const {TextArea} = Input;
const { Text, Title } = Typography;

function ModalReject(props) {
    const { t } = useTranslation();
    const [TextReason, setTextReason] = useState("");

    function onChangeText(e) {
        setTextReason(e.target.value);
    }

    return (
    <div>
        <Modal
            title={t("Deny to Sign")}
            open={props.visible}
            centered ={true}
            okText={t("Deny")}
            cancelText={t("Cancel")}
            width={800}
            destroyOnClose={true}
            onCancel={props.onCancel}
            onOk = {()=>{return props.onOk(TextReason)}}
            className="customPopup denySignPopup"
        >
            <Row justify='center'>
               <Text className="text">{props.explain}</Text>
            </Row>

            <Title level={4}>{t("Reason for refusal")}</Title>
            <Row justify='center'>
                <TextArea placeholder={t("200 characters or less")} showCount maxLength={200} onChange={onChangeText}/>
            </Row>

    	  </Modal>
    </div>
  );
}

export default withRouter(ModalReject);

import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import PdfImg01 from "../../../../assets/images/VideoSign/pdfimg03.jpeg";

function ESignature() {

    const { t } = useTranslation();

    const { Title, Text } = Typography;
   
    return (
        <div className="contentPages content">
            <div className="container">
                <Title align="center" className="mainTitle">{t("eSignature")}</Title>
                <p>{t("Electronic contract that provides the easiest but fastest and strongest legal protection.")}</p>
                <p>{t("The biggest problem with current electronic contract solutions is that they are complicated to use.")}</p>
                <p>{t("I tried several electronic contract programs, but all of them had no choice but to give up in the middle. The biggest problem was the incomprehensible UI. I tried all solutions, but I had a hard time understanding how the program worked.")}</p>
                <p>{t("VidioSign has made contract creation very easy. Like the slogan “E-Sign for Dummy”, VidioSign made it easy for any idiot to sign an electronic contract.")}</p>
                <p>{t("In VidioSign, if the other party to the contract presses the “Approve” button once, it is automatically entered into the places where signatures are required, and a contract certificate is issued.")}</p>
                <p>{t("One of the advantages of VidioSign is the additional issued certificate. As soon as the contract is completed, users will receive the certificate along with the e-contract by email.")}</p>

                <div align="center" style={{marginBlock:30}}>
                    <img src={PdfImg01} alt="pdf-img"/>
                </div>
            </div>
        </div>
    );
}

export default ESignature;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import english from "./lang.en.json";
import korean from "./lang.ko.json";
import japanese from "./lang.jp.json";

const resource = {
  English: {
    translation: english,
  },
  Korean: {
    translation: korean,
  },
  Japanese: {
    translation: japanese,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resource,
    lng: "English",
    fallbackLng: "English",
    // ns: ['translation'],
    // defaultNS: "translation",
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

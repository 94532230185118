import { apiUrl } from '../../config';
//import Resizer from "react-image-file-resizer";

// tinput이 http로 시작하지 않는 경우에는 apiUrl을 앞에 붙여줌
function make_image_url(tinput) {
    //console.log('make_image_url: ', tinput);
    let tout;
    if (!tinput) tout = "";
    else if (tinput.length < 5) tout = tinput;
    else if (tinput.substring(0, 4) !== "http") {
        tout = `${apiUrl}/${tinput}`;
        tout = encodeURI(tout);
    }
    else tout = tinput;

    //console.log('make_image_url:out: ', tout);
    return tout;
}

function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
}

// sign_url을 hash 비슷한 16진수 string으로 리턴
function sign_to_id(sign_url) {
    const tlen = sign_url.length;
    const id_str = sign_url.substring(tlen-17, tlen-4);
    const id_num = parseInt(id_str);
    return id_num.toString(16);
}

// const resizeFile = (file) =>
//     new Promise((resolve) => {
//         Resizer.imageFileResizer(
//             file,
//             640,
//             480,
//             "PNG",
//             100,
//             0,
//             (uri) => {
//             resolve(uri);
//             },
//             "base64"
//         );
// });

// export async function resize_image(tfile) {
//     try {
//         const image = await resizeFile(tfile);
//         //console.log(image);
//         return image;    
//     } catch (err) {
//         console.log(err);
//         return null;    
//     }
// }

function get_extension(fname) {
    return fname.split('.').pop();
}

function get_yyyymmdd(timestamp) {
	const ttime = new Date(timestamp);
	const year = ttime.getFullYear();
	const month = ttime.getMonth()+1;
	const date = ttime.getDate();
	const hour = ttime.getHours();
	const minutes = ttime.getMinutes();
    return [year, month, date, hour, minutes];
}

function get_time_from_history(docu_history, action){
    for (let k=0; k<docu_history.length; k++){
        const event = docu_history[k];
        if (event.action===action){
            return event.time;
        }
    }
    return null;
}

export default {
    make_image_url, 
    sleep, 
    get_extension,
    sign_to_id,
    get_yyyymmdd,
    get_time_from_history
}
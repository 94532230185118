import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Typography } from "antd";
import { CheckOutlined, FrownTwoTone, FrownOutlined, StopOutlined, WarningOutlined } from "@ant-design/icons";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";



// main page of document
// 현재 등록된 계약들을 보여주고 그 계약을 클릭하면 계속 진행하는 화면으로 전환
function ModalShowDocuInfo(props) {
    const { t } = useTranslation();
    const { Text } = Typography;
    const {docu, MyIndex} = props;
    const rcptlist = docu?.recipient_list;
    const sign_denied_str = 'Sign Denied';
    const show_detail = (docu.status!=='DOCU_STATUS.Upload' && docu.status!=='DOCU_STATUS.AddRcpt' && docu.status!=='DOCU_STATUS.SetPos');
    const pending = (docu.status!=='DOCU_STATUS.Completed' && docu.status!=='DOCU_STATUS.Generating')

    const [RcptState, setRcptState] = useState('');
    const [DefaultAction, setDefaultAction] = useState(null);

    // rcptlist에 각각의 rcpt의 state를 적어넣음
    useEffect(() => {     
        function get_state(rcpt) {
            if (docu.vc_recipient){       // vcontract인 경우는 sign을 하지는 않음
                return <>{t('Completed')} <CheckOutlined className="docCardIcon" /></>;
            }

            if (rcpt.rcpt_agree === 2) {       // Sign이 거절되면 다른 정보 필요없음
                return <>{t('Sign')} <StopOutlined className="docCardIcon docRedIcon" size={20} /></>;
            }

            if (docu.use_video_sign && docu.recipient_list.length===1) {  // video sign인 경우 && 1명이 하는 경우 --> vconfirm이 필요없음
                const sign_str = (rcpt.rcpt_agree===1)? <span>{t('Sign')} <CheckOutlined className="docCardIcon" /></span>: <span>{t('Sign')} <WarningOutlined className="docCardIcon docRedIcon" size={20} /></span>;
                const vsign_str = (rcpt.vsign_url.length>4)? <span>{t('Video')} <CheckOutlined className="docCardIcon" /></span> : <span>{t('Video')} <WarningOutlined className="docCardIcon docRedIcon" /></span>;
                return <>{sign_str} {vsign_str}</>;
            } else if (docu.use_video_sign) {  // video sign인 경우
                const sign_str = (rcpt.rcpt_agree===1)? <span>{t('Sign')} <CheckOutlined className="docCardIcon" /></span>: <span>{t('Sign')} <WarningOutlined className="docCardIcon docRedIcon" size={20} /></span>;
                const vsign_str = (rcpt.vsign_url.length>4)? <span>{t('Video')} <CheckOutlined className="docCardIcon" /></span> : <span>{t('Video')} <WarningOutlined className="docCardIcon docRedIcon" /></span>;
                const vconfirm_str = (rcpt.video_confirm===0)? <span>{t('Video Confirm')} <WarningOutlined className="docCardIcon docRedIcon" size={20} /></span> : 
                                    (rcpt.video_confirm===1)? <span>{t('Video Confirm')}<CheckOutlined className="docCardIcon" /></span> : 
                                    <span>{t('Video Confirm')} <StopOutlined className="docCardIcon docRedIcon" size={20} /></span>;
                return <>{sign_str} {vsign_str} {vconfirm_str}</>;
            } else {    // normal esign인 경우
                if (rcpt.rcpt_agree === 1) {
                    return <> {t('Sign Completed')} <CheckOutlined className="docCardIcon" /></>
                } else {
                    return <> {t('Sign')} <WarningOutlined className="docCardIcon docRedIcon" size={20} /></>;
                }
            }
        }

        // default action이 있다면 true
        function get_default_action() {
            if (docu.vc_recipient){       // vcontract인 경우
                //alert('Default Action: Sorry... not yet available');      //todo
                return null;
            }

            const my_rcpt = docu.recipient_list[MyIndex];
            if (!show_detail) {       // 계약이 만들어지기 이전이라면
                if (!my_rcpt.is_owner) return null;
                else {
                    return docu.status;
                }
            } else {
                if (my_rcpt.rcpt_agree===0) {    // 아직 sign안했으면
                    return 'need_sign';
                } 

                if (docu.use_video_sign) {      
                    if (!(my_rcpt.vsign_url.length>4)){  //아직 video 안찍었다면
                        return 'need_video';
                    } else if (my_rcpt.video_confirm===0) {    //아직 vconfirm 안했다면
                        return 'need_vconfirm';
                    }
                    return null;
                }
                return null;
            }
        }

        const rcptState=[];
        for (let k=0; k<rcptlist.length; k++) {
            const state_str = get_state(rcptlist[k]);
            rcptState.push(state_str);
        }

        setRcptState(rcptState);
        const default_action = get_default_action();
        setDefaultAction(default_action);
    }, []);

    function onClickShowRejectReason(e, rcpt) {
        e.preventDefault();
        e.stopPropagation();
        if (rcpt.reject_reason)
            Swal.fire(rcpt.reject_reason);
        else
            Swal.fire(t("You denied agreement to e-sign"));
    }

    function RejectButton(props) {
        if (RcptState[props.index]===sign_denied_str) {
            return <Button type='link' onClick={(e) => onClickShowRejectReason(e, rcptlist[props.index])}>{t("See reason for rejection ...")}</Button>
        }
        return '';
    }

    const rcpt_render = rcptlist && rcptlist.map((rcpt, index) => (
        <div key={index}>
            {
                (index===MyIndex)?
                    <>{index + 1}. {rcpt.name} ({rcpt.contact}) <Text code>{RcptState[index]} <RejectButton index={index}/> </Text></> :
                
                    <>{index + 1}. {rcpt.name} ({rcpt.contact}) <Text code>{RcptState[index]} <RejectButton index={index}/> </Text></>

            }
        </div> )
    );

    async function onClickDocument() {
        props.history.push("/home/show_docu/" + docu._id);
    }

    // action
    async function onClickContinue() {
        function on_upload_state(docu, evsign){
            if (docu?.vc_recipient) {
                props.history.push(`/home/make/vcontract/vcaddrcpt/${docu._id}`);
            } else {
                props.history.push(`/home/make/${evsign}/addrcpt/${docu._id}`);
            }
        }

        async function on_need_sign() {
            const docuId = docu._id;
            const res = await axios.post('/api/docu/get_rcpt_contact_enc', { docuId });
            if (res.data.success) {
                const contactenc = res.data.result;
                props.history.push(`/outsign/${docuId}/${contactenc}`);
            } else {
                alert(res.data.errmsg);
            }    
        }

        async function on_need_video() {
            const docuId = docu._id;
            const res = await axios.post('/api/docu/get_rcpt_contact_enc', { docuId });
            if (res.data.success) {
                const contactenc = res.data.result;
                props.history.push(`/outsign/${docuId}/${contactenc}`);
            } else {
                alert(res.data.errmsg);
            }    
        }

        async function on_need_vconfirm() {
            const docuId = docu._id;
            const res = await axios.post('/api/docu/get_rcpt_contact_enc', { docuId });
            if (res.data.success) {
                const contactenc = res.data.result;
                props.history.push(`/video_confirm/${docuId}/${contactenc}`);
            } else {
                alert(res.data.errmsg);
            }
        }

        //debugger;
        //console.log('docu=', docu);
        const evsign = docu.use_video_sign ? 'vsign' : 'esign';
        switch (DefaultAction) {
            case 'DOCU_STATUS.Upload': on_upload_state(docu, evsign); break;
            case 'DOCU_STATUS.AddRcpt': props.history.push(`/home/make/${evsign}/setpos/${docu._id}`); break;
            case 'DOCU_STATUS.SetPos': props.history.push(`/home/make/${evsign}/agree/${docu._id}`); break;
            case 'need_sign': await on_need_sign(); break;
            case 'need_video': await on_need_video(); break;
            case 'need_vconfirm': await on_need_vconfirm(); break;
            default:
                alert('contract is not ready yet!!! '+ docu.status);
                break;
        }
    }

    return (
        <div>      
            {
                show_detail && <div className="docCardInnerBox">
                    {
                        docu.status==='DOCU_STATUS.Upload'? 'Not Yet Determined' : <div>{rcpt_render}</div>
                    }                
                </div>
            }
            <Button type='link' className="seeDocumentLink" onClick={onClickDocument}>{t("Show Document")}</Button>
            {pending && <Button type='link' className="seeDocumentLink" disabled={DefaultAction===null} onClick={onClickContinue}>{t("Continue(Beta)")}</Button>}
            <Button type='link' className="moreInfoLink" onClick={(e) => props.onClickMore(e)}>{t("More info")}</Button>
        </div>
    );
}

export default withRouter(ModalShowDocuInfo);

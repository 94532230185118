import { useEffect, useState } from 'react';
import { Slider, Button, Row } from 'antd';
import axios from "axios";
import { useTranslation } from "react-i18next";

function BgRemoval(props) {
    const { t } = useTranslation();
    const [value, setValue] = useState(128);
    const [loaded, setLoaded] = useState(false);
    const [file, setFile] = useState({
        imageFile: "",
        imageURL: ""
    })

    const uploadImage = (e) => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');

        const fileReader = new FileReader();
        const image = new Image();

        fileReader.onload = (event) => {
            setFile({
                imageFile: e.target.files[0],
                imageURL: fileReader.result
            })
            image.src = e.target.files[0];
            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;

                ctx.drawImage(image, 0, 0);
                let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                if (props.sign_stamp==='sign') {
                    for (let i = 0; i < imageData.data.length; i += 4) {
                        const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
                        for (let j = 0; j < 3; j += 1) {
                            imageData.data[i + j] = 0;
                        }
                        if (avg > value) {
                            imageData.data[i + 3] = 0;
                        }
                        else {
                            imageData.data[i + 3] = 255;
                        }
                    }
                }
                else {
                    for (let i = 0; i < imageData.data.length; i += 4) {
                        const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
                        imageData.data[i] = 255;
                        imageData.data[i+1]=0;
                        imageData.data[i+2]=0;
                        if (avg > value) {
                            imageData.data[i + 3] = 0;
                        }
                        else {
                            imageData.data[i + 3] = 255;
                        }
                    }
                }

                ctx.putImageData(imageData, 0, 0);

            }
            image.src = canvas.toDataURL();
            ctx.drawImage(image, 0, 0);
            image.src = event.target.result;
        }
        fileReader.readAsDataURL(e.target.files[0]);

        setLoaded(true);
    }

    const editImage = async (value) => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');

        const fileReader = new FileReader();
        const image = new Image();

        fileReader.onload = (event) => {
            image.src = file.imageFile;
            image.onload = () => {

                ctx.drawImage(image, 0, 0);
                let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                if (props.sign_stamp==='sign'){
                    for (let i = 0; i < imageData.data.length; i += 4) {
                        const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
                        for (let j = 0; j < 3; j += 1) {
                            imageData.data[i + j] = 0;
                        }
                        if (avg > value) {
                            imageData.data[i + 3] = 0;
                        }
                        else {
                            imageData.data[i + 3] = 255;
                        }
                    }
                } else {
                    for (let i = 0; i < imageData.data.length; i += 4) {
                        const avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
    
                        imageData.data[i] = 255;
                        imageData.data[i+1]=0;
                        imageData.data[i+2]=0;
    
                        if (avg > value) {
                            imageData.data[i + 3] = 0;
                        }
                        else {
                            imageData.data[i + 3] = 255;
                        }
                    }
                }

                ctx.putImageData(imageData, 0, 0);

            }
            image.src = canvas.toDataURL();
            ctx.drawImage(image, 0, 0);
            image.src = event.target.result;
        }
        await fileReader.readAsDataURL(file.imageFile);
    }



    useEffect(() => {
        if (loaded) {
            editImage(value);
        }
    }, [value]);

    async function onClickUpload() {
        const canvas = document.getElementById('canvas');
        const data = await canvas.toDataURL();
        const result = await axios.post('/api/gensign/handwriting/save', { data: data, sign_stamp: props.sign_stamp, UserDbId:props.UserDbId });
        console.log("result", result);
        if (result.data.success) {
            props.onUpdate();
        }  
    }

    return (
        <div className='bg-removal'>
            <div className="formGroup">
                <div className='fileUpload'>
                    <input className="='input-image formControl" type='file' accept='image/*' onChange={uploadImage} />
                </div>
            </div>

            <div align='center'>
                <canvas id='canvas' />
            </div>

            <div justify='center' style={{marginBlock:20}}>
                {loaded? <Slider onChange={setValue} value={value} max={255} min={0} /> : ''}
            </div>

            <Row align='end'>
                <Button className="primaryBtn outlineSmallBtn" onClick={onClickUpload}> {t("Upload")}</Button>
            </Row>
            {/* <div className='modified-image'>
                <img src={file.imageURL} />
            </div> */}
        </div>
    )
}

export default BgRemoval;
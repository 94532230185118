import { Typography, Collapse, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

function FaqPage() {

    const { t } = useTranslation();

    const { Title, Text } = Typography;
    const { Panel } = Collapse;
   
    return (
        <div className="faq__wrapper content">
            <div className="container">
                <Title align="center" className="mainTitle">{t('Faq')}</Title>
                <Row justify="center">
                    <Col lg={18} xs={24}>
                        <Collapse bordered={false} accordion>
                            <Panel header={t("Why do I need a video signature?")} key="1">
                                <Text>{t("Since Covid-19, many businesses and individuals have started using e-contracts. The biggest problem in electronic contracts is the problem of forged signatures. Since contracts are signed non-face-to-face without meeting each other, there is no way to verify who is signing. Therefore, it is impossible to verify forged signers. Signature counterfeiting by malicious counterfeiters who want to steal your property will cause you serious damage. The surest way to prevent counterfeit signatures in non-face-to-face electronic contracts is a video signature that includes a video.")}</Text>
                            </Panel>
                            <Panel header={t("What is video evidence?")} key="2">
                                <Text>{t("Video evidence is any form of video used as admissible evidence in a court of law. It can be recorded on video home system (VHS) or in digital format. There has been a rise in using both types of video as evidence in court cases.")}</Text>
                            </Panel>
                            <Panel header={t("Can a video be used as an evidence in court to prove the contract?")} key="3">
                                <Text>{t("Yes. Anybody can definitely use the audio and video recordings. Video recordings are admissible in evidence and can be relied upon while reducing the evidence to prove your case. Video recording is one of the strongest evidence to prove the contract.")}</Text>
                            </Panel>
                            <Panel header={t("All video recordings can be used as an evidence.")} key="4">
                                <Text>{t("No, In order for Video Recording to have legal evidence, three elements are required.")}</Text><br></br>
                                <Text>{t("1) The person to be recorded must consent to the recording.")}</Text><br></br>
                                <Text>{t("2) It must be proven that the contents of the recording have not been manipulated.")}</Text><br></br>
                                <Text>{t("3) The date the video was taken must be stated, or it must be proven that it has not been tampered with.")}</Text>
                            </Panel>
                            <Panel header={t("Does VidioSign have strong legal force as evidence?")} key="5">
                                <Text>{t("1. When making a video electronic contract, confirm that you agree to video shooting.")}</Text><br></br>
                                <Text>{t("2. Videos stored in VidioSign cannot be manipulated because only videos directly shot in VidioSign's program are converted into NFTs.")}</Text><br></br>
                                <Text>{t("Therefore, you can prove that this Video is original and untampered with. Videos shot on VidioSign include a TimeStamp on the blockchain that cannot be tampered with, so you can get a clear legal effect on the date the video was taken.")}</Text>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default FaqPage;

import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { baseUrl } from "../../../../config";

function HelmetMetaData(props) {

    const location = useLocation();
    const { t } = useTranslation();

    const currentUrl = baseUrl + location.pathname;

    const {
        quote = "",
        hashtag = "#NFT",
        title = t("E-Signature Contract / Video Signature Contract / Video Contract"),
        description = t("E-Signature Contract / Video Signature Contract / Video Contract"),
        image = "https://s3.ap-northeast-1.amazonaws.com/assets.metabox.page/metabox_cat600.png",
    } = props;

    return (
        <Helmet>
            <title>{title}</title>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="csrf_token" content="" />

            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
            <meta name="theme-color" content="#ffffff" />

            <meta property="type" content="website" />
            <meta property="url" content={currentUrl} />
            <meta name="_token" content="" />
            <meta name="robots" content="noodp" />
            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />

            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="og:image" content={image} />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:site_name" content="CampersTribe" />
            <meta property="og:description" content={description} />
            <meta name="twitter:card" content={description}></meta>{" "}
        </Helmet>
    );
};

export default HelmetMetaData;

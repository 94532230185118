import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Row, Button, Modal, Checkbox, Typography } from "antd";
import LineLogin from "./LineLogin";
import TermsPage from '../14_Footer/Sections/TermsPage';
import { getCookie } from "../../../cookie/cookie";
import { loginGoogleUser } from "../../../_actions/user_actions";
import { GOOGLE_OAUTH_CLIENT_ID } from "../../../config";
import utilfunc from "../../utils/utilfunc";
import { useTranslation } from "react-i18next";
// import { GoogleLogin } from "react-google-login";

function Registration(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation('Home');
    const { Text } = Typography;
    const dispatch = useDispatch();

    //보여주는 화면이 agree mode인지 register mode인지 설정
    const [ScreenMode, setScreenMode] = useState('agree');
    const [AgreeService, setAgreeService] = useState(false);
    const [AgreePrivate, setAgreePrivate] = useState(false);
    const [AgreeMarketing, setAgreeMarketing] = useState(false);
    const [AgreeThird, setAgreeThird] = useState(false);
    const [AgreeAge14, setAgreeAge14] = useState(false);
    const [AgreeAll, setAgreeAll] = useState(false);
    const [LineStateString, setLineStateString] = useState('');

    useEffect(() => {

        if (AgreeAll) {

            setAgreeService(true);
            setAgreePrivate(true);
            setAgreeMarketing(true);
            setAgreeThird(true);
            setAgreeAge14(true);
        }

    }, [AgreeAll]);

    useEffect(() => {

        const x1 = (AgreeMarketing) ? '1' : '0';
        const x2 = (AgreeThird) ? '1' : '0';
        const state = 'xyzABC123' + x1 + x2;
        setLineStateString(state);

    }, [AgreeMarketing, AgreeThird]);

    function onClickRegister() {
        if (!AgreeService) {
            Swal.fire(t('Agreeing to the Terms of Service is required.'));
            return;
        }

        if (!AgreePrivate) {
            Swal.fire(t('Agreeing to the Privacy Policy is required.'));
            return;
        }

        if (!AgreeAge14) {
            Swal.fire(t('Only those 14 years of age or older can join.'));
            return;
        }

        setScreenMode('register');
    }

    function AgreeScreen() {
        return (
            <div className="registrationAgreePopup">
                <Fragment>
                    <div className="registrationTermsPage">
                        <TermsPage />
                    </div>
                    <div className="agreeCheckBox">
                        <Checkbox checked={AgreeService} onChange={e => setAgreeService(e.target.checked)}>{t("Terms of Service (required)")}</Checkbox>  <br />
                        <Checkbox checked={AgreePrivate} onChange={e => setAgreePrivate(e.target.checked)}>{t("Privacy policy (required)")}</Checkbox>  <br />
                        <Checkbox checked={AgreeMarketing} onChange={e => setAgreeMarketing(e.target.checked)}>{t("Collection and use of marketing information (optional)")}</Checkbox>  <br />
                        <Checkbox checked={AgreeThird} onChange={e => setAgreeThird(e.target.checked)}>{t("Consent to provision of information to third parties (optional)")}</Checkbox>  <br />
                        <Checkbox checked={AgreeAge14} onChange={e => setAgreeAge14(e.target.checked)}>{t("Age 14+ (required)")}</Checkbox>  <br />  <br />
                        <Checkbox checked={AgreeAll} onChange={e => setAgreeAll(e.target.checked)}>{t("I agree all items.")}</Checkbox>  <br />
                    </div>
                    <Row justify="center">
                        <Button className="primaryBtn" type='primary' onClick={onClickRegister}>{t("Registration")}</Button>
                    </Row>
                </Fragment>
            </div>
        )
    }

    function Register() {

        useEffect(() => {
            async function f() {
                /* global google */
                for (let k=0; k<10; k++){
                    const google = window.google;
                    if (google){
                        break;
                    }
                    await utilfunc.sleep(400);
                }
                if (!google){
                    alert('cannot load google ID login script!!!');
                    return;
                }

                google.accounts.id.initialize({
                    client_id: GOOGLE_OAUTH_CLIENT_ID,
                    callback: handleCallbackResponse
                });

                google.accounts.id.renderButton(document.getElementById("signInDiv"),
                    {
                        theme: "outline",
                        size: "medium",
                        text: "signup_with"
                    });
            }
            f();
        }, []);

        async function handleCallbackResponse(res) {
            var id_token = res.credential;
            const lang = i18n.language;
            const body = { token: id_token, payload: { AgreeMarketing, AgreeThird, lang } };
            const res_regist = await axios.post('/api/users/regist_google', body);
            if (res_regist.data.success) {
                Swal.fire(t('SignUp Success!!! Now Automatically SignIn as Google ID. Please Wait'));
                //props.history.push('/login');
                // automatic login
                const body = { token: id_token };
                dispatch(loginGoogleUser(body)).then((response) => {
                    respond_to_result(response);
                });
            } else {
                //Swal.fire('t("SignUp Failed...") : ' + res_regist.data.errmsg);
                Swal.fire(`${t("SignUp Failed...")} : ${res_regist.data.errmsg}`);
            }
        }

        // google로 로그인하면 불리는 함수
        function respond_to_result(response) {
            const resdata = response.payload;
    
            if (resdata.loginSuccess) {
    
                const token = getCookie('macAddress');
                const platform = getCookie('site-platform');
    
                if (token !== 'default-token') {
                    const dataToSubmit = { _id: resdata.userId, macAddress: token, platform };
                    axios.post("/api/users/updateUserById", dataToSubmit).then((response) => {
                        if (response.data.success) {
                            console.log('MacAddress added successfully!');
                        }
                    });
                }
    
                // 처음 login인 경우에는 lastlogintime이 2000년임
                if (resdata.last_login_time.substr(0, 4) === "2000") {
    
                    props.history.push({
                        pathname: "/thankyou"
                        //search: "?username=" + resdata.username + "&userinfo=" + resdata.userinfo,
                    });
    
                } else {
    
                    let message = t("Logged in successfully.");
                    Swal.fire(t("Success"), message, "success");
                    props.history.push("/");
                }
    
            } else {
                let msg;
                if (resdata.errmsg === 'user not found') {
                    msg = t("We cannot find user. If you didn't SignUp, please Signup First.");
                } else if (resdata.errmsg) {
                    msg = resdata.errmsg;
                } else {
                    msg = t('Something went wrong!!!')
                }
                Swal.fire({ icon: "error", title: t("Oops..."), text: msg });
            }
        }
    

        return (
            <Fragment>
                <div align="left">
                    <Text className="popupText noNeed">{t("No credit card required")}</Text>
                </div>

                <div id="signInDiv" className="google-sign google-signUp"></div>
                <div className="lineRegistration">
                <LineLogin state={LineStateString} register_mode={true} />
                </div>
                
                <div className="agreeLinksWrap" align="left">
                    <Text className="popupText popupLink">{t("By signing up, you agree to VidioSign")} <Link to="/privacy">{t("Privacy Notice")}</Link> {t("and")} <Link to="/terms">{t("Terms and Conditions")}</Link> </Text>
                </div>
                <Text className="popupText popupLink"> {t("Already Have an account?")} <Link to='/login'>{t("Login")}</Link></Text>
            </Fragment>
        )
    }

    function onCancel() {
        setScreenMode('cancel');
        props.history.push("/");
    }

    return (
        <Fragment>
            <Modal className="sign-in-up" title={t("Try VidioSign Free For 30 Days")} open={ScreenMode !== 'cancel'} footer={false} maskClosable={true} onCancel={onCancel}>
                <div align="center">
                    {ScreenMode === 'agree' ? <AgreeScreen /> : <Register />}
                </div>
            </Modal>
        </Fragment>
    );
}

export default withRouter(Registration);

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Button } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ShowDocu(props) {
    const { t } = useTranslation();
    const { history, match } = props;
    const docuId = match.params.docuId;
    const [PdfPath, setPdfPath] = useState(null);

    useEffect(() => {
        async function f() {
            const res = await axios
                .post("/api/docu/prepare_recent_pdf", { docuId })
                .catch((err) => {
                    console.error("ShowDocu:", err.message);
                });
            if (res.data.success) {
                //console.log(`/api/${res.data.result}`);
                setPdfPath(`/api/${res.data.result}`);
            } else {
                console.log("ShowDocu: Error:", res.data.errmsg);
                alert("Error: cannot prepare recent pdf:", res.data.errmsg);
            }
        }
        f();
    }, []);

    function onClickOk() {
        //debugger;
        //history.push('/');
        history.goBack();
    }

    return (
        <div>
            <Row>
                {PdfPath && (
                    <iframe
                        src={PdfPath}
                        style={{ width: "100%", height: "80vh" }}
                    />
                )}
            </Row>
            <Row justify="center" style={{ margin: "10px" }}>
                <Button
                    type="primary"
                    className="primaryBtn"
                    onClick={onClickOk}
                >
                   {t("OK")} 
                </Button>
            </Row>
        </div>
    );
}

export default withRouter(ShowDocu);

import React, { useEffect, useState } from "react";

import axios from "axios";
import { Spin, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, StopOutlined, RightCircleOutlined, EditOutlined } from '@ant-design/icons';

import docuutil from '../utils/DocuUtil';

// import fileDownload from "js-file-download";
// import Swal from "sweetalert2";

// docu 여러개를 landing page에 display할 때 각각의 docu를 그려주는 코드.
// modal에서도 사용되기 때문에 별도의 함수로 작성함.
function DisplayDocuList({ docu, refresh }) {
    const { t } = useTranslation();
    const { Title } = Typography;

    // 모든 서명이 완료되어 서버가 앞뒷장을 붙이는 작업을 하고 있는 경우에 완료되었는지 자동으로 체크
    const [CheckFlag, setCheckFlag] = useState(true);

    useEffect(() => {
        function change_flag() {
            setCheckFlag(!CheckFlag);
        }

        async function check_generating() {
            let tick;
            if (docu.status === 'DOCU_STATUS.Generating') {
                const curr_status = await get_docu_status(docu._id);
                if (curr_status !== 'DOCU_STATUS.Generating') {        // SignAll이 아닌 것으로 바뀌었으면 refresh함.
                    console.log('checking status: done');
                    refresh();
                } else {
                    tick = setTimeout(change_flag, 5000);
                }
            }
            return tick;
        }

        const tick = check_generating();

        return () => clearTimeout(tick);
    }, [CheckFlag, docu.status, docu._id])

    // 특정 문서의 status를 가져옴
    async function get_docu_status(docuId) {

        const res = await axios.post("/api/docu/get_docu_status", { docuId });
        if (res.data.success) {
            return res.data.result;
        } else {
            return null;
        }
    }

    function explain_status(status) {
        switch (status) {
            case 'DOCU_STATUS.Ready': return '';
            case 'DOCU_STATUS.Upload': return <font color='blue'><EditOutlined />{t("File Uploaded")}</font>;
            case 'DOCU_STATUS.AddRcpt': return <font color='blue'><EditOutlined />{t("Recipient Setup Completed")}</font>;
            case 'DOCU_STATUS.SetPos': return <font color='blue'><EditOutlined />{t("Sign Area Setup Completed")}</font>;
            case 'DOCU_STATUS.Agree': return <font color='deepskyblue'><RightCircleOutlined />{t("Waiting Sign")}</font>;
            case 'DOCU_STATUS.NeedVConfirm': return <font color='deepskyblue'><RightCircleOutlined />{t("Waiting Video Confirm")}</font>;
            case 'DOCU_STATUS.Generating': return <div> <font color='orange'><CheckCircleOutlined />{t("Generating Final Document")}</font> <Spin /></div>;
            case 'DOCU_STATUS.Completed': return <font color='orange'><CheckCircleOutlined />{t("Final Document Created")}</font>;
            case 'DOCU_STATUS.Reject': return <font color='black'><StopOutlined />{t("Denied")}</font>;
            case 'DOCU_STATUS.Reject_legal': return <font color='black'><StopOutlined />{t("E-Sign Denied")}</font>;
            default: return '';
        }
    }

    function print_timeinfo(history) {
        if (history?.length>0) {
            return docuutil.print_timeinfo(history[history.length - 1].time, t);
        } else {
            return 0
        }
    }

    return (
        <>
            <Title level={4}>{explain_status(docu.status)} </Title>
            <Title level={5}><font size={4}>{t(docu.title)} </font> </Title>
            <font size={2} color='black'>{print_timeinfo(docu.history)}</font>
        </>
    );
}

export default withRouter(DisplayDocuList);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Steps } from "antd";
import { useTranslation } from "react-i18next";

// MakeContract-->ProgressStep
// 진행정도를 보여줌(계약을 만드는 경우)
function ProgressStep(props) {
  const { t } = useTranslation();
  const [Current, setCurrent] = useState(0);

  useEffect(() => {
    switch (props.State) {
      case "upload":
        setCurrent(0);
        break;
      case "sign":
        setCurrent(0);
        break;
      case "addrcpt":
        setCurrent(1);
        break;
      case "setpos":
        setCurrent(2);
        break;
      case "agree":
        setCurrent(3);
        break;
      default:
        setCurrent(100);
        break;
    }
  }, [props.State]);

  const items=[
                {title:t("Upload"), description:"Upload file to make contract."}, 
                {title:t("Configuring Contractors"), description:"Configuring Contractors"}, 
                {title:t("Set Position"), description:"Set where to sign"}, 
                {title:t("Agreement"), description:"Agreement and Send Document to each recipients"}]
  return (
    <>
      <Steps current={Current} direction="vertical" items={items}/>
    </>
  );
}

export default withRouter(ProgressStep);

import utilfunc from "./utilfunc";

class MyVideoRecorder {

    constructor(preview) {
        this.camType = 'user';
        this.preview = preview;
        this.chunks = [];
    }

    async release() {
        delete this.preview.src;
        delete this.preview.srcObject;
        delete this.stream;
    }

    async openCam() {

        try {
            await this.release();
            console.log('before getUserMedia');
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

                // video: { facingMode: { exact: _type } },
                const _stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

                if (_stream) {

                    if ("srcObject" in this.preview) {
                        this.stream = _stream;
                        this.preview.srcObject = _stream;
                        this.preview.play();
                    }
                }

                console.log('before wait');
                utilfunc.sleep(300);
                console.log('after wait');
            }

        } catch (err) {
            console.log('MyVideoRecorder:openCam:error:getUserMedia:', err.message);
        }
    }

    async start_recording() {
        this.mediaRecorder = new MediaRecorder(this.stream);
        // new code this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: 'video/webm;codecs=H264' });
        this.mediaRecorder.ondataavailable = (_event) => {
            //console.log('~~~~~~~~~~~~~~~~~~ondataavailable', _event);
            this.chunks.push(_event.data);
        };
        
        this.mediaRecorder.onstop = (_event) => {
            //console.log('~~~~~~~~~~~~~~~~~~~~onstop', _event, this.chunks);
            this.blobs = new Blob(this.chunks, { type: _event.target.mimeType });
            this.url = URL.createObjectURL(this.blobs);
            this.stream.getTracks().forEach(track => track.stop()); // stop each of them
        };
        
        this.mediaRecorder.start();
    }

    async stop_recording() {
        await this.mediaRecorder.stop();
        this.preview.pause();
    }

    get_recorded_blob() {
        //console.log('get_recorded_blob', this.blobs);
        return this.blobs;
    }

    get_blob_url() {
        // console.log('get_blob_url', this.url);
        return this.url;
    }
};

export default MyVideoRecorder;

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { EditTwoTone} from '@ant-design/icons';

function DragResize(props) {
    const {init_height, sign_src, rcpt_idx, posindex, show_edit, backcolor, isMobile} = props;
    const [Init, setInit] = useState(false);     // resize, move
    const [VisibleButton, setVisibleButton] = useState(true);
    const [EndPos, setEndPos] = useState(0);
    const [ImgLoadDone, setImgLoadDone] = useState(false);


    useEffect(() => {
        //console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~', init_height, sign_src, rcpt_idx, posindex, show_edit, backcolor, isMobile);
        console.log('DR:init_height=', rcpt_idx, posindex, init_height, ImgLoadDone);
        initDragElement();
    }, [init_height, ImgLoadDone]);

    useEffect(() => {
        if (Init) {
            initDragElement();
            setInit(false);
        }
    }, [Init]);

    function onLoadImg() {
        setImgLoadDone(true);
    }

    function initDragElement() {
        if (!ImgLoadDone) {
            console.log('before loading')
            return;
        }

        console.log("initDragElement");
        var pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;
        var elmnt = null;
        var currentZIndex = 100; //TODO reset z index when a threshold is passed
        var popup = document.querySelector(`div#rid${rcpt_idx}-${posindex}`);
        var header = getHeader(popup);
        let move_mode =false;

        //console.log('DR:sign_src============>', sign_src, header.naturalWidth);
        const init_width = init_height * header.naturalWidth / header.naturalHeight;
        popup.style.height = `${init_height}px`;
        popup.style.width = `${init_width}px`;
        //debugger;

        if (isMobile){
            popup.ontouchstart = resizeMouseDown;
            popup.ontouchend = onResizeMove;
        } else {
            popup.onmousedown = resizeMouseDown;
            popup.onmouseup = onResizeMove;
        }

        if (header) {
            header.parentPopup = popup;
            if (isMobile){
                //console.log('header.ontouchstart = dragMouseDown;')
                header.ontouchstart = dragMouseDown;
            }else{
                //console.log('header.onmousedown = dragMouseDown;')
                header.onmousedown = dragMouseDown;
            }
            header.style.backgroundColor = backcolor;
        }

        popup.style.top = "0px";
        popup.style.left = "0px";
        // console.log('init offset:', pos2, parseInt(popup.style.top), parseInt(popup.offsetTop));
        setEndPos(popup.clientWidth);
        

        function dragMouseDown(e) {
            //setVisibleButton(false);
            move_mode = true;
            elmnt = this.parentPopup;
            //console.log("dragMouseDown:");
            elmnt.style.zIndex = "" + ++currentZIndex;

            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;

            if (isMobile) {
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.ontouchmove = elementDrag;
            } else {
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }
        }

        function resizeMouseDown(e) {
            setVisibleButton(false);
            this.style.zIndex = "" + ++currentZIndex;
        }

        function elementDrag(e) {
            if (!elmnt) {
                return;
            }

            if (isMobile) {
                e = e.touches[0];
            } else {
                e = e || window.event;
            }
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = elmnt.offsetTop - pos2 + "px";
            elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
            // console.log('drag2:', parseInt(elmnt.style.top), parseInt(elmnt.offsetTop));
        }

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            if (isMobile) {
                document.ontouchend = null;
                document.ontouchmove = null;
            } else {
                document.onmouseup = null;
                document.onmousemove = null;
            }
            setInit(true);
        }

        function onResizeMove(e) {
            console.log('onResizeMove: move_mode=', move_mode);
            var popup = document.querySelector(`div#rid${rcpt_idx}-${posindex}`);
            var header = getHeader(popup);
    
            // height 결정
            const outerw= header.clientWidth;
            const outerh= header.clientHeight;
            const naturalh= header.naturalHeight;
            const naturalw= header.naturalWidth;
            const outerratio = outerw/outerh;
            const naturalratio = naturalw/naturalh;
            let height;
            //debugger;
            if (outerratio>naturalratio) {      //이미지보다 상자의 width가 더 큼
                height = outerh;
            } else {
                height = outerw/naturalratio;
            }
    
            // pos 결정
            const trect = header.getBoundingClientRect();

            // recipient_list를 바꿈
            props.changePosHeight(rcpt_idx, posindex, trect.left, trect.top, height, move_mode);
            setVisibleButton(true);
            setEndPos(popup.clientWidth);

        }

    }

    function getHeader(element) {
        var headerItems = element.getElementsByClassName("popup-header");
        //debugger;
        if (headerItems.length === 1) {
            //console.log('DR:headerItems============>', headerItems[0]);
            return headerItems[0];
        }
        return null;
    }

    return (
        <>
            <div className="popup" id={`rid${rcpt_idx}-${posindex}`}>
                <img className="popup-header"
                    src={sign_src}
                    alt = 'p'
                    onLoad = {onLoadImg}
                />
            </div>
            { VisibleButton &&
                <div style={{left:`${EndPos}px` , position:'absolute', display:'flex'}}>
                    <button onClick={() => props.onClickDelete(rcpt_idx, posindex)}> &times; </button>
                    {show_edit && 
                        <button onClick={props.onClickChangeSign}>
                            <span> <EditTwoTone /> </span>
                        </button>
					} 
                </div>
            }
        </>
    );
}

export default withRouter(DragResize);

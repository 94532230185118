import React, { useEffect, useState } from "react";
import { Row, Modal, Tabs, Spin} from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";
import GenerateSign from "../GenerateSign";
import { fabric } from "fabric";
import BgRemoval from "./BgRemoval";
import { useTranslation } from "react-i18next";
import utilfunc from "../../../utils/utilfunc";

function ModalSign(props) {
    const [Spinning, setSpinning] = useState(false);
    const { t } = useTranslation();
    // Fragment
    function DrawSign(){
        const [canvas, setCanvas] = useState(null);

        useEffect(()=>{
            async function asyncfunc() {
                console.log('tab 1'); 
                let canvasEl;
                for (let k=0; k<20; k++){
                    const tmp_canvasEl = document.getElementById('handwriting');
                    if (tmp_canvasEl){
                        canvasEl = tmp_canvasEl;
                        break;
                    }
                    await utilfunc.sleep(200);
                    console.log('waiting canvas', k);
                }
                if (!canvasEl){
                    alert('Error:cannot make canvas');
                }
                canvasEl.style.display='block';
                var initCanvas = new fabric.Canvas('handwriting', { isDrawingMode: true, width: 700, height: 300 });
                initCanvas.freeDrawingBrush.width = 5;
                initCanvas.freeDrawingBrush.color = "black";
                setCanvas(initCanvas);
            }
            asyncfunc();
        },[]);
    
        const clear = async () => {
            canvas.clear();
        }
    
        const download = async () => {
            setSpinning(true);
            const data = await canvas.toDataURL();
            const result = await axios.post('/api/gensign/handwriting/save', { data: data, UserDbId:props.UserDbId });
            console.log("result", result);
            if (result.data.success) {
                props.onUpdate();
            }
            setSpinning(false);
        }
    
        return (<>
            <Row justify="center">
                <canvas id="handwriting" style={{ display: 'none', border: 'solid 1px black'}}/>
                <button className="primaryBtn smallBtn" onClick={clear} style={{margin:'10px'}}>{t("Delete")}</button> 
                <button className="primaryBtn outlineSmallBtn" onClick={download} style={{margin:'10px'}}>{t("Done")}</button>
            </Row>        
            </>);
    }

    const items=[
        {
            label:t("Draw"), 
            key:"1", 
            children: <DrawSign/>  
        },
        {
            label:t("Auto Generation"), 
            key:"2", 
            children:   <>
                        {t("Create various shapes of signs and choose the one you like")}
                        <GenerateSign onUpdate={props.onUpdate} UserDbId={props.UserDbId}/>
                        </>
        },
        {
            label:t("Upload"), 
            key:"3", 
            children:  <BgRemoval onUpdate={props.onUpdate} sign_stamp='sign' UserDbId={props.UserDbId}/>
        }
    ];

    return (
    <div>
        <Modal
            title={t("Create My Sign")}
            open={props.AddMode===1}
            centered ={true}
            cancelButtonProps={{ style: { display: "none" } }}
            okText="Enter"
            width={700}
            destroyOnClose={true}
            onCancel={props.onCancel}
            footer = {null}
            className="customPopup"
          >
              <Tabs className="changeSignInnerPopup" defaultActiveKey="1" items={items}/>
              <Spin spinning={Spinning}/>
    	  </Modal>
    </div>
  );
}

export default withRouter(ModalSign);

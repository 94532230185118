import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, Typography } from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";
import ModalSign from "./Sections/ModalSign";
import ModalStamp from "./Sections/ModalStamp";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";

// Home for loged in user
function SubSignStamp(props) {
    const { Title } = Typography;
    const { t } = useTranslation();
    //const {SignUrlList, setSignUrlList, DefaultIndex, setDefaultIndex, UserDbId} = props;
    const { UserDbId, changeSign } = props;
    const [AddMode, setAddMode] = useState(0); // 0:ready, 1:add sign, 2:add stamp
    const [PrevDbId, setPrevDbId] = useState({ db: "", userId: "" });
    const [SignUrlList, setSignUrlList] = useState(0);
    const [DefaultIndex, setDefaultIndex] = useState(0);

    useEffect(() => {
        console.log("--------->", PrevDbId.db, ":::", UserDbId.db);
        console.log("--------->", PrevDbId.userId, ":::", UserDbId.userId);
        if (UserDbId.userId !== PrevDbId.userId) {
            console.log("================ called");
            get_user_sign(UserDbId);
            setPrevDbId(UserDbId);
        }
    }, [UserDbId.userId, PrevDbId.userId]);

    async function get_user_sign(userDbId) {
        const res = await axios.post("/api/gensign/get_user_sign", {
            UserDbId: userDbId,
        });
        console.log("res=", res);

        if (res.data.success) {
            const rdata = res.data.result;
            //console.log('sign url=', rdata);
            //console.log('sign url list=', rdata.signlist);
            setSignUrlList(rdata.signlist);
            setDefaultIndex(rdata.default_sign_index);
            changeSign(rdata.signlist[rdata.default_sign_index]);
        } else {
            alert("error");
        }
    }

    function onCancel() {
        setAddMode(0);
    }

    async function onClickDownload(e, sign_url) {
        e.stopPropagation();
        const res = await axios.post(
            "/api/docu/get_file_from_s3",
            { file_url: sign_url },
            { responseType: "blob" }
        );
        console.log(res.data);
        if (!res) {
            return;
        }

        const out_fname = "tmp.png";
        fileDownload(res.data, out_fname);
        console.log("download end");
    }

    async function onClickDelete(e, sign_url, index) {
        e.stopPropagation();
        const res = await axios.post("/api/gensign/delete_sign_stamp", {
            UserDbId: UserDbId,
            sign_url,
            delete_index: index,
        });
        console.log("res=", res);
        if (res.data.success) {
            const rdata = res.data.result;
            setSignUrlList(rdata.signlist);
            setDefaultIndex(rdata.default_sign_index);
            changeSign(rdata.signlist[rdata.default_sign_index]);
        } else {
            alert("Error: delete_sign_stamp: " + res.data.errmsg);
        }
    }

    async function onUpdate() {
        await get_user_sign(UserDbId);
        setAddMode(0);
    }

    async function onClickCard(index) {
        // default를 바꾸어 줌
        await axios.post("/api/gensign/change_default", {
            default_sign_index: index,
            UserDbId: props.UserDbId,
        });
        changeSign(SignUrlList[index]);
        setDefaultIndex(index);
    }

    const signatures =
        SignUrlList &&
        SignUrlList.map((url, index) => {
            let card_style = { height: "auto" };
            if (index === DefaultIndex) {
                card_style.border = "1px solid orange";
            }

            return (
                <div key={index} className="signCard">
                    <Card onClick={() => onClickCard(index)} style={card_style}>
                        <div className="signCardImg">
                            <img
                                src={url}
                                alt="sign"
                                style={{
                                    maxWidth: "100%",
									height: '40px',
                                    objectFit: "contain",
                                }}
                            />
                        </div>
                        <div className="signCardBtnWrap">
                            <div
                                className="smallOutlineBtn"
                                onClick={(e) => onClickDownload(e, url)}
                            >
                                <span>
                                    <CloudDownloadOutlined />
                                </span>
                            </div>
                            <div
                                className="smallOutlineBtn deleteSmallBtn"
                                onClick={(e) => onClickDelete(e, url, index)}
                            >
                                <span>
                                    <DeleteOutlined />
                                </span>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        });

    ///////////////////////////////////////////////////////
    function default_image() {
        const url = SignUrlList[DefaultIndex];
        return (
            <img
                src={url}
                alt="sign"
                style={{ maxWidth: "100%", height:'40px', objectFit: "contain" }}
            />
        );
    }

    return (
        <div>
            <Row
                gutter={[20, 16]}
                justify="space-around"
                className="changeSignMobile"
            >
                {/* 버튼 */}
                {/* default signs */}

                <Col span={12}>
                    <div style={{ position: "relative" }}>
                        <Title level={4}>{t("Default sign")}</Title>
                        <Card>
                            <Row justify="center">{default_image()}</Row>
                        </Card>
                    </div>
                </Col>
                <Col span={12}>
                    <Title level={4}>{t("Select sign")}</Title>
                    <div className="signCardWrap">{signatures}</div>
                </Col>
                <Col span={24}>
                    <div>
                        <Row justify="center" style={{ paddingTop: 20 }}>
                            <Button
                                type="primary"
                                className="primaryBtn outlineSmallBtn"
                                style={{ marginInline: 5 }}
                                onClick={() => setAddMode(1)}
                            >
                                {t("Make New Sign")}
                            </Button>

                            <Button
                                type="primary"
                                className="primaryBtn outlineSmallBtn"
                                style={{ marginInline: 5 }}
                                onClick={() => setAddMode(2)}
                            >
                                {t("Make New Stamp")}
                            </Button>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row></Row>

            <ModalSign
                onCancel={onCancel}
                onUpdate={onUpdate}
                AddMode={AddMode}
                UserDbId={UserDbId}
            />
            <ModalStamp
                onCancel={onCancel}
                onUpdate={onUpdate}
                AddMode={AddMode}
                UserDbId={UserDbId}
            />
        </div>
    );
}

export default withRouter(SubSignStamp);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Steps } from "antd";
import ProcUpload from "./ProcUpload";
import ProcAddRcpt from "./ProcAddRcpt";
import ProcSetPos from "./ProcSetPos";
import ProcVSign from "./ProcVSign";
import ProgressStep from "./ProgressStep";
import ProcAgree from "./ProcAgree";
import { useTranslation } from "react-i18next";
import CircleIcon01 from "../../../assets/images/VideoSign/circle01.svg"
import CircleIcon02 from "../../../assets/images/VideoSign/circle02.svg";
import ProcVCVSign from "./VContract/ProcVCVSign";
import ProcVCAddRcpt from "./VContract/ProcVCAddRcpt";
import ProcVCAgree from "./VContract/ProcVCAgree";

// Home
// url parsing을 통해서 얻어진 func는 초기 state를 결정하고 이후에는 reloading을 피하기 위해서 setstate를 사용.
function MakeContract(props) {
    const { t } = useTranslation();
    const evsign = props.match.params.evsign;
    const func = props.match.params.func;
    const docuId = props.match.params.docuId;
    //const { Sider, Content } = Layout;
    const [State, setState] = useState(func);   //현재 진행중인 작업이 무엇인지 표시.
    const [Current, setCurrent] = useState(0);

    //console.log('=================> MakeContract');

    useEffect(() => {
        if (evsign==='esign') {
            switch (State) {
                case "upload":      setCurrent(0);      break;
                case "addrcpt":     setCurrent(1);      break;
                case "setpos":      setCurrent(2);      break;
                case "agree":       setCurrent(3);      break;
                default:            setCurrent(0);      break;
            }
        } else if (evsign==='vsign'){
            switch (State) {
                case "upload":      setCurrent(0);      break;
                case "addrcpt":     setCurrent(1);      break;
                case "setpos":      setCurrent(2);      break;
                case "vsign":       setCurrent(3);      break;
                case "agree":       setCurrent(4);      break;
                default:            setCurrent(0);      break;
            }
        } else {
            switch (State) {
                case "vcrecord":    setCurrent(0);      break;
                case "vcaddrcpt":   setCurrent(1);      break;
                case "vcagree":     setCurrent(2);      break;
                default:            setCurrent(0);      break;
            }
        }
    }, [State, evsign]);


    /////////////////////// upload related ///////////////////

    // const pcProcessBar = () => {
    //     return (
    //         <div>
    //             <Sider width={250} style={{ minHeight: "60vh", background: "#eee" }}>
    //                 <div style={{ margin: "16px" }}>
    //                     <font size={4} color="black">
    //                         STAR Net
    //                     </font>
    //                     <br />
    //                     <font size={4} color="black">
    //                         NFT SIGN
    //                     </font>
    //                     <br />
    //                     <br />
    //                     <br />
    //                     <ProgressStep State={State} />
    //                     <br />
    //                     <br />
    //                 </div>
    //             </Sider>
    //         </div>
    //     );
    // };

    const mobileProcessBar = () => {
        const items = (evsign==='esign')?   [{ title: t("Upload") }, { title: t("Configuring Contractors") }, { title: t("Set Position") }, { title: t("Finalize") }] :
                      (evsign==='vsign')?   [{ title: t("Upload") }, { title: t("Configuring Contractors") }, { title: t("Set Position") }, { title: t("Video Sign") }, { title: t("Finalize") }]:
                                            [{ title: t("Record") }, { title: t("Configuring Contractors") }, { title: t("Finalize") }];

        return (
            <div className="c-m-process-bar stepProcessBar">
                <Steps
                    current={Current}
                    direction="horizontal"
                    size="small"
                    responsive={false}
                    items={items}
                />
            </div>
        );
    };

    //console.log('MC:state=', State)
    return (
        <div className="dashboardContentWrap">
            <div className='funnelContent'>
                <div className="funnelCircle">
                    <img src={CircleIcon01} alt="circle" className="circleImg circle01" />
                    <img src={CircleIcon02} alt="circle" className="circleImg circle02" />
                </div>
                {mobileProcessBar()}
                {
                    State === "upload" ?    <ProcUpload evsign={evsign} /> :
                    State === "addrcpt" ?   <ProcAddRcpt evsign={evsign} docuId={docuId} /> :
                    State === "setpos" ?    <ProcSetPos evsign={evsign} docuId={docuId} /> :
                    State === "vsign" ?     <ProcVSign evsign={evsign} docuId={docuId} /> :
                    State === "agree" ?     <ProcAgree evsign={evsign} docuId={docuId} /> :
                    State === "vcrecord"?   <ProcVCVSign docuId={docuId}/> :
                    State === "vcaddrcpt"?  <ProcVCAddRcpt docuId={docuId} /> :
                    State === "vcagree"?    <ProcVCAgree docuId={docuId} /> :
                                            "Make Contract"
                }
            </div>
        </div>
    );
}

export default withRouter(MakeContract);

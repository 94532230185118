import React, { useState } from "react";
import { Row, Button, Col } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Generate signature
function GenerateSign(props) {
  const { t } = useTranslation();
  const [sign, setSign] = useState('');
  const [warn, setWarn] = useState('');
  const [signImage, setSignImage] = useState([]);
  const [chosenSign, setChosenSign] = useState({ index: -1, img: ''});
  const [lang, setLang] = useState('en');
  //const [showLang, setShowLang] = useState(false);

  const changeSign = async (e) => {
    if (e.target.value.length > 20) {
      setWarn('sign only allowed 20 words');
      return;
    }
    setSignImage([]);
    setWarn('');
    setSign(e.target.value);
  }
  const createSign = async () => {
    setWarn('');
    if (!sign.trim()) {
      setWarn('sign 공백 노노');
      return;
    }
    // if (!lang) {
    //   setWarn('언어 노 선택 노노');
    //   return;
    // }
    await axios.post('/api/gensign/create/sign', { sign: sign, lang: null, num: 6 }).then((response) => {
      //console.log('create sign data=', response.data);
      setSignImage(response.data);
    });
  }
  
  const chooseSign = (v, i) => {
    setChosenSign({index: i, img: v});
  }


  const saveSign = async () => {
    await axios.post('/api/gensign/save/sign', { img: chosenSign.img, UserDbId:props.UserDbId }).then((response) => {
      //setSignImage(response.data);
      setSignImage(null);
    });
  }

  //////////////////////////////////////////////
  async function onClickSelect() {
      await saveSign();
      await props.onUpdate();
  }

  function createSignStamp() {
      createSign();
  }

  signImage && console.log('----------------->', signImage.length)
  const render_sign = signImage && signImage.map((v, i) => {
    const classname = chosenSign.index === i ? 'chosen-sign' : 'sign-area';
    return (
        <img className={classname} src={v} width={200} key={`sign-${i}`} onClick={()=>{chooseSign(v, i);}} alt='sign'/>
    )}
  ); 

  return (
    <>
    <Row gutter={20} style={{marginTop:20}}>
      {/* <Col lg={12} xs={24}>
        <div className="langSelect" onClick={() => {setShowLang(!showLang);} }>
          <div style={{padding: '0 10px 0 0'}}>
            {lang ? (lang === 'jp' ? t('Japanese') : t('English')) : t('Language')}
          </div>
          <DownOutlined />
        </div> 
        <div className="langdropdown" style={{display: showLang ? 'block': 'none' }}>
          <p className="lang-area" onClick={() => {setLang('jp'); setShowLang(false);}}>{t("Japanese")}</p>
          <p className="lang-area" onClick={() => {setLang('en'); setShowLang(false);}}>{t("English")}</p>
        </div>
      </Col> */}
    
    <Col lg={12} xs={24}>
      <div className="formGroup">
        <input className="formControl" type='text' onChange={(e) => {changeSign(e);}} onKeyUp={(e) => {if(e.code === "Enter") createSignStamp();}} value={sign}/>
      </div>
    </Col>


    <Col lg={24} xs={24}>
      <button className="primaryBtn smallBtn" onClick={createSignStamp} style={{cursor:'pointer'}}>{t("Generate")}</button>
    </Col>

    </Row>
      
      <p style={{color: 'red',}}>{warn}</p>

      {/* { signImage ? signImage.map((v, i) => {return <img className={chosenSign.index === i ? 'chosen-sign' : 'sign-area'} src={v} width={300} key={`sign-${i}`} onClick={()=>{chooseSign(v, i);}}/>}) : <></> } */}
      {/* { signImage && chosenSign.img ? <button onClick={() => {saveSign();}}>save sign</button> : <></> } */}
      {render_sign}

      <Row align='end'>
          <Button className="primaryBtn outlineSmallBtn" disabled={!chosenSign.img} onClick={onClickSelect}>{t("Select")}</Button>
      </Row>
    
    </>
  
  );
}

export default withRouter(GenerateSign);

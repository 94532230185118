
const SIGN_POSITION_BOX_HALF_WIDTH = 32;
const STAMP_CURSOR_HALF_WIDTH = 19;
const DEFAULT_SIGN_HEIGHT = 35;
const IMG_DISPLAY_HEIGHT = 800;         // png 1page를 그릴 때의 높이
//const IMG_DISPLAY_HEIGHT = 513;         // png 1page를 그릴 때의 높이 (mobile default)
const IMG_DISPLAY_MARGIN = 10;          // png 와 png사이는 2*IMG_DISPLAY_MARGIN 만큼 떨어져 있음
const IMG_HEIGHT_WITH_PADDING = IMG_DISPLAY_HEIGHT + 2*IMG_DISPLAY_MARGIN;      // 전체 scroll window의 높이는 num_page * IMG_HEIGHT_WITH_PADDING
const DEFAULT_BLANK_SIGN = 'https://test2-metabox.s3.ap-northeast-1.amazonaws.com/system/blank_sign1-removebg.png';

// 각각의 서명자들이 서명해야 할 위치를 표시할 때 사용하는 색깔
const MY_COLOR_TABLE = ['#FFB2F5AA', '#6699FFAA', '#FFBB00AA', '#F15F5FAA', '#B2FFF5AA', '#9966FFAA', '#FF88BBAA', '#F15F5FAA'];  //todo


export {
    SIGN_POSITION_BOX_HALF_WIDTH,
    STAMP_CURSOR_HALF_WIDTH,
    DEFAULT_SIGN_HEIGHT,
    MY_COLOR_TABLE,
    IMG_DISPLAY_HEIGHT,
    IMG_DISPLAY_MARGIN,
    IMG_HEIGHT_WITH_PADDING,
    DEFAULT_BLANK_SIGN
}
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Button, Typography, Image, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import Icon01 from "../../../assets/images/VideoSign/icon01.png";
import Icon02 from "../../../assets/images/VideoSign/icon02.png";
import Icon03 from "../../../assets/images/VideoSign/icon03.png";
import Icon04 from "../../../assets/images/VideoSign/icon04.png";
import Icon05 from "../../../assets/images/VideoSign/icon05.png";
import Icon06 from "../../../assets/images/VideoSign/icon06.png";
import img01 from "../../../assets/images/VideoSign/img01.jpg";
import img02 from "../../../assets/images/VideoSign/img02.jpg";
import img03 from "../../../assets/images/VideoSign/img03.jpeg";
import BannerGif from "../../../assets/images/VideoSign/videoBanner.gif";

// Home for loged in user
function LandingNotLogin(props) {

    const { t } = useTranslation();
    const { Title, Text } = Typography;

    return (
        <div className="homePageWraper">
            <div className="homeBanner">
                <div className="bannerVideo">
                  {/* <video width="100%" height="100%" autoPlay loop>
                    <source src={BannerVideo} type="video/mp4"/>
                  </video> */}
                  <img src={BannerGif} alt="banner-gif" className="bannerGif"/>
                </div>
                <div className="container">
                    <div className="bannerContent">
                        <Row align="center">
                            <Col align="center">
                                <Title>{t("VIDIO SIGN IS NFT NOTARY SIGN")}</Title>
                                <Title className="JapaneseLang" level={2}>{t("Forgery and re-recording impossible")}</Title>
                                <Title level={2}>{t("EASIEST / STRONGEST/ NFT SIGN")}</Title>
                                <Link className="primaryBtn" to='/register'>{t("TRY NOW")}</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* <Link className="scrollDwon"></Link> */}
            </div>

            <section className="sectionPadding">
                <div className="container">
                    <Row gutter={16}>
                        <Col sm={8} xs={24}>
                            <div className='serviceBlock'>
                                <Image src={Icon01} preview={false} />
                                <Text>{t("Select Document")}</Text>
                                <Text>{t("electronic contract")}</Text>
                                <Text>{t("Video Signature")}</Text>
                                <Text>{t("Video Contract")}</Text>
                            </div>
                        </Col>
                        <Col sm={8} xs={24}>
                            <div className='serviceBlock'>
                                <Image src={Icon02} preview={false} />
                                <Text>{t("One Click for Signing")}</Text>
                            </div>
                        </Col>
                        <Col sm={8} xs={24}>
                            <div className='serviceBlock'>
                                <Image src={Icon03} preview={false} />
                                <Text>{t("Add Video Signing")}</Text>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className='sectionPadding fullWidthBlock'>
                <div className='container'>
                    <Row>
                        <Col lg={12} className='positionDefault'>
                            <div className='leftRightImg'>
                                <Image src={img01} preview={false} />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className='leftRightContent'>
                                <div className='sectionTilteWrap'>
                                    <Title className='sectionTilte' level={3}>{t("Simple One Click Signing")}</Title>
                                    <Text>{t("Forget the complicated electronic contract procedures that are difficult to understand.")} {t("Contract signing is completed immediately with one click.")} <Link to='/eSignature'>{t("Read more")}</Link></Text>
                                </div>
                                <Row gutter={16}>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon01} preview={false} />
                                            <Text>{t("Send Document with Email or Messenger")}</Text>
                                        </div>
                                    </Col>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon02} preview={false} />
                                            <Text>{t("One-click the Sign button")}</Text>
                                        </div>
                                    </Col>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon04} preview={false} />
                                            <Text>{t("The signed contract and NFT certificate will be sent to the contractor.")}</Text>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>


            <section className='sectionPadding fullWidthBlock rightImg'>
                <div className='container'>
                    <Row>
                        <Col lg={12}>
                            <div className='leftRightContent'>
                                <div className='sectionTilteWrap'>
                                    <Title className='sectionTilte' level={3}>{t('VIDEO SIGNING')}</Title>
                                    <Text>{t("It is a signature that includes a video indicating that the contractor agrees to the signing when signing the contract. Video signatures fundamentally block the risk of forged signatures. A link to the video is provided at the bottom of the electronic signature.")} <Link to='/video-signature'>{t("Read more")}</Link></Text>
                                </div>
                                <Row gutter={16}>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon03} preview={false} />
                                            <Text>{t("Say 'I accept this agreement' in front of your mobile phone.")}</Text>
                                        </div>
                                    </Col>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon05} preview={false} />
                                            <Text>{t("Your contract is converted to NFT along with the video and saved as NFT.")}</Text>
                                        </div>
                                    </Col>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon06} preview={false} />
                                            <Text>{t("Video Sign is more likely to have stronger legal protection than notarization.")}</Text>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col lg={12} className='positionDefault'>
                            <div className='leftRightImg'>
                                <Image src={img02} preview={false} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className='sectionPadding fullWidthBlock'>
                <div className='container'>
                    <Row>
                        <Col lg={12} className='positionDefault'>
                            <div className='leftRightImg'>
                                <Image src={img03} preview={false} />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className='leftRightContent'>
                                <div className='sectionTilteWrap'>
                                    <Title className='sectionTilte' level={3}>{t("Video Contract")}</Title>
                                    <Text>{t("Create a video contract with just a video shoot on your mobile phone or laptop. Contractors can talk about the contents of the contract together verbally in the video contents. Video images are immediately converted into NFTs and created as certificate documents. This video contract certificate is sent to all contractors' emails immediately.")} <Link to='/video-contract'>{t("Read more")}</Link></Text>
                                </div>
                                <Row gutter={16}>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon03} preview={false} />
                                            <Text>{t("Contractors gather and shoot a video with mobile phone or laptop. The recorded video is immediately converted to NFT and saved.")}</Text>
                                        </div>
                                    </Col>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon04} preview={false} />
                                            <Text>{t("Video contracts and certificates are sent to all contractors via email.")}</Text>
                                        </div>
                                    </Col>
                                    <Col sm={8} xs={24}>
                                        <div className='serviceBlock'>
                                            <Image src={Icon06} preview={false} />
                                            <Text>{t("In case of legal trouble, a video contract can be a strong proof.")}</Text>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className='sectionPadding'>
                <div className='container'>
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className='sectionTilteWrap' align="center">
                                <Title className='sectionTilte' level={2}>{t("Can be used for various contracts")}</Title>
                            </div>
                        </Col>

                        <Col lg={8} sm={12} xs={24}>
                            <Card className='featuredCard'>
                                <div className='cardText'>
                                    <Title level={5}>{t("Labor Contract")}</Title>
                                    <Text>{t("Employment Contract")}, {t("Notice of Working Conditions")}, {t("A Letter of Guarantee")}, {t("Notice of Employment Offer")}, {t("Letter of Employment Pledge (Written Acceptance of Employment)")}. </Text>
                                </div>
                                <div className='cardArrow'>
                                    <RightOutlined size={50} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} sm={12} xs={24}>
                            <Card className='featuredCard'>
                                <div className='cardText'>
                                    <Title level={5}>{t("Trading System")}</Title>
                                    <Text>{t("Goods Sales Contract")}, {t("Land Sales Contract")}, {t("Building Sales Contract")}, {t("Real-Estate Sales Contract")}  </Text>
                                </div>
                                <div className='cardArrow'>
                                    <RightOutlined size={50} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} sm={12} xs={24}>
                            <Card className='featuredCard'>
                                <div className='cardText'>
                                    <Title level={5}>{t("Lease System")}</Title>
                                    <Text>{t("Building Lease Agreement")}, {t("Land Lease Agreement")}, {t("Parking Contract")}, {t("Building Lease")} </Text>
                                </div>
                                <div className='cardArrow'>
                                    <RightOutlined size={50} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} sm={12} xs={24}>
                            <Card className='featuredCard'>
                                <div className='cardText'>
                                    <Title level={5}>{t("Sales and Purchasing Systems")}</Title>
                                    <Text>{t("Basic Transaction Agreement")}, {t("Service Application Form")}, {t("Order Form")}, {t("Order Receipt")}, {t("Bill")}, {t("Receipt")} </Text>
                                </div>
                                <div className='cardArrow'>
                                    <RightOutlined size={50} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} sm={12} xs={24}>
                            <Card className='featuredCard'>
                                <div className='cardText'>
                                    <Title level={5}>{t("Money Loan System")}</Title>
                                    <Text>{t("Loan-to-Consumption Contract")}, {t("Money Loan")}, {t("Debt-Approval Performance Contract")}, {t("Bond Assignment Agreement")} </Text>
                                </div>
                                <div className='cardArrow'>
                                    <RightOutlined size={50} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} sm={12} xs={24}>
                            <Card className='featuredCard'>
                                <div className='cardText'>
                                    <Title level={5}>{t("Business Delegation / Department of Accountability")}</Title>
                                    <Text>{t("Business Consignment Contract")}, {t("Contract")}, {t("Construction Contract")}, {t("Dealership Agreement")}, {t("Agency Contract")}, {t("Consignment Contract for Merchandise Sales")}, {t("Supply Contract")}, {t("Manufacturing Consignment Contract")} </Text>
                                </div>
                                <div className='cardArrow'>
                                    <RightOutlined size={50} />
                                </div>
                            </Card>
                        </Col>
                        <Col lg={8} sm={12} xs={24}>
                            <Card className='featuredCard'>
                                <div className='cardText'>
                                    <Title level={5}>{t("Other")}</Title>
                                    <Text>{t("Non-disclosure Agreement")}, {t("Confidentiality Agreement")}, {t("Share Transfer Agreement")}, {t("Personal Information Handling Agreement")}, {t("Contract Change Agreement")}, {t("Notice of Cancellation of Contract")}, {t("Division of Inheritance")}, {t("Death-gift Agreement")}, {t("Copyright Transfer Agreement")}, {t("Merger Agreement")}, {t("Minutes of a Board Meeting")}  </Text>
                                </div>
                                <div className='cardArrow'>
                                    <RightOutlined size={50} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="bgSection">
                <div className="container">
                    <div className="bannerContent">
                        <Row align="center">
                            <Col align="center">
                                <Title>{t("Get Started right now!")}</Title>
                                <Title level={2}>{t("Try VidioSign eSignature for 30 days with free.")}</Title>
                                <Button className="primaryBtn widthBtn" type="primary">{t("1 month free trial")}</Button>
                                <Text className="spanText">{t("No credit card required.")}</Text>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>

            <section className="contactSectionWrap">
                <div className="container">
                    <Row align="center">
                        <Col>
                            <div className="contactSection">
                                <div className="contactEmail">
                                    <Text>info@vidiosign.com</Text>
                                </div>
                                <div className="contactButton">
                                    <Button className="primaryBtn widthBtn" type="primary">{t("Contact Us")}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    );
}

export default withRouter(LandingNotLogin);


import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Auth from "../hoc/auth";

import helper from "../components/utils/Helper";
import Footer from "../components/views/14_Footer/Footer";
import NavBar from "../components/views/00_NavBar/NavBar";
import Page404 from "../components/views/ErrorPages/Page404";
import FaqPage from "../components/views/14_Footer/Sections/FaqPage";
import LoginPage from "../components/views/02_LoginPage/LoginPage.js";
import ThankyouPage from "../components/views/02_LoginPage/ThankyouPage";
import Registration from '../components/views/02_LoginPage/Registration';
import Plans from '../components/views/40_Payment/Plans';

import AboutPage from "../components/views/14_Footer/Sections/AboutPage";
import TermsPage from "../components/views/14_Footer/Sections/TermsPage";
import VSignMobile from '../components/views/20_MakeContract/VSignMobile';
import VCMobile from '../components/views/20_MakeContract/VContract/VCMobile';
import RefundPage from "../components/views/14_Footer/Sections/RefundPage";
import LandingPage from "../components/views/10_LandingPage/LandingPage.js";
import PrivacyPage from "../components/views/14_Footer/Sections/PrivacyPage";
import ServicesPage from "../components/views/14_Footer/Sections/ServicesPage";
import ESignature from "../components/views/14_Footer/Sections/ESignature";
import VideoContract from "../components/views/14_Footer/Sections/VideoContract";
import VideoSignature from "../components/views/14_Footer/Sections/VideoSignature";
import LineLoginCallback from '../components/views/02_LoginPage/LineLoginCallback';
import MainOutStartSign from '../components/views/21_Signing/MainOutStartSign';
import MainVideoConfirm from '../components/views/21_Signing/MainVideoConfirm';

import AdminPage from "../components/views/AdminPage/AdminPage";
// import EditEvent from "../components/views/AdminPage/InnerPages/Events/EditEvent";
// import EditCategory from "../components/views/AdminPage/InnerPages/Categories/EditCategory";
// import RegisterPage from "../components/views/01_RegisterPage/RegisterPage.js";
// import MyProfilePage from "../components/views/03_MyProfile/MyPage";
// import Notice from "../components/views/14_Footer/Sections/Notice";
// import MyNFTPage from "../components/views/12_MyNFTPage/MyNFTPage";

const SubRoutes = (match) => {

    const pathName = window.location.pathname;

    return (
        <>
            <NavBar language={helper.fetchLanguageForUrl(pathName)} />
            <Switch>
                {/* null-->누구나 접근가능, true-->로그인한 사용자만 접근가능, false-->로그인 안한 사용자만 접근가능 */}
                <Route exact path="/:lang/" component={Auth(LandingPage, null)} />
                <Route exact path="/:lang/login" component={Auth(LoginPage, false)} />
                <Route exact path="/:lang/register" component={Auth(Registration, false)} />
                <Route exact path="/:lang/plans" component={Auth(Plans, true)} />

                <Route exact path="/:lang/thankyou" component={Auth(ThankyouPage, true)} />
                <Route exact path="/:lang/vsign_mobile/:target_enc" component={Auth(VSignMobile, null)} />
                <Route exact path="/:lang/vc_mobile/:target_enc" component={Auth(VCMobile, null)} />
                <Route exact path="/:lang/outsign/:docuId/:emailenc" component={Auth(MainOutStartSign, null)} />
                <Route exact path="/:lang/video_confirm/:docuId/:emailenc" component={Auth(MainVideoConfirm, null)} />
                {/* Footer Routes */}
                <Route exact path="/:lang/faq" component={Auth(FaqPage, null)} />
                <Route exact path="/:lang/services" component={Auth(ServicesPage, null)} />
                <Route exact path="/:lang/about" component={Auth(AboutPage, null)} />
                <Route exact path="/:lang/eSignature" component={Auth(ESignature, null)} />
                <Route exact path="/:lang/video-signature" component={Auth(VideoSignature, null)} />
                <Route exact path="/:lang/video-contract" component={Auth(VideoContract, null)} />
                <Route exact path="/:lang/terms" component={Auth(TermsPage, null)} />
                <Route exact path="/:lang/refund" component={Auth(RefundPage, null)} />
                <Route exact path="/:lang/privacy" component={Auth(PrivacyPage, null)} />
                <Route exact path="/:lang/callback_linelogin" component={Auth(LineLoginCallback, false)} />
                <Route exact path="/:lang/admin" component={Auth(AdminPage, true)} />
                <Route component={Page404} exact />
                {/* <Route exact path="/:lang/myprofile" component={Auth(MyProfilePage, true)} /> */}
            </Switch>
            <Footer />
        </>
    );
};

export default SubRoutes;
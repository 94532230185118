import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import docuutil from "../../../utils/DocuUtil";

// Docu-->ModalInfoSign-->DocuHistory
function DocuHistory(props) {
    const history_list = props.docu.history;
    const { t } = useTranslation();
    const { i18n } = useTranslation('Home');

    function gen_message(history) {
        const user = `${history.name}(${history.contact})`;
        switch (history.action) {
            case 'upload': return `${user} ${t("uploaded contract document.")}`;
            case 'transfer': return `${t("System send email to")} ${user} ${t("for requesting signature.")}`;
            case 'agree': return `${user} ${t("signed contract.")}`;
            case 'reject': return `${user} ${t("denied contract.")}`;
            case 'reject_legal': return `${user} ${t("rejected e-sign agreement.")}`;
            case 'reject_vconfirm': return `${user} ${t("denied to confirm recorded video for contract.")}`;
            case 'all_agree': return `${t("All recipient agree to contract.")}`;
            case 'transfer_completion': return `${t("System sent sign completed contract to")} ${user}.`;
            case 'hash_nft': return `${t("NFT of signed document hash has been created.")}`;
            case 'video_confirm_req': return `${t("Video Confirm request sent to")} ${history.contact}.`;
            case 'video_confirm': return `${history.name}(${history.contact}) ${t("confirmed all Videos.")}`;
        }
    }

    function gen_time_message(utc_timestamp, language) {
        const d = new Date(utc_timestamp);
        //return d.toLocaleString();
        
        return docuutil.my_locale_string(d, language);
    }

    const render_history = history_list && history_list.map(function(history, index){
        //console.log('------------------>', history.time, history.action);
        return (
            <div className="progressStatus" key={index}>
                <font size={3} className="progressTxt"> {gen_message(history)} </font> 
                <font className="progressTime"> {gen_time_message(history.time, i18n.language)} </font>
                {/* <font className="progressTime"> {docuutil.my_locale_string(history.time, i18n.language)} </font> */}
            </div>
        )}
    );

    return (
        <div className="progressStatusTab">
            {render_history}
        </div>
    );
}

export default withRouter(DocuHistory);

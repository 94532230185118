import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from 'antd';
import { useTranslation } from "react-i18next";

const Page404 = () => {
    
    const { t } = useTranslation();

    return (
        <div className='container'>
            <Result 
                status="404" 
                title="404"
                className='content' 
                subTitle={t('Sorry, the page you visited does not exist.')} 
                extra={<Link to="/" className='ant-btn ant-btn-primary'>{t('Back Home')}</Link>}/>
        </div>
    );
};

export default Page404;
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import SubRoutes from './SubRoutes';
import InnerRoutes from './InnerRoutes';
import Page404 from "../components/views/ErrorPages/Page404";
import ScrollToTop from './ScrollToTop';

import Auth from "../hoc/auth";

const Routes = () => {
    
    return (
        <>
            <ScrollToTop />
            <Switch>
                {/* null-->누구나 접근가능, true-->로그인한 사용자만 접근가능, false-->로그인 안한 사용자만 접근가능 */}
                <Route path="/:lang/home" component={Auth(InnerRoutes, true)} />
                <Route path="/:lang" component={SubRoutes} />
                <Route component={Page404} exact />
            </Switch>
        </>
        
    );
};

export default withRouter(Routes);

import React, { Fragment, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useEffect } from 'react';

const LocalizedRouter = ({ children, RouterComponent, defaultLanguage, AppLanguage }) => {

    const { i18n } = useTranslation();
    const [DLanguage, setDLanguage] = useState(null);

    useEffect(()=>{
        async function reset_lang() {
            const location = await axios.get("https://ipinfo.io/?token=7806b5ce9366b1");    //ip를 기반으로 language알아냄
            if (location) {
                const first_language = (location.data.country==='JP')? 'Japanese' : 'English';
                i18n.changeLanguage(first_language);
                setDLanguage(first_language);
            };
        }

        if (defaultLanguage) {
            i18n.changeLanguage(defaultLanguage);
            setDLanguage(defaultLanguage);
        }
        else {
            reset_lang();
        }
    }, [defaultLanguage]);

    return (
        DLanguage&&<RouterComponent>
            <Route path="/:lang([a-zA-Z]{2})">
                {({ match, location }) => {
                    /**
                     * Get current language
                     * Set default locale to en if base path is used without a language
                     */
                    const params = match ? match.params : {};
                    let { lang = AppLanguage[`${DLanguage}`] } = params;

                    /**
                     * If language is not in route path, redirect to language root
                     */
                    const { pathname } = location;
                    if (pathname.includes(`/${lang}`) === false) {
                        return <Redirect to={`/${lang}${location?.pathname}${location?.search}`} />;
                    } 
                
                    /**
                     * Return Intl provider with default language set
                     */
                    return (
                        <Fragment>
                            {children}
                        </Fragment>
                    );
                }}
            </Route>
        </RouterComponent>
    );
};

export default LocalizedRouter;
import React, { useRef } from 'react';
import { Link } from "react-scroll";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

function PrivacyPage() {
    const { t } = useTranslation();
    const { Title } = Typography;

    return (
        <div className="contentPages content">
            <div className="container">
                <Title className="main__title">{t("Privacy Notice")}</Title>
                <p>{t("Version Date: December 23, 2022")}</p>
                <h3>{t("Purpose")}</h3>
                <p>{t("At VidioSign, privacy is a priority. The purpose of this document is to set out how VidioSign, Inc. and its affiliates (“us,” “our,” or “we”) collect, use, store, or otherwise process personal information about customers and other individuals (collectively “you”) who access or use our websites, including VidioSign.com and VidioSign.net, our mobile applications, our web client or professional client, and/or any of our other websites, products, or services that link to this Privacy Notice (the “Services”). By using our Services, you understand that we will collect and use your personal information as described in this Privacy Notice.")}</p>
                <p>{t("In some cases, we may process your personal information pursuant to an agreement with a third party organization. In those cases, the terms of that agreement may govern how we process your personal information. If you believe a third party organization has asked us to process your personal information on their behalf, please consult with them in the first instance as they will be responsible for how we process your information. This Privacy Notice (“Notice”) does not apply to any third-party websites and apps that you may use, including those to which we link in our Services. You should review the terms and policies for third-party websites and apps before clicking on any links.")}</p>
                <p>{t("VidioSign's core product and Services help users create, complete, and show the validity of digital or electronic transactions, such as electronically signing a contract for mobile phone services or placing a digital signature on a loan application. As part of our Services, users want us to collect and record information that helps the parties prove the validity of the transactions, such as the names of the persons who are involved in the transactions and the devices those persons use.")}</p>
                <p>{t("We recommend that you read this Notice in full to ensure you are fully informed about the manner in which we collect, use, store, or otherwise process your personal information as well as your privacy rights. However, if you want to skip to a particular section of this Notice, please refer to the table of contents below.")}</p>
                <ol>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-1'>{t("Collection of Personal Information")}</Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-2'>{t("Use of Personal Information")}</Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-3'>{t("Personal Information Sharing")}</Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-4'>{t("Retention of Personal Information")}</Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-5'>{t("Your Choices")}</Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-6'>{t("Children's Privacy")} </Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-7'>{t("Your Privacy Rights")} </Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-8'>{t("How We Protect Your Personal Information")}</Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-9'>{t("Changes to This Privacy Notice")}</Link>
                    </li>
                    <li>
                        <Link spy={true} smooth={true} offset={-60} duration={500} to='section-10'>{t("How to Contact Us")} </Link>
                    </li>
                </ol>
          
                    <h3 id='section-1'>1. {t("Collection of Personal Information")}</h3>
                    <p>{t("You have choices about whether you visit our websites, install our apps, or provide personal information to us. However, if you do not provide us with certain personal information, you may not be able to use some parts of our Services. For example, if you do not adopt an electronic signature, then you will not be able to sign certain electronic documents on our Service. For choices and rights you may have, please see Sections 5 and 8 of this Notice.")}</p>
                    <p>
                        <strong> {t("Personal Information We Collect from You.")}</strong> {t("You provide us with personal information about yourself when you:")}
                    </p>
                    <ul>
                        <li>{t("Register or log in to your account.")}</li>
                        <li>{t("Start, sign, or review an electronic document.")}</li>
                        <li>{t("Create or edit your user profile.")}</li>
                        <li>{t("Contact customer support.")}</li>
                    </ul>
                    <p>{t("You also provide us with personal information about others when you use parts of our Services, such as when you:")}</p>
                    <ul>
                        <li>{t("Start or participate in an electronic transaction, such as an envelope within VidioSign Signature.")}</li>
                        <li>{t("Share information in VidioSign Rooms Service.")}</li>
                        <li> {t("Add others as a member to an existing account.")}</li>
                        <li>{t("Leave comments.")}</li>
                    </ul>
                    <p>{t("Your main choice for this type of personal information is simply not providing it, such as by not creating a profile or not leaving a comment in a blog. For other choices you may have, please see Section 5 of this Privacy Notice.")}</p>
                    <p>{t("Examples of the categories of personal information you may provide are:")}</p>
                    <ul>
                        <li>
                            <strong>{t("Identifiers and contact information.")}</strong> {t("This includes your name, email address, mailing address, phone number, or electronic signature.")}
                        </li>
                        <li>
                            <strong>{t("Commercial information.")}</strong> {t("This includes billing and payment information (e.g., credit card number, expiration date, visual cryptogram), products or services purchased.")}
                        </li>
                        <li>
                            <strong>{t("Geolocation.")}</strong> {t("This includes physical location")}
                        </li>
                    </ul>
                    <p>
                        <strong>{t("Personal Information We Collect Automatically.")}</strong> {t("We may automatically collect personal information from you and your devices when you use our Services, including when you visit our websites or apps without logging in. For choices you may have on what information we automatically collect, please see Section 5 of this Privacy Notice.")}
                    </p>
                    <p>{t("The categories of personal information we may automatically collect includes:")}</p>
                    <p>
                        <strong>{t("Device, Usage Information, and Transactional Data.")}</strong> {t("We collect personal information about how you use our Services and the devices (e.g., computers, mobile phones, tablets) you use to access our Services. This may include, but is not limited to, the following:")}
                    </p>
                    <ul>
                        <li>{t("IP address.")}</li>
                        <li>{t("Precise geolocation information that you allow our apps to access (usually from your mobile device).")}</li>
                        <li>{t("Unique device identifiers and device attributes, such as operating system and browser type.")} </li>
                        <li>{t("Usage data, such as web log data, referring and exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages and content viewed and the order of those pages, the amount of time spent on particular pages, the date(s) and time(s) you used our Services, the frequency of your use of our Services, error logs, and other related information.")} </li>
                        <li>{t("Transactional data, such as names and email addresses of parties to a transaction, subject line, history of actions that individuals take in connection with a transaction (e.g., review, sign, enable features) and personal information about those individuals or their devices, such as name, email address, IP address, and authentication methods.")} </li>
                    </ul>
                    <p>
                        <strong>{t("Cookies and Related Technologies.")}</strong> {t("We use cookies, which are text files containing small amounts of information that are downloaded on your device, or related technologies, such as web beacons, local shared objects and tracking pixels, to collect and/or store information. For additional information about cookies and related technologies, including details of how to opt out, please read our Cookies Notice.")}
                    </p>
                    <p>
                        <strong>{t("Information We Collect from Other Sources.")}</strong> {t("Subject to applicable law, we may collect personal information about you from others, such as:")}
                    </p>
                    <ul>
                        <li>
                            <strong>{t("Third-Party Sources.")}</strong> {t("Examples of third-party sources include marketers, partners, researchers, affiliates (like companies under common ownership or control of VidioSign), service providers, and others where they are legally allowed to share your personal information with us. For example, if you register for our Services on another website, the website may provide your personal information to us.")}
                        </li>
                        <li>
                            <strong>{t("Other Customers.")}</strong> {t("Other customers may give us your personal information. For example, if a customer wants you to sign an electronic document in our Services, he or she will give us your email address and name.")}
                        </li>
                        <li>
                            <strong>{t("Combining Personal Information from Different Sources.")}</strong> {t("We may combine the personal information we receive from other sources with personal information we collect from you (or your device) and use it as described in this Notice.")}
                        </li>
                    </ul>
                    <p>
                        <strong>{t("Personal Information We Collect & Process on Behalf of Customers.")}</strong> {t("When our business customers use certain Services, we generally process and store limited personal information on their behalf as a data processor. For example, in the context of VidioSign eSignature, when a customer uploads contracts or other documents for review or signature, we act as a data processor and process the documents on the customer's behalf and in accordance with their instructions. In those instances, the customer is the data controller and is responsible for most aspects of the processing of the personal information. If you have any questions or concerns about how personal information is processed in these cases, including how to exercise your rights as a data subject, you should contact the customer (either your employer or the individual or entity requesting your signature). If we receive any rights requests concerning instances where we act as data processor, we will forward your query on to the relevant customer.")}
                    </p>
             
                <h3 id='section-2'>2. {t("Use of Personal Information")}</h3>
                <p>{t("In general, and subject to applicable law, including consent (as required), we may use your personal information to provide, fix, and improve our Services, develop new Services, and market our companies and their products and Services. Examples of how we use the personal information we process include, but are not limited to, the following:")}</p>
                <ul>
                    <li>{t("Provide you with and collect payment for the products and Services and products you request.")} </li>
                    <li>{t("Create your account and manage your relationship with us (e.g., communicating with you, providing you with requested information).")}</li>
                    <li>{t("Record details about transactions involving electronic documents (e.g., who initiated, viewed, or signed the documents; signers' IP addresses; timestamps).")}</li>
                    <li>{t("Choose and deliver content and tailored advertising, and support the marketing and advertising of our Services.")}</li>
                    <li>{t("Create and review data about our users and how they use our Services.")}</li>
                    <li>{t("Fix problems you may have with our Services, including answering support questions, customer education and training, and resolving disputes.")}</li>
                    <li>{t("Manage the Services platform, including support systems and security.")}</li>
                    <li>{t("Prevent, investigate and respond to fraud, unauthorized access to or use of our Services, breaches of terms and policies, or other wrongful behavior.")}</li>
                    <li>{t("Comply with legal obligations.")}</li>
                    <li>{t("Meet legal retention periods.")}</li>
                    <li>{t("Establish, exercise, or defend our rights in legal claims.")}</li>
                </ul>
                <p>
                    <strong>{t("Other Uses.")}</strong> {t("We may combine the personal information we collect (“aggregate”) or remove pieces of personal information (“de-identify”) to limit or prevent identification of any particular user or device to help with goals like research and marketing. Once such information has been aggregated and anonymized so that it is no longer considered personal information under applicable data protection law, this Notice does not apply.")}
                </p>
                <p>
                    <strong>{t("Lawful Basis for Processing Your Personal Information.")}</strong> {t("If data protection law applies and where VidioSign acts as a data controller, our lawful basis for collecting and using the personal information described in this Notice will depend on the type of personal information concerned and the specific context in which we collect or use it.")}
                </p>
                <p>{t("We normally collect or use personal information only where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may have a legal obligation to collect or retain personal information or may need the personal information to protect your vital interests or those of another person. For example, when we:")} </p>
                <ul>
                    <li>{t("Use personal information to create and manage an account, we do so in order to provide you with relevant Services and perform our contract with you.")}</li>
                    <li>{t("Gather and record data associated with use of a digital certificate or digital signature, we do so to comply with regulations.")}</li>
                    <li>{t("Use names and email addresses for email marketing purposes, we do so with your consent (which you can withdraw at any time) or, where permitted under applicable law, on the basis of our legitimate interests.")}</li>
                    <li>{t("Gather usage data and analyze it to improve our Services or ensure the security of our websites, we do so based on our legitimate interest in safeguarding and improving our Services.")}</li>
                </ul>
                <p>{t("If you have questions about or need further information concerning the lawful basis on which we collect and use your personal information, please contact us using the contact details provided in Section 11 of this Notice. Note that in situations where VidioSign acts as a processor, it is our customer who determines the appropriate legal basis associated with processing activities, and queries about the applicable lawful basis should be directed to them.")}</p>
                <h3 id='section-3'>3. {t("Personal Information Sharing")}</h3>
                <p>{t("Subject to applicable law, including consent (as required), we may share personal information as follows:")} </p>
                <p>{t("We share personal information as follows:")}</p>
                <ul>
                    <li>
                        <strong>{t("Other VidioSign users.")}</strong> {t("When you allow others to access, use, or edit content in your account, we share that content with them. For example, if you send an envelope to others for review or signature, we make the contents of the envelope available to them.")}
                    </li>
                    <li>
                        <strong>{t("Third Parties.")}</strong> {t("When you make a payment to another user within our Services, we share your payment method details with the third-party payment processor selected by you.")}
                    </li>
                    <li>
                        <strong>{t("Public Information.")}</strong>
                        <ul>
                            <li>
                                <strong>{t("User-Generated Content.")}</strong> {t("When you comment on our blogs or in our community forums, this information may also be read, collected, and used by others.")}
                            </li>
                            <li>
                                <strong>{t("Profile Information.")}</strong> {t("When you create a VidioSign profile, other VidioSign users can view your profile information. If you would like to make this information private, please visit your account settings.")}
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>{t("Your Employer or Organization.")}</strong> {t("When you create an account or user role with an email address assigned to you as an employee, contractor or member of an organization, that organization (if it is a VidioSign customer with certain features) can find your account and take certain actions that may affect your account.")}
                    </li>
                </ul>
                <h3 id='section-4'>4. {t("Retention of Personal Information")}</h3>
                <p>{t("We keep your personal information for no longer than necessary for the purposes for which it is processed. The length of time for which we retain personal information depends on the purposes for which we collected and use it and/or as required to comply with applicable laws as set out in our data retention policy and information handling standards. Generally, this means we retain your personal information to comply with any retention or statutory limitations or for purposes of performing a contract with you. Where there are technical limitations that prevent deletion or anonymization, we safeguard personal information and limit active use of it.")}</p>
                <h3 id='section-5'>5. {t("Your Choices")}</h3>
                <p>{t("This section describes many of the actions you can take to change or limit the collection, use, storage, or other processing of your personal information.")}</p>
                <ul>
                    <li>
                        <strong>{t("Profile.")}</strong>{t("You are not required to fill out a profile. If you do, you can access and review this personal information. If any personal information is inaccurate or incomplete, you can make changes in your account settings.")}
                    </li>
                    <li>
                        <strong>{t("Marketing Messages.")}</strong> {t("You can opt out of email marketing messages we send you by clicking on the “unsubscribe” link in the email message or. Please note that we may send you one message to confirm you want to opt out. If you are a registered user of our Services, or if you have engaged in transactions with us, we may continue to send transactional or relationship messages (e.g., signing notifications or account notifications) after you opt out of marketing messages. If you would like your phone number added to our internal Do-Not-Call list to opt out of telemarketing messages, please contact us via the Privacy Request Portal. Please include your first name, last name, company, and phone number. You can also let us know at any time, including during a telemarketing call, that you do not want to be called again for telemarketing purposes.")}
                    </li>
                    <li>
                        <strong>{t("Cookies and Other Related Technology.")}</strong> {t("You can decline cookies through your browser settings or via the VidioSign Cookie Preference Center and other methods detailed in our Cookie Notice. However, if you decline cookies, you may not be able to use some parts of our Services. Please note we do not recognize or respond to browser-initiated Do Not Track signals.")}
                    </li>
                    <li>
                        <strong>{t("Device and Usage Information.")}</strong> {t("If you do not want us to see your device location, you can turn off location sharing on your device, change your device privacy settings, or decline to share location on your browser.")}
                    </li>
                    <li>
                        <strong>{t("Closing Your Account.")}</strong> {t("If you wish to close your account, please log in to your account and edit your plan.")}
                    </li>
                    <li>
                        <strong>{t("Complaints.")}</strong> {t("We are committed to resolving valid complaints about your privacy and our collection, use, storage, or other processing of your personal information. For questions or complaints regarding our data use practices or this Notice, please contact us via the Privacy Request Portal.")}
                    </li>
                </ul>
                <h3 id='section-6'>6. {t("Children's Privacy")}</h3>
                <p>{t("Our Services are not designed for and are not marketed to people under the age of 18 or such other age designated by applicable law (“minors”). We do not knowingly collect or ask for personal information from minors. We do not knowingly allow minors to use our Services. If you are a minor, please do not use our Services or send us your personal information. We delete personal information that we learn is collected from a minor without verified parental consent. Please contact us via the Privacy Request Portal if you believe we might have personal information from or about a minor that should be removed from our system.")}</p>
                <h3 id='section-7'>7. {t("Your Privacy Rights")}</h3>
                <p>{t("You may have certain rights related to your personal information, subject to local data protection laws, as described in more detail below. To exercise any of these rights, please contact us via the Privacy Request Portal.")}</p>
                <ul>
                    <li>{t("You can")} <strong>{t("access")}</strong> {t("and review personal information associated with your account at any time.")} </li>
                    <li>{t("You also can")} <strong>{t("request")}</strong> {t("the following information: how we collect and use your personal information and why; the categories of personal information involved; the categories of recipients of your personal information; how we received your personal information and its source; our business purpose for using your personal information; and how long we use or store your personal information or the manner in which we determine relevant retention periods.")} </li>
                    <li>{t("You have a right to")} <strong>{t("correct")}</strong> {t("inaccurate personal information about you, and you should notify us immediately if you believe the personal information we hold about you is inaccurate, incomplete, or out-of-date.")} </li>
                    <li>{t("In certain situations, you can ask that we")} <strong>{t("erase")}</strong> {t("or stop using your personal information,")} <strong>{t("object")}</strong> {t("to or")} <strong>{t("restrict")}</strong>{t("the use of your personal information, or")} <strong>{t("export")}</strong> {t("your personal information to another controller.")} </li>
                    <li>{t("Where we rely on your consent to process your personal information, you have the right to decline consent and/or, if provided, to")} <strong>{t("withdraw consent")}</strong> {t("at any time. This will not affect the lawfulness of processing prior to the withdrawal of your consent. At any time, you can request that we stop using your personal information for direct marketing purposes. See Section 5 (Your Choices) of this Notice for more information on your choices.")} </li>
                    <li>{t("If you are unsatisfied with our response to your")} <strong>{t("complaint")}</strong>, {t("you have a right to raise questions or complaints with your local data protection authority at any time.")} </li>
                </ul>
                <p>{t("If you make a request to exercise the rights referenced above, we will require you to provide certain information for identity verification purposes. If you have an account with us, we may verify you through your login of your account. If you do not have an account with us, we may require you to provide additional information from which we can confirm your identity. You may authorize an agent to make a request to us on your behalf and we will verify the identity of your agent or authorized legal representative by either seeking confirmation from you or documents that establish the agent's authorization to act on your behalf.")}</p>
                <p>{t("Certain personal information may be exempt from such requests under applicable law. We need certain types of personal information so that we can provide the product and Services to you. If you ask us to delete it, you may no longer be able to access or use our product and Services.")}</p>
                <h3 id='section-8'>8. {t("How We Protect Your Personal Information")}</h3>
                <p>{t("We have implemented appropriate technical, physical and organizational measures to protect your personal information from misuse or accidental, unlawful, or unauthorized destruction, loss, alteration, disclosure, acquisition, or access as well as all other forms of unlawful processing. To achieve this, we have developed and implemented an Information Security Management System and other sub-policies and guidelines relating to the protection of your personal information. For example, our staff is permitted to access customer personal information only to the extent necessary to fulfill the applicable business purpose(s) and to perform their job, subject to confidentiality obligations.")}</p>
                <h3 id='section-9'>9. {t("Changes to This Privacy Notice")}</h3>
                <p>{t("We may amend this Notice to reflect changes in the law, our companies, our Services, our data processing practices, or advances in technology. Our use of the personal information we collect is subject to the Privacy Notice in effect at the time such personal information is used. Depending on the type of change, we may notify you of the change by posting on this page.")}</p>
                <h3 id='section-10'>10. {t("How to Contact Us")}</h3>
                <p>{t("For questions or complaints regarding our use of your personal information or this Notice, please contact us at,")} <a href="#">{t("metas@metabox.land")}</a>. </p>
            </div>
        </div>
    );
}

export default PrivacyPage;

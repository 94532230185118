import React, { useState, useEffect } from "react";
import { useMeasure } from "react-use";
import { Col, Row, Button, Spin, Typography, Modal } from "antd";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import DrawSignPos from "./DrawSignPos";
import docuutil from "../../utils/DocuUtil";
import Swal from "sweetalert2";
import { IMG_DISPLAY_MARGIN, DEFAULT_SIGN_HEIGHT} from "../../utils/size_constants";
import utilfunc from "../../utils/utilfunc";
import helper from "../../utils/Helper";
import { useSelector } from "react-redux";
import ModalSelectSign from "../21_Signing/Sections/ModalSelectSign";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import demo_gif from '../../../assets/demo/demo_sign_click1.gif';


// MakeContract-->ProcSetPos-->DrawSignPos
function ProcSetPos(props) {
    const { Text } = Typography;
    const { t } = useTranslation();
    let user = useSelector((state) => state.user);
    const docuId = props.docuId;
    const [RcptIdx, setRcptIdx] = useState(-1);
    const [PdfLoadDone, setPdfLoadDone] = useState(false);      // img0.clientHeight가 유효하다는 의미
    const [RecipientList, setRecipientList] = useState([]);
    const [PngUrlList, setPngUrlList] = useState([]);
    const [Cursor, setCursor] = useState("pointer");
    const [SelectSignMode, setSelectSignMode] = useState(false);
    const [FileUrl, setFileUrl] = useState("");
    const [UseVideoSign, setUseVSign] = useState(false);
    const [DocuId4, setDocuId4] = useState('');
    const [SignDisplay, setSignDisplay] = useState('none');
    const [Demo, setDemo] = useState(false);

    const [imgref, {height}] = useMeasure();        // height는 사용되지 않고 단지 re-render를 위해서 필요함 (browser 크기 변경할 때)
    //const img0 = document.getElementById("imgwh0");
    //console.log('img0 height=', img0?.clientHeight)

    useEffect(() => {
        async function f() {
            const docu = await helper.get_docu(docuId);
            //console.log('useeffect: docu=', docu);
            showPdf(docu.png_key_list);
            setRecipientList(docu.recipient_list);
            setRcptIdx(0);
            setFileUrl(docu.file_url);
            setUseVSign(docu.use_video_sign);
            setDocuId4(docu.id4.toUpperCase());
        }
        f();
    }, []);

    useEffect(()=>{
        //console.log('user=', user)
        if (user.isLoaded && user.userData.comp_signclick<5) {
            setDemo(true);
        }

    }, [user.isLoaded]);

    useEffect(() => {
        set_cursor(RecipientList, RcptIdx);
    }, [RecipientList, RcptIdx]);

    function onMouseMove(e) {
        if (SignDisplay!=='') {
            setSignDisplay('');
        }
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const cursorRounded = document.querySelector("img#img1");
        const cursorRef = document.querySelector("div#imgref");
        const tmp_cursor_rect = cursorRef.getBoundingClientRect();
        cursorRounded.style.transform = `translate(${mouseX - tmp_cursor_rect.left}px, ${mouseY - tmp_cursor_rect.top}px)`;
    };

    function onMouseEnter(e) {
        setSignDisplay('');
    }

    function onMouseLeave(e) {
        setSignDisplay('none');
    }

    // true이면 custom image를 cursor로 그려줌
    function set_cursor(rcptlist, rcptidx) {
        //debugger;
        const sign_url = (rcptidx >= 0 && rcptlist[rcptidx]) ? rcptlist[rcptidx].sign_url : "";
        setCursor(sign_url);
    }

    // page0 width, height
    function get_img0_display_dimension() {
        const img0 = document.getElementById("imgwh0"); //todo: 현재는 모든 페이지의 높이가 0페이지와 같다고 가정하고 한 것임.  수정필요.
        const timg_display_width = img0? img0.clientWidth:0;
        const timg_display_height = img0? img0.clientHeight:0;
        return [timg_display_width, timg_display_height];
    }

    // index번째 signer의 사인위치를 등록함.
    function addPosInfo(uidx, pos_info) {
        const rcpt = RecipientList[uidx];
        rcpt.pos_info.push(pos_info);

        const newlist = [
            ...RecipientList.slice(0, uidx),
            rcpt,
            ...RecipientList.slice(uidx + 1, RecipientList.length),
        ];
        setRecipientList(newlist);
    }

    function changePosInfo(rcpt_idx, index, pos_info) {
        const rcpt = RecipientList[rcpt_idx];
        const tinfo = rcpt.pos_info[index];
        tinfo.sign_pos_x = pos_info.sign_pos_x;
        tinfo.sign_pos_y = pos_info.sign_pos_y;
        if (pos_info?.sign_height) tinfo.sign_height = pos_info.sign_height;

        const newlist = [
            ...RecipientList.slice(0, rcpt_idx),
            rcpt,
            ...RecipientList.slice(rcpt_idx + 1, RecipientList.length),
        ];
        setRecipientList(newlist);
    }

    function onClickDelete(uidx, subindex) {
        const target = RecipientList[uidx];
        target.pos_info.splice(subindex, 1);

        const newlist = [
            ...RecipientList.slice(0, uidx),
            target,
            ...RecipientList.slice(uidx + 1, RecipientList.length),
        ];
        setRecipientList(newlist);
    }

    async function showPdf(png_key_list) {
        // png가 server에 없다면 s3에서 복사해 와서 준비
        const dataToSubmit = {
            png_key_list: png_key_list,
            password: null,
        };
        const res = await axios.post("/api/docu/prepare_png", dataToSubmit);
        if (!res.data.success) {
            console.log("error: prepare_png:", res.data.errmsg);
            return;
        }

        // download png
        const pnglist = [];
        for (let k = 0; k < png_key_list.length; k++) {
            const path = png_key_list[k];
            pnglist.push(`/api/${path}`);
        }
        setPngUrlList(pnglist);


        //scrollHeight가 반영될 때까지 최대 4초까지 기다림
        const img0 = document.getElementById("imgwh0");
        console.log('showPdf pass3: img0=', img0);
        for (let k = 0; k < 20; k++) {
            if (img0?.clientHeight > 0) {
                //console.log("height=", img0, img0.clientHeight);
                break;
            } else {
                // loading 이전
                //console.log("First page is not loaded yet");
                await utilfunc.sleep(200);
            }
        }

        //console.log("PdfLoadDone  -------------------- true");
        setPdfLoadDone(true);
    }

    // useEffect(()=>{
    //     console.log('--------->', PngUrlList, img0?.clientHeight)
    //     if (img0?.clientHeight > 0) {            
    //         setPdfLoadDone(true);
    //     }        
    // }, [PngUrlList, img0?.clientHeight])

    function onClickPrev() {
        if (RcptIdx == 0) {
            props.history.push(`/home/make/${props.evsign}/addrcpt/${docuId}`);
        } else {
            setRcptIdx(RcptIdx - 1);
        }
    }

    async function onClickNext() {
        // 적어도 한 곳에 서명이 들어가는지 체크
        const rcpt = RecipientList[RcptIdx];
        if (rcpt.pos_info.length <= 0) {
            alert(
                t("No signature location has been specified for this contractor. At least one signature location must be specified.")
            );
            return;
        }

        // rcpt 저장
        await helper.save_recipient(
            docuId,
            RecipientList,
            "DOCU_STATUS.SetPos"
        );

        // last signer가 아닌 경우에는 다음 사용자에 대해 진행
        if (RcptIdx !== RecipientList.length - 1) {
            const new_rcptidx = RcptIdx + 1;
            //set_cursor(RecipientList, new_rcptidx);
            setRcptIdx(new_rcptidx);

            const postStr = ((new_rcptidx + 1) === 1) ? t('st') : ((new_rcptidx + 1) === 2) ? t('nd') : ((new_rcptidx + 1) === 3) ? t('rd') : t('th');
            const str = `${t('Please specify the signature position of the')} ${new_rcptidx + 1}${postStr} ${t('contractor')}`

            Swal.fire(str);

        } else {
            // last signer인 경우
            const tsplit = FileUrl.split("/");
            const folder_name = "uploads/" + tsplit[tsplit.length - 1];
            const res = await axios.post("/api/docu/remove_local_folder", {
                folder_name,
            });
            if (!res.data.success) {
                alert("Fail to delete local folder");
            }

            setRcptIdx(-1);

            if (UseVideoSign) {
                // owner가 계약자에 속해 있다면 여기에서 영상서명을 해야 함.
                for (let u = 0; u < RecipientList.length; u++) {
                    if (RecipientList[u].is_owner) {
                        props.history.push(`/home/make/${props.evsign}/vsign/${docuId}`);
                        return;
                    }
                }
            }

            props.history.push(`/home/make/${props.evsign}/agree/${docuId}`);
        }
    }

    //console.log('PngUrlList', PngUrlList)
    const render_png_mobile =
        PngUrlList &&
        PngUrlList.map((pngurl, index) => {
            return (
                <div key={index}>
                    <img
                        id={`imgwh${index}`}
                        className="pdfMobile"
                        alt={`pdfImg${index}`}
                        src={pngurl}
                        // height={IMG_DISPLAY_HEIGHT}
                        onClick={onClickPutSignOrg}
                        style={{
                            margin: `${IMG_DISPLAY_MARGIN}px 0`,
                        }}
                    ></img>
                </div>
            );
        });

    const render_png_pc =
        PngUrlList &&
        PngUrlList.map((pngurl, index) => {
            return (
                <img key={index}
                    id={`imgwh${index}`}
                    alt={`pdfImg${index}`}
                    src={pngurl}
                    // height={IMG_DISPLAY_HEIGHT}
                    onClick={onClickPutSignOrg}
                    onMouseMove={onMouseMove} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                    style={{
                        margin: `${IMG_DISPLAY_MARGIN}px 0`,
                        border: "1px solid #d3d3d3",
                        //cursor: 'none',
                    }}
                ></img>
            );
        });

    // 사인 위치를 클릭하면 실행되는 함수
    async function onClickPutSignOrg(e) {
        const el = document.getElementById("scroll_window");
        if (!el) {
            console.log("onClickPutSignOrg:get_scroll_window() return null");
            return;
        }
        const boundary = el.getBoundingClientRect();
        const xpos_screen = el.scrollLeft + e.clientX - boundary.left; //scrollLeft:스크롤위치 cleintX:client화면의 절대위치, boundary: client시작점의 절대위치
        const ypos_screen = el.scrollTop + e.clientY - boundary.top;

        const [img_display_width, img_display_height] = get_img0_display_dimension();
        const pdf_posinfo = docuutil.image_pos_to_pdf_posinfo(
            xpos_screen,
            ypos_screen,
            //DEFAULT_SIGN_HEIGHT*img_display_height/1024,
            DEFAULT_SIGN_HEIGHT,
            img_display_width,
            img_display_height
        );

        //const pdf_posinfo = docuutil.adjust_by_boundary(pdf_posinfo_click);

        if (RecipientList[RcptIdx].is_owner) {
            // user와 계약자가 동일인이므로 위치뿐만 아니라 사인을 바로 넣어야 함.
            pdf_posinfo.signed = true;
        } else {
            pdf_posinfo.signed = false;
        }
        addPosInfo(RcptIdx, pdf_posinfo);
    }

    function changePosHeight(rcpt_idx, index, clientX, clientY, sign_height_px, move_mode){
		//console.log('change1=>', rcpt_idx, index, parseInt(clientX), parseInt(clientY), parseInt(height));
    
        const el = document.getElementById("scroll_window");
        if (!el) {
            console.log("onClickPutSignOrg:get_scroll_window() return null");
            return;
        }
        const boundary = el.getBoundingClientRect();
        // const padding_correction = 4;           // padding때문인지 명확치 않음 대충 4로 정함
        // const xpos_screen = el.scrollLeft + clientX - boundary.left - padding_correction; //scrollLeft:스크롤위치 cleintX:client화면의 절대위치, boundary: client시작점의 절대위치
        // const ypos_screen = el.scrollTop + clientY - boundary.top - padding_correction;
        const xpos_screen = el.scrollLeft + clientX - boundary.left; //scrollLeft:스크롤위치 cleintX:client화면의 절대위치, boundary: client시작점의 절대위치
        const ypos_screen = el.scrollTop + clientY - boundary.top;

        const [img_display_width, img_display_height] = get_img0_display_dimension();
        //console.log('sign_height_px=', sign_height_px);
        const pdf_posinfo = docuutil.image_pos_to_pdf_posinfo(
            xpos_screen,
            ypos_screen,
            sign_height_px,
            img_display_width,
            img_display_height,
            move_mode
        );
        //console.log('pdf_posinfo=', pdf_posinfo);

        changePosInfo(rcpt_idx, index, pdf_posinfo);
        //console.log('change2=>', rcpt_idx, index, parseInt(clientX), parseInt(clientY), parseInt(height));
    }

    function onClickChangeSign() {
        setSelectSignMode(true);
    }

    //ModalSelectSign에서 sign을 바꾸면 이 함수가 불림
    function changeSign(sign_url) {
        if (sign_url === null) {
            // cancel인 경우 null로 들어옴
            setSelectSignMode(false);
        } else {
            setSelectSignMode(false);

            for (let u = 0; u < RecipientList.length; u++) {
                if (RecipientList[u].is_owner) {
                    _change_sign_url(u, sign_url);
                    return;
                }
            }
            alert('changeSign:cannot find owner of contract');
        }
    }

    function _change_sign_url(index, sign_url) {
        const rcpt = RecipientList[index];
        rcpt.sign_url = sign_url;
        const newlist = [
            ...RecipientList.slice(0, index),
            rcpt,
            ...RecipientList.slice(index + 1, RecipientList.length),
        ];
        setRecipientList(newlist);
    }

    async function onOkDemo(){
        setDemo(false);

        if (user.userData.comp_signclick<5) {
            const dataToSubmit = {
                userId: user.userData._id,
                update_info: {comp_signclick: user.userData.comp_signclick+10}
            };
    
            const res = await axios.post("/api/users/update_user", dataToSubmit);
        
            if (res.data.success) {
                console.log('hhhhhhhhhhhhhhhhhhhhhhhhh')
            }
    
        }
    
    }

    const mobileView = () => {
        return (
            <div className="mobileView">
                    {user?.userData?._id && (
                        <ModalSelectSign
                            visible={SelectSignMode}
                            className="avb"
                            changeSign={changeSign}
                            UserDbId={{ db: "User", userId: user.userData._id }}
                        />
                    )}
                    <div className="docFileImgMobile">
                        <Spin spinning={PngUrlList.length == 0} size="large">
                            <div id="scroll_window" className="signCustom mobileScrollWindow" style={{position: "relative",height: "420px",}}>
                                {PdfLoadDone && (
                                    <DrawSignPos
                                        RecipientList={RecipientList}
                                        onClickChangeSign={onClickChangeSign}
                                        onClickDelete={onClickDelete}
                                        img_display_height={get_img0_display_dimension()[1]}
                                        changePosHeight={changePosHeight}
                                        isMobile = {isMobile}
                                    />
                                )}
                                {render_png_mobile}
                            </div>
                        </Spin>
                    </div>
            </div>
        );
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const pcView = () => {
        return (
            <div className="desktopView">
                {user?.userData?._id && (
                    <ModalSelectSign
                        visible={SelectSignMode}
                        className="avb"
                        changeSign={changeSign}
                        UserDbId={{ db: "User", userId: user.userData._id }}
                    />
                )}
                {/* <ModalSelectSign visible={SelectSignMode} changeSign={changeSign} UserDbId={{ db: "User", userId: '62c7c190d93d173050693516' }}/> */}
                <div className="docFileImg">
                    <Spin spinning={PngUrlList.length == 0} size="large">
                        <div id="scroll_window" className="signCustom pcScrollWindow" style={{ position: "relative", height: "600px" }}>
                            {render_png_pc}
                            {PdfLoadDone && (
                                <DrawSignPos
                                    RecipientList={RecipientList}
                                    onClickChangeSign={onClickChangeSign}
                                    onClickDelete={onClickDelete}
                                    img_display_height={get_img0_display_dimension()[1]}
                                    changePosHeight={changePosHeight}
                                    isMobile = {isMobile}
                                />
                            )}
                        </div>
                    </Spin>
                </div>
            </div>
        );
    };

    return (
        <>
            <div align="center" className="posTopText">
                <Text className="topText" level={5}>{t("Your Contract Number is")} #{DocuId4}.</Text>
                <Text className="topText">{t("Set the positions requiring signatures throughout the document.")}</Text>
            </div>
            <div className="stepForm setPOSPage" ref={imgref}>
                <div className="stepFormBtn smallHide">
                    <Button onClick={onClickPrev} className="btn preBtn"><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")}</Button>
                </div>
                <div className="stepFormCard">
                    <img id="img1" style={{ zIndex: 10, position: "absolute", pointerEvents: 'none', display:SignDisplay }} src={Cursor} height={DEFAULT_SIGN_HEIGHT} />
                    <div id="imgref" style={{ zIndex: 0, position: "absolute", pointerEvents: 'none' }}></div>
                    { isMobile ? mobileView() : pcView()}
                </div>
                <div className="stepFormBtn smallHide">
                    <Button onClick={onClickNext} className="btn nextBtn">{t("Next")} <ArrowRightOutlined className="arrowIcon" /></Button>
                </div>
            </div>

            <Modal 
                open={Demo} 
                destroyOnClose={true} 
                cancelButtonProps={{ style: { display: 'none' } }}
                okText={t("OK")}
                onOk={onOkDemo}
                className="customPopup"
            >
                <Row className="signConfirmPopup" align="center">
                    <Col align="center" style={{marginBlock:20}}>
                        <img width={400} src={demo_gif}/>
                    </Col>
                    <Col align="center">
                        {t("Click where you want to place your sign. You can also change your signature by clicking the button next to your signature.")}
                    </Col>
                </Row>
            </Modal>

            <div className="stepFormBtn smallShow">
                <Button onClick={onClickPrev} className="btn preBtn"><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")}</Button>
                <Button onClick={onClickNext} className="btn nextBtn">{t("Next")} <ArrowRightOutlined className="arrowIcon" /></Button>
            </div>
        </>
    );
}

export default withRouter(ProcSetPos);

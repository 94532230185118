import React, { useState } from 'react'
import { Input, Typography, Dropdown } from 'antd';
import helper from '../utils/Helper';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";




function RecipientCard(props) {
    const { Title } = Typography;
    const { t } = useTranslation();

    const [DisableDropdown, setDisableDropdown] = useState(false);
    const [AddrMenu, setAddrMenu] = useState();     // address book으로 만들어진 메뉴
    const [MyMenu, setMyMenu] = useState();         // 입력이 시작되면 dropdown을 없애기 위해서 null_menu를 사용함.

    const postStr = ((props.index + 1) === 1) ? t('st') : ((props.index + 1) === 2) ? t('nd') : ((props.index + 1) === 3) ? t('rd') : t('th');

    useEffect(() => {
        let has_name = false;
        if (props?.recipient?.name) {
            has_name = (props.recipient.name.length > 0);
        }
        let has_contact = false;
        if (props?.recipient?.contact) {
            has_contact = (props.recipient.contact.length > 0);
        }
        console.log('disable contact=', has_name, has_contact);
        const disable = has_name || has_contact;
        setDisableDropdown(disable);

        if (disable) {
            setMyMenu(null_menu);
        } else {
            setMyMenu(AddrMenu);
        }
    }, [props?.recipient?.name, props?.recipient?.contact, AddrMenu]);

    useEffect(() => {
        const items = [];
        for (let k = 0; k < props.AddressBook.length; k++) {
            const addr = props.AddressBook[k];
            const label_txt = addr.name + ' / ' + addr.contact;
            const label = (<div onClick={() => onClickMenu(k)}>{label_txt}</div>);
            items.push({ label: label, key: '' + k });
        }

        console.log('items', items);
        setAddrMenu(items);
    }, [props?.AddressBook]);

    // function email_or_phone(tcontact) {
    //     const x = helper.email_or_phone(tcontact);
    //     if (x === 'phone') {
    //         return 'This is phone number. Message will be transfered by Line or SMS.';
    //     }
    //     if (x === 'email') {
    //         return 'This is Email Address. Message will be transfered by Email.';
    //     }
    //     return '';
    // }

    const null_menu = [];

    function onClickMenu(idx) {
        const addr = props.AddressBook[idx];
        props.setValue(props.index, addr.name, 'name');
        props.setValue(props.index, addr.contact, 'contact');
    }

    function onChangeName(e) {
        props.setValue(props.index, e.currentTarget.value, 'name');
    }

    function onChangeContact(e) {
        props.setValue(props.index, e.currentTarget.value, 'contact');
    }

    async function onBlurName() {

    }

    async function onBlurContact() {
        
    }

    return (
        <div className="addRecepientsForm">
            <div key={props.index} id={props.recipient._id} title={`${props.index+1}${postStr} ${t("Contractor")}`}>
                <div onClick={() => props.onClickRemove(props.index)} className="recepientsClose"><CloseOutlined /></div>
                <Title className="title" level={5}>{t("Contractor")} {props.index + 1}</Title>
                <Dropdown menu={{ items: MyMenu }} trigger={['click']} disabled={DisableDropdown}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Input className="formControl" value={props.recipient.name} onChange={onChangeName} placeholder={t("Write Name")} onBlur={onBlurName}/>
                    </a>
                </Dropdown>
                <Dropdown menu={{ items: MyMenu }} trigger={['click']} disabled={DisableDropdown}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Input className="formControl" value={props.recipient.contact} onChange={onChangeContact} placeholder={t("Write Email Address")} onBlur={onBlurContact}/> <br />
                    </a>
                </Dropdown>

                {/* {email_or_phone(props.recipient.contact)} */}
            </div>
        </div>
    )
}

export default RecipientCard

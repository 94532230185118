import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Spin } from "antd";
import axios from "axios";
import utilfunc from "../../../utils/utilfunc";
import docuutil from "../../../utils/DocuUtil";
import { useTranslation } from "react-i18next";

// Docu-->ModalInfoSign-->DocuInfo
function DocuInfo(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation('Home');
    const [Owner, setOwner] = useState([]);
    const [DocuUploadTime, setDocuUploadTime] = useState(0);

    
    
    useEffect(()=>{
        async function f() {
            const res = await axios.post('/api/users/get_user_fields', {userId:props.docu.userId, fields:'name email phone'});
            if (res.data.success) {
                setOwner(res.data.result);
            }

            const upload_time = utilfunc.get_time_from_history(props.docu.history, 'upload');
            //const [year, month, date, hour, minutes] = utilfunc.get_yyyymmdd(CurrTime);
            const d = new Date(upload_time);
            setDocuUploadTime(docuutil.my_locale_string(d, i18n.language));
        }
        if (props.docu.userId) f();
    }, [props?.docu?.userId]);

    const rcptlist = props.docu.recipient_list;
    const rcpt_render = rcptlist && rcptlist.map((rcpt, index)=>(
        // todo: vsign, vcontract의 경우도 고려해야 함.
        <div key={index}>
            {index+1}. {rcpt.name}({rcpt.contact})  <br/>
            {t("Status:")} {rcpt.rcpt_agree===1? t('Signed All'): rcpt.rcpt_agree===2? t('Sign Denied') : t('Sign Needed')}
        </div>
    ));

    function explain_status(status) {
        switch (status) {
            case 'DOCU_STATUS.Ready': return '';
            case 'DOCU_STATUS.Upload': return <font color='blue'>{t("File Uploaded")}</font>;
            case 'DOCU_STATUS.AddRcpt': return <font color='blue'>{t("Recipient Setup Completed")}</font>;
            case 'DOCU_STATUS.SetPos': return <font color='blue'>{t("Sign Area Setup Completed")}</font>;
            case 'DOCU_STATUS.Agree': return <font color='deepskyblue'>{t("Waiting Sign")}</font>;
            case 'DOCU_STATUS.NeedVConfirm': return <font color='deepskyblue'>{t("Waiting Video Confirm")}</font>;
            case 'DOCU_STATUS.Generating': return <div> <font color='orange'>{t("Generating Final Document")}</font> <Spin /></div>;
            case 'DOCU_STATUS.Completed': return <font color='orange'>{t("Final Document Created")}</font>;
            case 'DOCU_STATUS.Reject': return <font color='black'>{t("Denied")}</font>;
            case 'DOCU_STATUS.Reject_legal': return <font color='black'>{t("E-Sign Denied")}</font>;
            default: return '';
        }
    }

    function display_mobile(mobile){
        if (!mobile) {
            return t('Not Available');
        } else {
            return mobile;
        }
    }

    return (
        <div>
            <font size={5}>{t("Document Info")}</font>
            <table style={{border:'none', borderCollapse:'collapse'}}>
            <tbody>
                <tr>
                    <td>{t("Title")}</td>
                    <td>{t(props.docu.title)}</td>
                </tr>
                <tr>
                    <td>{t("Document ID")}</td>
                    <td>{props.docu.id4} ({props.docu._id})</td>
                </tr>
                <tr>
                    <td>{t("Status")}</td>
                    <td>{explain_status(props.docu.status)}</td>
                </tr>
                <tr>
                    <td>{t("Uploaded Time")}</td>
                    <td>{DocuUploadTime}</td>
                </tr>
            </tbody>
            </table>

            <font size={5}>{t("Signature Requester Info")}</font>
            <table style={{border:'none', borderCollapse:'collapse'}}>
            <tbody>
                <tr>
                    <td>{t("Name")}</td>
                    <td>{Owner.name}</td>
                </tr>
                <tr>
                    <td>{t("Email")}</td>
                    <td>{Owner.email}</td>
                </tr>
                <tr>
                    <td>{t("Mobile")}</td>
                    <td>{display_mobile(Owner.phone)}</td>
                </tr>
            </tbody>
            </table>


            <font size={5}>{t("Contractor Info")}</font>            
            {rcpt_render}
        </div>
    );
}

export default withRouter(DocuInfo);

import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, Typography, Tabs } from "antd";
import DocuInfo from "./Sections/DocuInfo";
import DocuHistory from "./Sections/DocuHistory";
import { useTranslation } from "react-i18next";

// main page of document
// 현재 등록된 계약들을 보여주고 그 계약을 클릭하면 계속 진행하는 화면으로 전환
function ModalInfoSign(props) {
    const { t } = useTranslation();

    // const [CurTab, setCurTab] = useState('1');
    const items = [
        { label: t("Document Info"), key: "1", children: <DocuInfo docu={props.docu} /> },
        { label: t("Progress Status"), key: "2", children: <DocuHistory docu={props.docu} /> }
    ];

    return (
        <div style={{ width: "80%", margin: "1rem auto" }}>
            <Modal
                title={t("Contract Info.")}
                open={props.docu}
                centered={true}
                width={800}
                destroyOnClose={true}
                onCancel={props.onCancel}
                className="contract-prog-info customPopup"
                // cancelButtonProps={{ style: { display: "none" } }}
                // okButtonProps={{ style: { display: "none" } }}
                footer={null}
            >
                <Tabs defaultActiveKey="1" items={items} />
            </Modal>

        </div>
    );
}

export default withRouter(ModalInfoSign);


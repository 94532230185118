import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import ProcOutInitModal from "./ProcOutInitModal";
import ProcOutReview from "./ProcOutReview";
import VSign from "../20_MakeContract/VSign";
import Swal from "sweetalert2";
import docuutil from "../../utils/DocuUtil";
import { useSelector } from "react-redux";
import { USER_SERVER } from "../../../config";
import { Steps } from "antd";
import helper from "../../utils/Helper";
import { useTranslation } from "react-i18next";
import CircleIcon01 from "../../../assets/images/VideoSign/circle01.svg"
import CircleIcon02 from "../../../assets/images/VideoSign/circle02.svg";

// /outsign/docuId/emailenc 로 접근하면 router를 통해서 이 페이지로 들어옴
// emailenc는 이 링크를 통해서 서명해야 할 사람의 contact identifier임
// url parsing을 통해서 얻어진 func는 초기 state를 결정하고 이후에는 reloading을 피하기 위해서 setstate를 사용.
function MainOutStartSign(props) {
	const { t } = useTranslation();
	const {i18n} = useTranslation();
	let user = useSelector((state) => state.user);
	const docuId = props.match.params.docuId;
	const emailenc = props.match.params.emailenc;

	const [UserDbId, setUserDbId] = useState(null); //{db: User or OuterUser, userId: id}
	const [Docu, setDocu] = useState(null);
	const [MyIndex, setMyIndex] = useState(-1);
	const [Current, setCurrent] = useState(0);
	const [State, setState] = useState("init"); //현재 진행중인 작업이 무었인지 표시.  st_review, st_signing, ...
	const [Rcpt, setRcpt] = useState([]);

	useEffect(() => {
		async function f() {
			const [dbid, docu, user_contact] = await get_docu();
	
			if (docu && user_contact){
				console.log('docuouter:get_docu------->', docu);
				const myidx = docuutil.get_my_index(docu, user_contact);
				setUserDbId(dbid);
				setDocu(docu);
				setMyIndex(myidx);
			}
		}
		if (user.isLoaded) f();
	}, [user.isLoaded]);

	useEffect(() => {
		switch (State) {
			case 'init': setCurrent(0); break;
			case 'review': setCurrent(1); break;
			case 'vsign': setCurrent(1); break;
			default: setCurrent(2); break;
		}
	}, [State]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	// 문서에 관한 정보를 읽어들임
	// result.data.rcpt_contact은 emailenc를 decoding한 결과임
	async function get_docu() {
		let dbid = null;
		let docu = null;
		let user_contact = null;

		const dataToSubmit = {
			docuId: docuId,
			emailenc: emailenc,
		};
		const res_docu = await axios.post("/api/docuouter/get_docu", dataToSubmit);

		if (res_docu.data.success) {
			console.log('docu=', res_docu);
			const already_completion = check_already_completion(res_docu.data.docu, res_docu.data.rcpt_contact);
			if (already_completion) return [null, null, null];

			if (user.userData.isAuth) {
				if (res_docu.data.rcpt_contact === user.userData.email) {
					// 서명할 사람이 login한 상태라면
					//alert('The signer is logged in already.');
					dbid = { db: "User", userId: user.userData._id };
					docu = res_docu.data.docu;
					user_contact = { email: res_docu.data.rcpt_contact, phone: null };
				} else {
					// 서명할 사람의 id가 아닌 다른 사람으로 login 된 상태-->logout시키고 진행
					const res_swal1 = await Swal.fire({
											title: t("Caution!"),
											text: t("You are logged in with an ID other than the one to sign. Logout to continue."),
											icon: "question",
											showCancelButton: true,
											cancelButtonText: t("Cancel"),
											confirmButtonText: t("Logout and Proceed"),
										});
					if (res_swal1.isConfirmed) {
						const res_logout = await axios.get(`${USER_SERVER}/logout`);
						if (res_logout.status === 200) {
							alert(t("logout success"));
							window.location.reload();
						} else {
							throw new Error("Error: Logout failed!");
						}
					} else {
						//props.history.push('/');
						props.history.goBack();
					}
				}
			} else {
				// 아무도 login하지 않은 상태
				//alert('not loged');
				const rcpt_name = _get_rcpt_name(res_docu.data.docu, res_docu.data.rcpt_contact);
				const res_user = await axios.post("/api/docuouter/search_user_by_contact", { db: 'OuterUser', contact: res_docu.data.rcpt_contact, name: rcpt_name }								);
				if (res_user.data.success) {
					// todo
					dbid = { db: 'OuterUser', userId: res_user.data.userId };
					docu = res_docu.data.docu;
					user_contact = { email: res_docu.data.rcpt_contact, phone: null };
					console.log("UserDbId set !!!", { db: 'OuterUser', userId: res_user.data.userId });
				} else {
					throw new Error("cannot find user or cannot make outer user");
				}
			}
		} else {
			alert("get_docu: fail to get document information");
		}

		return [dbid, docu, user_contact];
	}

	function check_already_completion(docu, rcpt_contact) {
		const rcptlist = docu.recipient_list;

		// get myid
		let my_rcpt = null;
		for (let r=0; r<rcptlist.length; r++){
			const rcpt = rcptlist[r];
			if (rcpt.contact === rcpt_contact) {
				my_rcpt = rcpt;
				break;
			}
		}

		if (!my_rcpt) throw new Error('cannot find contact from recipient_list in docu');

		if (my_rcpt.rcpt_agree===1) {
			alert('You have already agreed to this contract.');
			props.history.push('/');
			return true;
		} else if (my_rcpt.rcpt_agree===2) {
			alert('You have already denied this contract.');
			props.history.push('/');
			return true;
		} else {
			return false;
		}
	}

	// docu의 서명자 중에서 contact가 일치하는 서명자의 이름을 리턴
	function _get_rcpt_name(docu, contact) {
		const rcptlist = docu.recipient_list;
		for (let index = 0; index < rcptlist.length; index++) {
			if (rcptlist[index].contact === contact) {
				return rcptlist[index].name;
			}
		}
		return null;
	}


	async function status_update(status) {
		const res = await axios.post('/api/docuouter/update_docu', {docuId, update_info: {'status': status}});
		if (!res.data.success) {
			alert('fail:update_docu:'+ res.data.errmsg);
		}
		return res.data.success;
	}

	// vsign: final_agree-->vsign-->final_agree_sub
	// normal: final_agree-->final_agree_sub
	async function final_agree(rcpt) {
		// sign할 곳에 모두 sing해야만 여기에 올 수 있음
		const dataToSubmit = {rcpt: rcpt};
		console.log('data to update_rcpt=', dataToSubmit);
		const res = await axios.post("/api/docuouter/update_rcpt", dataToSubmit);
		console.log("res=", res);

		if (Docu.use_video_sign) {
			setRcpt(rcpt);
			setState('vsign');
		} else {
			final_agree_sub(rcpt);
		}
	}

	async function final_agree_sub(rcpt) {
		// sign할 곳에 모두 sing해야만 여기에 올 수 있음
		// pos_info 저장

		const myhtml = `<div>${t("I agree to the legal effect of electronic signatures and electronic documents.")} <br/>
			${t("The electronic document sent after signing is recognized as the original.")}  <br/>
			${t("Confirmed that all signers have legitimate authority for electronic signatures.")} <br/>
			${t("For other details, I agree to the Electronic Signature Terms and Conditions")} <br/>`;

		const res_swal = await Swal.fire({
			title: t("Agreement to e-sign"),
			html: myhtml,
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: t("Confirm"),
			denyButtonText: t("Deny"),
			cancelButtonText: t("Cancel"),
			customClass: {
			actions: "my-actions",
			cancelButton: "order-1 right-gap",
			confirmButton: "order-2",
			denyButton: "order-3",
			},
		});

		console.log("res_swal", res_swal);

		// 전자서명 동의를 누른 경우
		if (res_swal.isConfirmed) {
			helper.update_rcpt_agree(Docu._id, Docu?.recipient_list, MyIndex, 1, i18n.language);

			const myhtml1 = `<div>
			<font size={8}>${t("Your sign is completed.")}</font>
			<br/><br/><br/>
			${t("After all signers are signed, the completed document and the blockchain document certificate recording the signature progress history will be emailed to you.")}
			<br/>
			</div>
			`;
			await Swal.fire({
				icon: 'success',
				title: t("Go to Home"),
				html: myhtml1,
			});

			// 모든 서명자가 서명했는지 확인하고 후속 진행 --> 매 사인마다 서버에서 검사해서 minting하는 방식으로 바뀜
			//await axios.post("/api/docu/check_all_sign_done", { docuId: docuId });
			props.history.push("/");
		} else if (res_swal.isDenied) {		// 거절한 경우
			const rcpt = Docu.recipient_list[MyIndex];
			const name = rcpt.name;
			const contact = rcpt.contact;
			const action = 'reject_legal';
			const ipaddr = 'unknown';
			const machine = 'unknown';
			const dataToSubmit = {
				docuId: Docu._id,
				rcptId: rcpt._id,
				push_history: { name, contact, action, ipaddr, machine },
				lang: i18n.language
			}
			//helper.update_rcpt_agree(Docu._id, Docu?.recipient_list, MyIndex, 2);
			const res = await axios.post('/api/docuouter/reject_legal', dataToSubmit);
			if (res.data.success) {
				Swal.fire({
					icon: "success",
					title: "Legal Agreement Denied.",
					text: `You refused to agree electronic sign system for contract ${Docu.title}.`,
					confirmButtonText: t('Go to Home')
				});
				const myhtml1 = `<font size={8}>${t('Sign is denied.')}</font><br/>`;
				await Swal.fire({
					title: t("Go to Home"),
					html: myhtml1,
				});
				props.history.push("/");
			} else {
				alert('Error:reject_legal=', res.data.errmsg);
			}
	
			// const status_update_result = await status_update('DOCU_STATUS.Reject_legal');
			// if (!status_update_result) {
			// 	return;
			// }

			// const res = await axios.post('/api/docuouter/update_docu', {docuId, update_info: {'status': 'DOCU_STATUS.Reject_legal'}});
			// if (!res.data.success) {
			// 	alert('fail:update_docu:'+ res.data.errmsg);
			// 	return;
			// }

		} else if (res_swal.isDismissed) {
		}
	}

	function onClickPrev_vsign(){
		setState('review');
	}

	function onClickNext_vsign(){
		final_agree_sub(Rcpt);
	}

	/////////////////////// upload related ///////////////////
	// const processPcView = () => {
	// 	return (
	// 		<div>
	// 		<Sider width={250} style={{ minHeight: "60vh", background: "#eeee" }}>
	// 			<div style={{ margin: "16px" }}>
	// 			<font size={4} color="black">
	// 				STAR Net
	// 			</font>
	// 			<br />
	// 			<font size={4} color="black">
	// 				NFT SIGN
	// 			</font>
	// 			<br />
	// 			<br />
	// 			<br />
	// 			<ProgressStepSign State={State} />
	// 			<br />
	// 			<br />
	// 			</div>
	// 		</Sider>
	// 		</div>
	// 	);
	// }

	const processMobileView = () => {
		const items = [
			{title:t("Setup"),},
			{title:t("Signing"),},
			{title:t("Complete"),}];
		return (
			<div className="p-10 stepProcessBar">
				<Steps current={Current} direction="horizontal" responsive={false} size="small" items={items} />
			</div>
		);
	}

	return (
		<div className="dashboardContentWrap secondSigner">
			<div className="funnelContent">
			<div className="funnelCircle">
				<img src={CircleIcon01} alt="circle" className="circleImg circle01" />
				<img src={CircleIcon02} alt="circle" className="circleImg circle02" />
			</div>
			{processMobileView()}
			
			
				{Docu===null? "" : 
				State === "init" ? <ProcOutInitModal setState={setState} /> : //서명 안내 모달
				State === "review" ? <ProcOutReview Docu={Docu}
													UserDbId={UserDbId}
													MyIndex={MyIndex}
													final_agree={final_agree}
									/> : // 여기에서 서명
				State === 'vsign' ? <VSign 
										rcpt 	 	= {Rcpt}
										title	 	= {Docu && Docu.title}
										outerFlag	= {UserDbId.db!=='User'}
										onClickPrev = {onClickPrev_vsign}
										onClickNext = {onClickNext_vsign}
										full_s3_key = {`${Docu.base_key}/vsign-${MyIndex}`}
										docuId		= {docuId}
										id4 		= {Docu.id4}
										MyIndex		= {MyIndex}
									/>
	 : "Signing"
				}
			
		</div>
		</div>
	);
}

export default withRouter(MainOutStartSign);

import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import notiIcon from "../../../assets/images/VideoSign/notification.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SubNavBar({ language }) {
    const { Text } = Typography;
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);

    return (
        <>
            <div className="adminRightMenu">
                <div className="adminUser">
                    {user?.userData?.image && (
                        <img
                            src={user.userData.image}
                            alt="user-img"
                            className="user-logo"
                        />
                    )}
                    {user?.userData?.name && (
                        <Text className="userName">
                            {user?.userData?.name}
                            <Link className="myProfileLink" to="/home/mypage">
                                {t("My Profile")}
                            </Link>
                        </Text>
                    )}
                </div>
                <div className="notiIcon">
                    <img
                        src={notiIcon}
                        alt="Logo"
                        className="notification-icon"
                    />
                </div>
            </div>
        </>
    );
}

export default SubNavBar;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row, Button, Tabs, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";



function MyPage(props) {
    const { Title } = Typography;
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);

    const [User, setUser] = useState({});

    useEffect(() => {
        getUserById();
    }, [user?.userData?.isAuth]);

    async function getUserById() {
        const res = await axios.post("/api/users/getUserById", { userId: user.userData?._id });
        if (res.data.success) {
            const tresult = res.data.result;
            console.log('user=', tresult);
            setUser(tresult);
        } else {
            Swal.fire(t('Fail to load user information'));
        }
    }

    function Myinfo() {
        return (
            <>
                <div className="myInfoWrap tabContent">
                    <Title className="tabTitle" level={2}>{t("My Info")}</Title>
                    <Row gutter={12}>
                        <Col xs={24}>
                            <div className="formGroup">
                                <Input className="formControl" value={User.name} placeholder={t("User")} />
                            </div>
                        </Col>
                        <Col xs={24}>
                            {User.email?
                                <div className="formGroup formBtnWrap">            
                                    <Input className="formControl" value={User.email} placeholder={t("Email Address")}/>
                                    <Button className="formBtn">{t("Verified")}</Button> 
                                </div> :
                                <div className="formGroup formBtnWrap">
                                    <Input className="formControl" placeholder={t("Email Address")}/>
                                    <Button className="formBtn">{t("Verify")}</Button> 
                                </div>
                            }
                        </Col>
                        {/* <Col lg={24}>
                            {User.phone ?
                                <div className="formGroup formBtnWrap">
                                    <Input className="formControl" value={User.phone} placeholder={t("Phone Number")} />
                                    <Button className="formBtn">{t("Verified")}</Button>
                                </div> :
                                <div className="formGroup formBtnWrap">
                                    <Input className="formControl" placeholder={t("Phone Number")} />
                                    <Button className="formBtn">{t("Verify")}</Button>
                                </div>
                            }
                        </Col>
                        <Col lg={24}>
                            <div className="formGroup formBtnWrap">
                                <Input className="formControl" value={User.phone} placeholder={t("Blockchain wallet")} />
                                <Button className="formBtn">{t("Connect")}</Button>
                            </div> 
                        </Col> */}
                        <Col xs={24} align="right">
                            <Button className="primaryBtn">{t("Save")}</Button>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    function PricingInfo() {
        return (
            <>
                <div className="myInfoWrap tabContent">
                    <Title className="tabTitle" level={2}>{t("Pricing Information")}</Title>
                </div>
            </>
        )
    }

    const items = [
        { label: t("My Info"), key: "1", children: <Myinfo /> },
        { label: t("Pricing Information"), key: "2", children: <PricingInfo /> }
    ]

    return (
        <div className="dashboardContentWrap">
            <Tabs className="customTab" defaultActiveKey="1" items={items} centered />
        </div>
    );
}

export default withRouter(MyPage);

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import helper from "../../utils/Helper";
import { useSelector } from "react-redux";
import VSign from "./VSign";

// 별도의 주소를 가지는 page
function ProcVSign(props) {
	const docuId = props.docuId;
	const [Docu, setDocu] = useState([]);
	const [Rcpt, setRcpt] = useState([]);
	const [MyIndex, setMyIndex] = useState(-1);

	useEffect(() => {		
		async function f() {
			const docu = await helper.get_docu(docuId);
			const rcptlist = docu.recipient_list;
			let rcpt = null;
			for (let u=0; u<rcptlist.length; u++) {
				if (rcptlist[u].is_owner) {
					rcpt = rcptlist[u];
					setMyIndex(u);
					break;
				}
			}
			if (!rcpt) {
				alert('Error: cannot find owner...');
				return;
			}
	
			setRcpt(rcpt);
			setDocu(docu);		
		}
		f();
	}, []);

	function onClickPrev() {
		props.history.push(`/home/make/${props.evsign}/setpos/${docuId}`);
	}

	async function onClickNext() {
		props.history.push(`/home/make/${props.evsign}/agree/${docuId}`);
	}

	return (
	<>
		<VSign 
			rcpt 	 	= {Rcpt}
			title	 	= {Docu && Docu.title}
			outerFlag	= {false}
			onClickPrev = {onClickPrev}
			onClickNext = {onClickNext}
			full_s3_key	= {`${Docu.base_key}/vsign-${MyIndex}`}
			docuId		= {docuId}
			id4 		= {Docu.id4}
			MyIndex		= {MyIndex}
		/>
	</>
	);
}

export default withRouter(ProcVSign);

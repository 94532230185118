import React from "react";
import { Button, Row, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Text, Title } = Typography;

function ThankyouPage(props) {

    const { t } = useTranslation();

    function onClickStart() {
        props.history.push("/");
    }

    return (
        <div className="container">
            <div className="content thankyouPage">
                <Row justify="center">
                    <Title level={2}>{t('Thank you for signing up with us.')}</Title>{" "}
                </Row>
                <Row justify="center">
                    <Text className="text" size={4}>{t('Easily use electronic contracts in VidioSign.')}</Text>{" "}
                </Row>
                <Row justify="center">
                    {" "}
                    <Button className="primaryBtn" type="primary" onClick={onClickStart}>
                        {" "}
                        {t("Start Explore")}{" "}
                    </Button>{" "}
                </Row>
            </div>
        </div>
    );
}

export default withRouter(ThankyouPage);

import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

// import LandingLogin from "./LandingLogin";

import LandingNotLogin from "./LandingNotLogin";

// import helper from "../../utils/Helper";
// import NavBar from "../00_NavBar/NavBar";
// import Footer from "../14_Footer/Footer";

import { useEffect } from "react";

// Home
function LandingPage(props) {

    const user = useSelector((state) => state.user);
    const pathName = window.location.pathname;

	useEffect(()=>{
		if (user?.userData?.isAuth) {
			props.history.push('/home');
		}
	}, [user?.userData && user.userData?.isAuth])

    return (
        <div>
			<LandingNotLogin />
        </div>
    );
}

export default withRouter(LandingPage);

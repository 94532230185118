import React from 'react'

function RefundPage() {

    return (
        <div style={{ width: '95%', margin: '3rem auto' }}>
            <p  >
                <strong>Returns and Refunds Policy</strong>
            </p>
            <p  >
                Depending on the unique characteristics of the work of art, if the works you have purchased or sold are not genuine, different from the advertised information, damaged, etc., we will work with you to find the best solution such as cancellation/exchange/return/refund. In some cases, partial refund, restoration, or exchange would be the way. Items 1 to 5 apply to customers who have purchased Fine Art or Anime works. Customers who have purchased Digital Art, please follow the item number 6. In addition, you should fulfill the following details to be protected by METABOX’s Returns and Refunds Policy.
            </p>
            <ul>
                <li>
                    This policy will be applied only to the customers who proceed the safe payment way done through METABOX such as payment(purchase)/inquiry etc.
                </li>
                <li>
                    If there is a difference between the posted work information and real work, please contact us within 7 days after receipt.
                </li>
                <li>
                    If the delivery date is delayed, please contact us within 14 days of the latest estimated delivery date.
                </li>
                <li>
                    Please contact us within 180 days of delivery for works in doubt about their authenticity.
                </li>
                <li>
                    Please provide photos, etc. of the issue you wish to inquire about. If the packaging is damaged, please contact us in an unopened state.
                </li>
                <li>
                    You may be required to provide identification card/passport/signature etc. in order to handle the inquires.
                </li>
            </ul>
            <p  >
                &nbsp;
            </p>
            <ol>
                <li>
                    <strong>Cancellation</strong>
                </li>
            </ol>
            <p  >
                1-1. Members who have purchased Fine Art and Anime can cancel the purchase until the purchased work is sent, and if payment is completed, all works will be processed according to the return procedure, not cancellation.
            </p>
            <p  >
                1-2. If the seller expresses his/her intention not to approve the application of the service described in the sales process after receiving the buyer’s payment notice, the contract will be automatically canceled and the payment will be refunded to the buyer.
            </p>
            <p  >
                1-3. If you choose to receive the Fine art or Anime work directly, after the member completes the payment and before the shipping preparation stage, the cancellation process will be conducted after review by METABOX when a valid reason is found. In the case of Digital Art, please refer to clause 6.
            </p>
            <p  >
                1-4. When you choose to receive Fine Art and Anime directly, if the product has already been prepared for delivery or has been shipped when you apply for cancellation while it is ready for delivery, in principle, the buyer will bear the round-trip shipping cost of the shipped product. It is processed according to the return procedure, not cancellation.
            </p>
            <p  >
                1-5. Cancellation fees incurred when canceling or refunding works purchased by members are as follows. However, cancellation or refund processing due to product defects or damage caused by intention or gross negligence of the company does not incur a cancellation fee.
            </p>
            <p  >
                After payment and before shipping: Cancellation fee 30%
            </p>
            <p  >
                Within 7 days from the date of receipt after delivery: Return procedure
            </p>
            <p  >
                1-6. Refunds due to cancellation will be processed as soon as possible after the cancellation procedure is completed and after confirmation by METABOX. The account must be in your name, and if it is not specified or the name is different, the refund will not be processed, and we will announce the details through your SMS or e-mail address.
            </p>
            <p  >
                &nbsp;
            </p>
            <ol>
                <li>
                    <strong>Return</strong>
                </li>
            </ol>
            <p  >
                2-1. Members who choose to receive Fine art and Anime works directly can request a return from the time the work is sent to within 7 days after the date of receipt in accordance with the relevant laws and regulations.
            </p>
            <p  >
                2-2. General matters regarding return take precedence over the conditions suggested by the seller in related laws, such as the Consumer Protection Act in Electronic Commerce, etc.
            </p>
            <p  >
                2-3. The cost of the return is generally attributable to the customers responsible for the return.
            </p>
            <p  >
                2-4. If you do not provide a return invoice number when requesting a return, or do not accurately notify the seller (orally or in writing) about the reason for return, return processing and refund may be delayed.
            </p>
            <p  >
                2-5. Refund due to return will be progressed as soon as possible when the returned product arrives at the seller and both the reason for the return and the person responsible for the return shipping cost are confirmed by METABOX.
            </p>
            <p  >
                2-6. If the buyer is responsible for the return shipping cost, the refund may be delayed unless additional payment for the return shipping cost is made.
            </p>
            <p  >
                &nbsp;
            </p>
            <ol>
                <li>
                    <strong>Exchange</strong>
                </li>
            </ol>
            <p  >
                3-1. You can apply for an exchange from the time the seller sends the product to within 7 days after the delivery completion date in accordance with the relevant laws and regulations.
            </p>
            <p  >
                3-2. Even if you apply for an exchange, if there is only one original or if the seller does not have stock of the item to be exchanged, exchange is not possible. In this case, the request for exchange will be processed according to the return procedure.
            </p>
            <p  >
                3-3. The cost of exchange is borne by the seller in the case of a defect in the product, but the buyer will pay the cost of exchange in the case of a change of mind.
            </p>
            <p  >
                &nbsp;
            </p>
            <ol>
                <li>
                    <strong>Refund</strong>
                </li>
            </ol>
            <p  >
                4-1. The company will issue a refund if the reason for the refund occurs due to the buyer’s cancellation or return. The holder of the NFT must be the same as the member’s name, and if it is not the same, the refund process will be stopped. Refund may incur a service fee.
            </p>
            <p  >
                4-2. The refund procedure will be processed as soon as possible after the buyer completes the refund request due to the cancellation or return and METABOX confirms that the reason for the refund is applicable.
            </p>
            <p  >
                &nbsp;
            </p>
            <ol>
                <li>
                    <strong>Exclusion of return/exchange/refund</strong>
                </li>
            </ol>
            <p  >
                The buyer cannot request a refund or exchange in any of the following cases.
            </p>
            <p  >
                1) If the product is lost or damaged due to reasons attributable to the buyer
            </p>
            <p  >
                2) If the value of the product has significantly decreased due to the buyer’s use or partial consumption
            </p>
            <p  >
                3) If the value of the product has significantly decreased to such an extent that resale is difficult due to the passage of time
            </p>
            <p  >
                4) If the packaging of the product that can be reproduced is damaged
            </p>
            <p  >
                5) In the case where serious irreparable damage to the seller is expected, such as items produced individually according to the order, and in the case that the fact is separately notified of the transaction in advance and the buyer’s written consent (including electronic documents) is obtained
            </p>
            <p  >
                6) If the color of the artwork looks different depending on the lighting or other environment
            </p>
            <p  >
                7) Change of mind due to things that are incidental to the work but may not be clearly visible on the screen (visible brushstrokes, etc.)
            </p>
            <p  >
                8) Minor size differences
            </p>
            <p  >
                9) If the delivery is delayed due to the circumstances of the delivery company
            </p>
            <p  >
                10) If there are any unpaid duties and other charges for which the buyer is responsible
            </p>
            <p  >
                11) Any issues related to payment if you paid directly to the seller (bank transfer, direct deposit, check, PayPal, or other platforms)
            </p>
            <p  >
                12) Problems with works not listed on the order confirmation and on the METABOX website
            </p>
            <p  >
                13) Buyer’s simple change of mind
            </p>
            <p  >
                In each of the following cases, the responsibility for refund or exchange lies with the owner (seller) of the work, not the trading platform METABOX.
            </p>
            <p  >
                1) If the work is lost or damaged due to reasons attributable to the seller
            </p>
            <p  >
                2) If the value of the work has significantly decreased due to the seller’s use or partial consumption
            </p>
            <p  >
                3) If the image of the work is different from the actual condition to the extent that it is difficult to sell
            </p>
            <p  >
                4) If the packaging of the work that can be reproduced is damaged
            </p>
            <p  >
                5) When a work arrives without the required items (artist’s signature, etc.)
            </p>
            <p  >
                6) When detailed information about the work is written differently (when the size of the work differs significantly, etc.)
            </p>
            <p  >
                7) If artwork arrives with damage not described or photographed via METABOX
            </p>
            <p  >
                METABOX is not responsible for refund or exchange in any of the following cases.
            </p>
            <p  >
                1) If the delivery company reports that the artwork is lost or damaged during delivery
            </p>
            <p  >
                &nbsp;
            </p>
            <ol>
                <li>
                    <strong>Returns and Refunds Policy related to Digital Art purchase</strong>
                </li>
            </ol>
            <p  >
                Due to the nature of digital art works, it is difficult to cancel/exchange/return/refund works for which ownership has been transferred after payment is made. Customers who want to purchase Digital Art, please consider this point and purchase carefully.
            </p>
            <p  >
                &nbsp;
            </p>
            <ol>
                <li>
                    <strong>Post-processing related to authenticity</strong>
                </li>
            </ol>
            <p  >
                In rare cases, METABOX may report the following issues within 180 days of the transaction of the Artwork. If the following problems are found to be true, METABOX may hold the seller responsible for any damages, etc., and METABOX does not take any responsibility.
            </p>
            <p  >
                1) If it turns out to be unlicensed reproductions and false/fraudulent works not made by that artist but marked as the work of a particular artist
            </p>
            <p  >
                2) A work whose date of work is marked as the artist’s lifetime, but is actually produced after death on the artist’s property
            </p>
            <p  >
                3) When false facts that significantly affect the value of the work are found
            </p>                  
        </div>
    )
}


export default RefundPage

import React from "react";
import { withRouter } from "react-router-dom";
import SubSignStamp from "./SubSignStamp";
import { useTranslation } from "react-i18next";

// Home for loged in user
function HomeSignStamp(props) {
	const { t } = useTranslation();
	// const [SignUrlList, setSignUrlList] = useState([]);
	// const [SelectedIndex, setSelectedIndex] = useState([]);

	// async function onClickOK() {
	// 	await axios.post('/api/gensign/change_default', {default_sign_index:SelectedIndex, UserDbId:props.UserDbId});
	// 	Swal.fire('Default Sign Changed !!!');
	// }

	//////////////////////////////////////////////////////////////////////////
	return (
		<div className="dashboardContentWrap">
			<div className="signPage">
				<div className="darboartTitleWrap">
					<h2 className="dashBoardPageTitle" align="center">{t("Make New Sign / Stamp")}</h2>
				</div>
				<SubSignStamp UserDbId={props.UserDbId} changeSign={()=>{;}}/>
			</div>
		</div>
	);
}

export default withRouter(HomeSignStamp);

import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Typography } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

import LineLogin from "./LineLogin";
import { getCookie } from "../../../cookie/cookie";
import { loginGoogleUser } from "../../../_actions/user_actions";
import { GOOGLE_OAUTH_CLIENT_ID } from "../../../config";
import utilfunc from "../../utils/utilfunc";

// import { GoogleLogin } from "react-google-login";

const { Text } = Typography;

function LoginPage(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(true);

    useEffect(()=>{
        async function f() {
            /* global google */
            for (let k=0; k<20; k++){
                const google = window.google;
                if (google){
                    break;
                }
                await utilfunc.sleep(400);
            }
            if (!google){
                alert('cannot load google ID login script!!!');
                return;
            }

            google.accounts.id.initialize({
                client_id: GOOGLE_OAUTH_CLIENT_ID,
                callback: handleCallbackResponse
            });

            google.accounts.id.renderButton(document.getElementById("signInDiv"), { theme: "outline", size: "large", border: "none" });
        }
        f();
    }, []);

    function handleCallbackResponse(res) {
        if (res?.credential) {
            var id_token = res.credential;
            const body = { token: id_token };
            dispatch(loginGoogleUser(body)).then((response) => {
                respond_to_result(response);
            });
        } else {
            alert('Fail to login to google: res.credential unknown');
        }
    }

    function respond_to_result(response) {
        const resdata = response.payload;

        if (resdata.loginSuccess) {

            const token = getCookie('macAddress');
            const platform = getCookie('site-platform');

            if (token !== 'default-token') {
                const dataToSubmit = { _id: resdata.userId, macAddress: token, platform };
                axios.post("/api/users/updateUserById", dataToSubmit).then((response) => {
                    if (response.data.success) {
                        console.log('MacAddress added successfully!');
                    }
                });
            }

            // 처음 login인 경우에는 lastlogintime이 2000년임
            if (resdata.last_login_time.substr(0, 4) === "2000") {

                props.history.push({
                    pathname: "/thankyou",
                    search: "?username=" + resdata.username + "&userinfo=" + resdata.userinfo,
                });

            } else {

                let message = t("Logged in successfully.");
                Swal.fire(t("Success"), message, "success");
                props.history.push("/");
            }

        } else {
            let msg;
            if (resdata.errmsg === 'user not found') {
                msg = 'We cannot find user.  If you didn\'t SignUp, please Signup First.';
            } else if (resdata.errmsg) {
                msg = resdata.errmsg;
            } else {
                msg = 'Something went wrong!!!'
            }
            Swal.fire({ icon: "error", title: t("Oops..."), text: msg });
        }
    }

    function handleOk() {
        setIsModalVisible(false);
    }

    function handleCancel() {
        setIsModalVisible(false);
        props.history.push("/");
    }

    return (
        <Fragment>
            <Modal className="sign-in-up" title={t("Login")} open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                maskClosable={true} // 모달밖 영역 눌렀을때 닫는 기능 비활성화
                cancelButtonProps={{ style: { display: "none" } }} okButtonProps={{ style: { display: "none" } }}>
                <Text className="popupText">{t("Log in to VidioSign to continue")}</Text>
                <div style={{ textAlign: "center" }}>
                    <div className="google-sign" id="signInDiv"></div>
                    <div>
                        <LineLogin state='xyzABC123' register_mode={false}/>
                    </div>
                    <Text className="popupText popupLink"> {t("Don’t have an account:")} <Link to='/register'>{t("Signup")}</Link></Text>
                </div>

            </Modal>
        </Fragment>
    );
}

export default withRouter(LoginPage);

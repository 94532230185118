import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { withRouter } from "react-router-dom";
import DocuSigned from "./Sections/DocuSigned";
import DocuActivity from "./Sections/DocuActivity";
import { useTranslation } from "react-i18next";

// main page of document
// 현재 등록된 계약들을 보여주고 그 계약을 클릭하면 계속 진행하는 화면으로 전환
function Docu(props) {
    const { t } = useTranslation();
    const start_tab = props.match.params.start_tab;

    const items = [
        {label:t("Signed"), key:"signed", children:<DocuSigned docutype='signed'/>},
        {label:t("Pending"), key:"pending", children:<DocuSigned docutype='pending'/>},
        {label:t("Activity"), key:"activity", children:<DocuActivity/>}
    ];

    function onTabClick(key) {
        props.history.push(`/home/doc/${key}`);
    }

    return (
        <div className="dashboardContentWrap">
            <Tabs className="customTab" defaultActiveKey={start_tab} items={items} centered onTabClick={onTabClick}/>
        </div>
    );
}

export default withRouter(Docu);

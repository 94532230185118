import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import helper from "./utils/Helper";
import Routes from "../router/Routes";
import LocalizedRouter from "../router/LocalizedRouter";
import HelmetMetaData from "./views/10_LandingPage/Sections/HelmetMetaData";

// import { fetchLanguage, AppLanguage, fetchLanguageForUrl } from "./utils/Helper";

function App() {

    const pathName = window.location.pathname;

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <LocalizedRouter RouterComponent={BrowserRouter} defaultLanguage={helper.fetchLanguage(pathName)} AppLanguage={helper.AppLanguage}>
                    <HelmetMetaData />
                    <Routes />
                </LocalizedRouter>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;

// set port of backend
const PORT              = 4001;     // for my private
//const PORT              = 5000;     // for dev site
//const PORT              = 5003;     // for manga site
//const PORT              = 5001;     // for live site

// base url
// const baseUrl = 'http://localhost:3000';                // my private local
const baseUrl = 'https://vidio.starnetnft.com';          // live

// set aws s3 bucket
const AWS_BUCKET     = 'test2-metabox';                 // my private
//const AWS_BUCKET     = 'dev.metabox.page';         // for live site

const AWS_BUCKET_IPFS   ='ipfs.metabox';
const GOOGLE_BUCKET_IPFS ='metabox_bucket';

// id for google oauth
// const GOOGLE_OAUTH_CLIENT_ID = '887960247818-s7g1hi2bqc9tg2vuvpjga63paqse4fir.apps.googleusercontent.com';   // my private
// const GOOGLE_OAUTH_CLIENT_ID = '345804872721-kk61l2g9nhdetfckolm3qqpm7pj4uod1.apps.googleusercontent.com';   // for dev site
// const GOOGLE_OAUTH_CLIENT_ID   = '345804872721-v3rblrtl00sde8527afif58r0objhjqh.apps.googleusercontent.com';   // for manga site
const GOOGLE_OAUTH_CLIENT_ID = '212389913795-j0koleatr72rj6ff0jhqfv0hv78aejp5.apps.googleusercontent.com';   // for live site

// info for line oauth
const LINE_OAUTH_CLIENT_ID = '1657264731';

// blockchain related
const MINTING_BLOCKCHAIN    = 'starnet';        // polygon, starnet (we need both blockchain setting, since contents minted with polygon or starnet)
const POLYGON_HTTP_PROVIDER = "https://polygon-mainnet.infura.io/v3/e240a960f15c4994ae6b23121382212b";  // polygon mainnet (bisket version)
const POLYGON_CA            = '0xc0980ab019D7B185C36997a651CDcD528077D534';             // polygon mainnet (bisket version)
const POLYGON_SCAN          = "https://polygonscan.com"                                 // polygon mainnet (bisket version)
const STARNET_HTTP_PROVIDER = "https://rpc1.starnetnft.com";                            // star mainnet
const STARNET_CA_VER1       = '0xc43Ee8027247d46765289F52839CeAC6Bb433fE0';             // star mainnet                             // star mainnet
const STARNET_SCAN          = "https://www.starnetscan.com/"                            // star mainnet
// const STARNET_HTTP_PROVIDER = "https://rpc-testnet.starnetnft.com";                  // star testnet
// const STARNET_CA_VER1       = '0xC59005472EE16d3C55d615E1D68aC8CD991d240E';          // star testnet
// const STARNET_SCAN          = "https://testnet.starnetscan.com"                      // star testnet

//------------------------------- DO NOT EDIT --------------------------------
const apiUrl        = 'https://s3.ap-northeast-1.amazonaws.com/' + AWS_BUCKET;
const apiUrl_IPFS   = 'https://s3.ap-northeast-1.amazonaws.com/' + AWS_BUCKET_IPFS;
const USER_SERVER   = '/api/users';

const STRIPE_PUBLIC_KEY = 'pk_test_51MWbtvKHlOGCyFheGnEByCTMUaTpIryvs4Zi93zvtv5sbJJ7Cfp0X3ZfTSE2VVVLklkz7wOq0pjFccWMS5HCQsdi00smoSfFn3'

// const STRIPE_PUBLIC_KEY = 'pk_test_yAjVtStRIiCIXrq2heSA4Oys'

module.exports = {
    PORT,
    baseUrl,
    AWS_BUCKET,
    AWS_BUCKET_IPFS,
    apiUrl,
    apiUrl_IPFS,
    GOOGLE_OAUTH_CLIENT_ID,
    LINE_OAUTH_CLIENT_ID,
    MINTING_BLOCKCHAIN,
    POLYGON_HTTP_PROVIDER,
    POLYGON_CA,
    POLYGON_SCAN,
    STARNET_HTTP_PROVIDER,
    STARNET_CA_VER1,
    STARNET_SCAN,
    GOOGLE_BUCKET_IPFS,
    USER_SERVER,
    STRIPE_PUBLIC_KEY
}

import axios from "axios";

const AppLanguage = { English : 'en', Japanese : 'jp' };

const fetchLanguage = (pathname) => {
    const languageArr = pathname?.toString().split(/\//g);
    const language = languageArr[1];
    const findLang = Object.keys(AppLanguage).find(key => AppLanguage[key] === language);
    //return (findLang) ? findLang : 'English';
    return findLang;
};

const fetchLanguageForUrl = (pathname) => {

    const languageArr = pathname?.toString().split(/\//g);
    const language = languageArr[1];

    const findLang  = Object.keys(AppLanguage).find(key => AppLanguage[key] === language);
    return (findLang) ? language : 'en';
};

const numberWithCommas = (x) => {
        
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Upload File By a API
 */
const uploadFile = async (file) => {

    const config = {
        headers: { "Content-type": "multipart/form-data" },
    };

    let formData = new FormData();
    formData.append("file", file);

    return await axios.post("/api/contents/uploadFile", formData, config).then((res) => {
        return { status: true, data: res.data };
    }).catch((err) => {
        return { status: false, message: err.message };
    });
};

// input string이 email 인지 phone인지 알려줌.
function email_or_phone(tcontact) {
    if (!tcontact) {
        return '';
    }
    const checkReg = /^[-0-9]*$/;
    if (checkReg.test(tcontact)) {
        return 'phone';
    }
    else if (tcontact.indexOf('@') > 0) {
        return 'email'
    }
    else {
        return '';
    }
}

// 서버에서 user의 default sign을 불러옴
async function get_default_sign() {
    const res = await axios.get('/api/users/default_sign');
    if (res.data.success) {
        return res.data.result;
    }
    return null;
}

// rcpt_agree field를 바꾸어줌
async function update_rcpt_agree(docuId, rcpt_list, rcpt_idx, rcpt_agree, lang) {
    const name = rcpt_list[rcpt_idx].name;
    const contact = rcpt_list[rcpt_idx].contact;
    const action =
        rcpt_agree === 1 ? "agree" : rcpt_agree === 2 ? "reject" : "unknown";
    const ipaddr = "unknown";
    const machine = "unknown";

    const dataToSubmit = {
        docuId: docuId,
        myindex: rcpt_idx,
        field: "rcpt_agree",
        value: rcpt_agree,
        push_history: { name, contact, action, ipaddr, machine },
        lang: lang
    };

    const res = await axios.post("/api/docuouter/update_rcpt_field", dataToSubmit);
    if (!res.data.success) {
        alert("error");
        return;
    }
}

/////////////////////// choose sign /////////////////////////////////
async function chooseSign(UserDbId) {
    let result;
    if (UserDbId) {
        result = await chooseSign_nonlogin_user(UserDbId);
    } else {
        result = await chooseSign_login_user();
    }
    return result;
}

// 서명자가 login한 경우
async function chooseSign_login_user() {
    const res = await axios.post('/api/users/get_user_fields', { fields: 'signlist default_sign_index' });
    if (res.data.success) {
        const result = res.data.result;
        const idx = result.default_sign_index ? result.default_sign_index : 0;
        const url = result.signlist[idx];
        return url;
    }
    console.log('ERROR:get_user_contact');
    return null;
}

// 로그인하지 않은 사용자가 sign할 때 불림
// 두가지 경우에 이 함수가 불림
// 1. 계정이 있는데 로그인 하지 않은 사용자
// 2. 계정이 없는 사용자
async function chooseSign_nonlogin_user(UserDbId) {
    const res = await axios.post('/api/gensign/get_user_sign', { UserDbId: UserDbId });
    if (res.data.success) {
        const result = res.data.result;
        const idx = result.default_sign_index ? result.default_sign_index : 0;
        const url = result.signlist[idx];
        return url;
    }
    console.log('ERROR:get_user_contact');
    return null;

    // const rcpt = props.RecipientList[props.MyIndex];
    // const res = await axios.post('/api/gensign/make_default_sign', {sign:rcpt.name});
    // if (res.data.success) {
    // 	const url = res.data.result;
    // 	const height = default_sign_height;
    // 	console.log(url);
    // 	return [url, height];
    // }
    // console.log('ERROR:get_user_contact');
    // return [null, 0];
}

async function get_docu(docuId) {
    const res = await axios.post("/api/docu/get_docu", { docuId: docuId });
    if (res.data.success) {
        return res.data.result;
    } else {
        return null;
    }
}

async function get_rcptlist(docuId) {
    const res = await axios.post("/api/docu/get_rcptlist", { docuId: docuId });
    if (res.data.success) {
        return res.data.result;
    } else {
        return null;
    }
}

async function save_recipient(docuId, recipient_list, new_status) {
    const dataToSubmit = {
        docuId: docuId,
        recipient_list,
        new_status,
    };
    const res = await axios.post("/api/docu/save_recipient", dataToSubmit);

    if (res.data.success) {
        // setDocu(res.data.result);
        return res.data.upload_percent;
    } else {
        alert('Error:save_recipient:'+res.data.errmsg)
        return -1;
    }
}

function get_extension(fname) {
    const fileExt = fname.split('.').pop();
    return fileExt;
}

export default {
    AppLanguage,
    fetchLanguage,
    fetchLanguageForUrl,
    numberWithCommas,
    uploadFile,
    email_or_phone,
    get_default_sign,
    update_rcpt_agree,
    chooseSign,
    get_docu,
    get_rcptlist,
    save_recipient,
    get_extension
};
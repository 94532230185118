import { LOGIN_USER, REGISTER_USER, AUTH_USER, LOGOUT_USER } from '../_actions/types';

const initialState = {
    isLoaded: false,
}

// initial state�� action�� �������� next state�� ��� �Ǵ����� ����
export default function my_reducer(state = initialState, action) {
    switch (action.type) {

        case REGISTER_USER:
            return { 
                ...state, 
                register: action.payload,
                isLoaded: true 
            }
        case LOGIN_USER:
            return { 
                ...state, 
                loginSuccess: action.payload,
                isLoaded: true
            }
        case AUTH_USER:
            return { 
                ...state, 
                userData: action.payload,
                isLoaded: true
            }
        case LOGOUT_USER:
            return { 
                ...state, 
                isLoaded: true 
            }
        // case USER_NOTIFICATION_COUNT:
        //     return { 
        //         ...state, 
        //         count: action.payload, 
        //         isLoaded: true 
        //     }            
        default:
            return state;
    }
};


import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal, Checkbox, Input, Spin, Typography } from "antd";
import { ArrowLeftOutlined, CheckCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import helper from "../../../utils/Helper";
import { useTranslation } from "react-i18next";
import SuccessTick from "../../../../assets/images/VideoSign/success-tick.svg";


function ProcVCAgree(props) {
	const docuId = props.docuId;
	const { t } = useTranslation();
	const {i18n} = useTranslation("Home");
	const [Docu, setDocu] = useState(null);
	const [Agreement, setAgreement] = useState(false);
	const [Title, setTitle] = useState('');
	const [TitleChanged, setTitleChanged] = useState(false);
	const [ShowMessage, setShowMessage] = useState(false);
	const [LoadingDocu, setLoadingDocu] = useState(true);
	const [Loading, setLoading] = useState(false);
	const [ThumbImg, setThumbImg] = useState('https://nftdocu.s3.ap-northeast-1.amazonaws.com/system/border.png');
	const [DoneButtonName, setDoneButtonName] = useState('Send Request');

	useEffect(() => {
		async function f() {
			const docu = await helper.get_docu(docuId);
			console.log('ProcAgree:docu=', docu);
			if (docu.privateinfo_agree) {
				setAgreement(true);
			}
			if (docu.title) {
				setTitle(docu.title);
			}
	
			if (docu.recipient_list.length===1){
				setDoneButtonName(t('Completed'));
			} else {
				setDoneButtonName(t('Send Request'));
			}
	
			setDocu(docu);
			if (docu.vc_thumbnail) {
				setThumbImg(docu.vc_thumbnail);
			}
			setLoadingDocu(false);
		}
		f();
    }, []);

	async function onChangeAgree() {
		const new_agreement = !Agreement;
		await setAgreement(new_agreement);
		const dataToSubmit = {
			docuId: docuId,
			update_info: {'privateinfo_agree': new_agreement}
		};
		const res = await axios.post("/api/docu/update_docu", dataToSubmit);

		console.log('res=', res.data);
	
	}

	function onClickPrev() {
		props.history.push(`/home/make/vcontract/vcaddrcpt/${docuId}`);
	}

	async function onClickNext() {
		setLoading(true);		//기다리는 spin 표시

		// title이 바뀌었다면 저장
		if (TitleChanged){
			const dataToSubmit = {
				docuId: docuId,
				update_info: {title: Title}
			};
			const res1 = await axios.post("/api/docu/update_docu", dataToSubmit);
			if (!res1.data.success) {
				setLoading(false);
				alert("fail to update field1");
				return;
			}
		}

		// 
		const name = '';
		const contact = '';
		const action = 'agree';
		const ipaddr = 'unknown';
		const machine = 'unknown';
		const lang = i18n.language;
		const dataToSubmit = {docuId, push_history:{ name, contact, action, ipaddr, machine }, lang}

		const res = await axios.post("/api/docu/finalize_for_vc", dataToSubmit);
		if (res.data.success) {
		} else {
			setLoading(false);
			alert("Error:", res.data.errmsg);
			return;
		}
		console.log('link_for_curr_user=', res.data.link_for_curr_user);

		// show message
		setShowMessage(true);
		setLoading(false);
	}

	async function onOk() {
		// go to home
		setShowMessage(false);
		props.history.push('/home');
	}

	function onChangeTitle(e){
		setTitle(e.currentTarget.value);
		setTitleChanged(true);
	}

	// input field가 focus를 잃을 때 발생
	async function onBlur(){
		if (TitleChanged){
			const dataToSubmit = {
				docuId: docuId,
				update_info: {title: Title}
			};
			const res1 = await axios.post("/api/docu/update_docu", dataToSubmit);
			if (!res1.data.success) {
				alert("fail to update field1");
				return;
			} else {
				console.log('saved');
			}
			setTitleChanged(false);
		}
	}


	const render = Docu?.recipient_list && Docu.recipient_list.map((rcpt, index) => {
		return (
			<div key={index} className="recipientDetailWrap">
				<div className="recipientDetail" title={`${t("Recipient:")} ${index+1}`}>
					<h5 className="title">{t("Contractor")} {index+1} <CheckCircleOutlined /></h5>
					<div className="recipientPerson">{rcpt.name}</div>
					<div className="recipientPerson">{rcpt.contact}</div>		
				</div>
			</div>
		)
	})

	return (
		<>
		<div className="stepForm">
			<div className="stepFormCard">
				<h5 className="formCardTitle">{t("Finalize")}</h5>

				<div className="formGroup">	
					<label>{t("Contract Name")}</label> 
					<Input className="formControl" onChange={onChangeTitle} onBlur={onBlur} value={t(Title)} placeholder={t("Contract Name")}/>
				</div>
				<Row gutter={20} className="agreeFileMobile">
					<Col sm={10}>
						<div className="fileDetial">
						<div className="fileThumbnail">
							<Spin spinning={LoadingDocu}>
								<img src={ThumbImg} alt="thumb-img"/>
							</Spin>
						</div>
						<div className="docName">{Docu?.org_fname}</div>
                    	<div className="docName">{t("Contract ID:")} {Docu?.id4?.toUpperCase()}</div>
						</div>
					</Col>
					<Col sm={14}>
						{render}
					</Col>
				</Row>

				<div className="agreeTxt">
					<Checkbox onChange={onChangeAgree} checked={Agreement}>{t("I agree to VidioSign's")} <Link to={"/terms"}>{t("terms and conditions")}</Link> </Checkbox>
				</div>

				<div align="center" className="spinBtn">
					<Spin spinning={Loading} delay={500}><button onClick={onClickNext} disabled={!Agreement} type='button' className="primaryBtn">{DoneButtonName}</button></Spin>
				</div>
			</div>
		</div>


		<div className="stepFormBtn">
			<Button type="primary" onClick={onClickPrev} className="btn preBtn"><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")}</Button>
		</div>

		<Modal
			open={ShowMessage}
			centered ={true}
			cancelButtonProps={{ style: { display: "none" } }}
			okText={t("OK")}
			width={400}
			destroyOnClose={false}
			onOk={onOk}
			className="requestPopup"
		>
			<div className="requestPopupBody">
				<img src={SuccessTick} alt="success-icon" />
				<h3>{t("Request Successfully Sent")}</h3>
				<h5>{t("Your signature request has been completed.")}</h5>
			</div>
    	</Modal>

		</>
	);

}

export default withRouter(ProcVCAgree);

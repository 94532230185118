import { Typography, Row, Col } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

function AboutPage() {
    const { Title } = Typography;
    const { t } = useTranslation();

    return (
        <div className="faq__wrapper content">
            <div className="container">
                <Title align="center" className="mainTitle"><strong>{t('About Us')}</strong></Title>
                <Row justify="center">
                    <Col lg={18} xs={24}>
                    <p>{t("VidioSign is a new type of electronic contract. Throughout their lives, the VidioSign team has realized that writing contracts are too inefficient. It will become a matter of course in the future for paper contracts to be replaced by electronic contracts. However, electronic contracts in the market are still complex. VidioSign has made contract signing just one click away. Another problem with contract writing is false signatures. We ask that you attach a number of additional documents because we do not know if the other party really signed us honestly. VidioSign supports a very secure contract by simply taking a picture of yourself with your mobile phone when creating a signature, storing it as an NFT, and issuing a certificate.")}</p>
                    <p>{t("In the future, the VidioSign team will continue to think deeply, develop, and support the easiest and most legally safe way for corporations and individuals to sign contracts. Thank you to all customers who use VidioSign.")}</p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default AboutPage;

import "./GenerateSign.css";
import React, { useState } from "react";
import { Row, Button, Col} from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Generate signature
function GenerateStamp(props) {
  const { t } = useTranslation();
  const [sign, setSign] = useState('');
  const [name, setName] = useState('');
  const [warn, setWarn] = useState('');
  const [signImage, setSignImage] = useState([]);
  const [chosenSign, setChosenSign] = useState({ index: -1, img: ''});
  const lang = 'jp';

  async function changeSign(e) {
    setSignImage([]);
    setWarn('');
    setSign(e.target.value);
  }

  async function changeName(e) {
    setSignImage([]);
    setWarn('');
    setName(e.target.value);
  }

  const chooseSign = (v, i) => {
    setChosenSign({index: i, img: v});
  }


  async function createStamp() {
    setWarn('');
    if (!sign.trim()) {
      setWarn('sign is mandatory');
      return;
    }
    if (sign.trim().length > 3 || name.trim().length > 3) {
      setWarn('each length should be less than 4');
      return;
    }
    if (sign.trim().length === 0) {
      setWarn('sign is mandatory')
      return;
    }
    await axios.post('/api/gensign/create/stamp', { sign: sign, name: name, lang: lang }).then((response) => {
      setSignImage(response.data);
    });
  }

  async function createCorporate() {
    setWarn('');
    if (!sign.trim()) {
      setWarn('sign is mandatory');
      return;
    }
    if (!lang) {
      setWarn('select language');
      return;
    }
    if (sign.length > 11) {
      setWarn('length should be less than 12');
      return;
    }
    await axios.post('/api/gensign/create/corporate', { sign: sign, lang: lang }).then((response) => {
      if (response.data.success) {
        setSignImage(response.data.result);
      } else {
        alert('Error: '+response.data.errmsg);
      }
    });
  }

  async function saveSign() {
    await axios.post('/api/gensign/save/sign', { img: chosenSign.img, UserDbId:props.UserDbId }).then((response) => {
      //setSignImage(response.data);
      setSignImage(null);
    });
  }

  //////////////////////////////////////////////
  async function onClickSelect() {
      await saveSign();
      await props.onUpdate();
  }

  const render_sign = signImage && signImage.map((v, i) => {
    const classname = chosenSign.index === i ? 'chosen-sign' : 'sign-area';
    return (
        <img className={classname} src={v} width={120} key={`sign-${i}`} onClick={()=>{chooseSign(v, i);}} alt='sign'/>
    )}
  ); 

  return (
    <div style={{marginTop:20}}>
      {props.stampType==='generic'? 
          <Row gutter={20}>
              <Col md={12} xs={24} className="formGroup">
                <label>{t("Family Name")}</label>   
                <input className="formControl" type='text' onChange={changeSign} value={sign}/>
              </Col>
              <Col md={12} xs={24} className="formGroup">
                <label>{t("Given Name")}</label>
                <input className="formControl" type='text' onChange={changeName} value={name}/>
              </Col>
              <Col md={24} xs={24}>
              <button className="primaryBtn smallBtn" onClick={createStamp} style={{cursor:'pointer'}}>{t("Generate")}</button>
              </Col>
          </Row> :
          <div>
            <div className="formGroup">
              <input className="formControl" type='text' onChange={changeSign} value={sign}/>
            </div>
            <button className="primaryBtn smallBtn" onClick={createCorporate} style={{cursor:'pointer'}}>{t("Generate")}</button>
          </div>
      }

 

      <p style={{color: 'red',}}>{warn}</p>

      {/* { signImage ? signImage.map((v, i) => {return <img className={chosenSign.index === i ? 'chosen-sign' : 'sign-area'} src={v} width={300} key={`sign-${i}`} onClick={()=>{chooseSign(v, i);}}/>}) : <></> } */}
      {/* { signImage && chosenSign.img ? <button onClick={() => {saveSign();}}>save sign</button> : <></> } */}
      {render_sign}

      <Row align='end'>
          <Button className="primaryBtn outlineSmallBtn" onClick={onClickSelect}>{t("Select")}</Button>
      </Row>
    </div>
  
  );
}

export default withRouter(GenerateStamp);

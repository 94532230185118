import axios from 'axios';
import { LOGIN_USER, REGISTER_USER, AUTH_USER, LOGOUT_USER } from './types';
import {USER_SERVER} from '../config'

export function registerUser(dataToSubmit) {
    
    const request = axios.post(`${USER_SERVER}/register`, dataToSubmit).then(response => response.data);

    return {
        type: REGISTER_USER,
        payload: request
    }
}

export function loginUser(dataToSubmit) {

    const request = axios.post(`${USER_SERVER}/login`, dataToSubmit).then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function loginGoogleUser(dataToSubmit) {

    const request = axios.post(`${USER_SERVER}/logingoogle`, dataToSubmit).then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function loginLineUser(dataToSubmit) {
    console.log('in dispatch:', dataToSubmit);

    const request = axios.post(`${USER_SERVER}/loginline`, dataToSubmit).then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function loginMetamask(dataToSubmit) {
    const request = axios.post(`${USER_SERVER}/loginmetamask`, dataToSubmit).then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function auth() {

    const request = axios.get(`${USER_SERVER}/auth`).then(response => response.data);

    return {
        type: AUTH_USER,
        payload: request
    }
}

export function logoutUser() {

    const request = axios.get(`${USER_SERVER}/logout`).then(response => response.data);

    return {
        type: LOGOUT_USER,
        payload: request
    }
}

import React, { useState, useEffect } from "react";
import { Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
import RecipientCard from "../../../utils/RecipientCard";
import helper from '../../../utils/Helper';
import { useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";



// MakeContract --> ProcAddRcpt
// sign할 사람을 추가하거나 편집
function ProcVCAddRcpt(props) {
	const { Text, Title } = Typography;
	const { t } = useTranslation();
	const user = useSelector((state) => state.user);
	const docuId = props.docuId;
	const [RecipientList, setRecipientList] = useState([]);
	const [AddressBook, setAddressBook] = useState([]);
	const [EnableNextButton, setEnableNextButton] = useState(true);

	useEffect(() => {
		async function f() {
			const rcptlist = await helper.get_rcptlist(docuId);
			setRecipientList(rcptlist);
			await get_address_book();
		}
		f();
	}, []);

	async function get_address_book() {
		const res = await axios.get("/api/docu/get_address_book");

		if (res.data.success) {
			setAddressBook(res.data.result);
		}
	}

	function check_validity_simple(rcptlist) {
		let next_enb = false;
		for (let r = 0; r < rcptlist.length; r++) {
			const rcpt = rcptlist[r];
			if (rcpt.contact && rcpt.name && rcpt.contact.trim().length > 0 && rcpt.name.trim().length > 0) {
				next_enb = true;
				break;
			}
		}
		return next_enb;
	}

	function setValue(index, newval, fieldname) {
		const target = RecipientList[index];
		if (fieldname === 'name')
			target.name = newval;
		else if (fieldname === 'contact') {
			target.contact = newval;
		}

		// field를 완전히 지우는 경우는 next button을 disable해야할 수도 있음
		if (EnableNextButton && newval.trim().length === 0) {
			const next_enb = check_validity_simple(RecipientList);
			if (!next_enb) setEnableNextButton(false);
		}

		// 처음 뭔가를 쓰는 경우는 next button을 enable해야 할 수도 있음
		else if (!EnableNextButton) {
			const next_enb = check_validity_simple(RecipientList);
			if (next_enb) setEnableNextButton(true);
		}

		const newlist = [...RecipientList.slice(0, index), target, ...RecipientList.slice(index + 1, RecipientList.length)];
		setRecipientList(newlist);
	}

	function onClickAddItem() {
		const newindex = RecipientList.length;
		const target = { name: null, contact: null };
		const newlist = [...RecipientList, target];
		setRecipientList(newlist);
	}

	function onClickRemove(index) {
		const newlist = [...RecipientList.slice(0, index), ...RecipientList.slice(index + 1, RecipientList.length)];
		setRecipientList(newlist);

		// next button disable할지 결정
		if (newlist.length < 1) {
			setEnableNextButton(false);
		} else if (EnableNextButton) {
			const next_enb = check_validity_simple(newlist);
			if (!next_enb) setEnableNextButton(false);
		}
	}

	function onClickPrev() {
		props.history.push(`/home/make/vcontract/vcrecord/${docuId}`);
	}

	// 각각의 Signer에 대해서 필요한 field가 입력되었는지 체크함.  
	// 문제있으면 message를 리턴.  문제없으면 return null
	function validity_check() {
		function has_char(str) {
			return (str && str.trim().length > 0);
		}

		// rcpt list를 조사해서 완전히 비어 있는것은 자동 삭제함.
		//const rcptlist = [...RecipientList];    // copy
		const rcptlist = RecipientList;
		while (true) {
			let findflag = false;
			for (let k = 0; k < rcptlist.length; k++) {
				const rcpt = rcptlist[k];
				if (!has_char(rcpt.contact) && !has_char(rcpt.name)) {
					rcptlist.splice(k, 1);
					findflag = true;
					break;
				}
			}
			if (!findflag)
				break;
		}

		if (rcptlist.length === 0) {
			return { rcptlist: null, msg: t('At least one contractor must be designated.') };
		}

		// 개별 rcpt를 조사
		for (let k = 0; k < rcptlist.length; k++) {
			const rcpt = rcptlist[k];
			if (!rcpt.contact) {
				return { rcptlist: null, msg: t("There are places where the contractor's contact information is not specified. Enter contact information or click the x in the top right to delete unnecessary contractors") };
			}
			else {
				rcpt.contact = rcpt.contact.trim();
				if (rcpt.contact.length === 0) {
					return { rcptlist: null, msg: t("There are places where the contractor's contact information is not specified. Enter contact information or click the x in the top right to delete unnecessary contractors") };
				}
			}

			if (!rcpt.name) {
				return { rcptlist: null, msg: t("There are places where the contractor's name information is not specified. Enter a name or click the x in the top right to delete unnecessary contractors") };
			}
			else {
				rcpt.name = rcpt.name.trim();
				if (rcpt.name.length === 0) {
					return { rcptlist: null, msg: t("There are places where the contractor's name information is not specified. Enter a name or click the x in the top right to delete unnecessary contractors") };
				}
			}
		}

		// contact가 같은 것이 있는지 조사
		for (let k0 = 0; k0 < rcptlist.length; k0++) {
			const rcpt0 = rcptlist[k0];
			for (let k1 = k0 + 1; k1 < rcptlist.length; k1++) {
				const rcpt1 = rcptlist[k1];
				if (rcpt0.contact === rcpt1.contact) {
					return { rcptlist: null, msg: t("There is a place where the contractor's contact information is the same. If the contacts are the same, they are considered the same person. Please check that contact information is entered correctly") };
				}
			}
		}

		return { rcptlist: rcptlist, msg: null };
	}

	function is_owner(rcpt) {
		return (user.userData.email === rcpt.contact);
	}

	// recipient를 저장 : email 인지 phone인지를 알아내고 method field에 적어줌
	async function onClickNext() {
		const newlist = [];
		const tcheck = validity_check();
		if (tcheck.msg !== null) {
			alert(tcheck.msg);
			return;
		}

		const rcptlist = tcheck.rcptlist;
		if (rcptlist.length > 0) {
			for (let k = 0; k < rcptlist.length; k++) {
				//const rcpt = {...rcptlist[k]};   //deep copy
				const rcpt = rcptlist[k];
				rcpt.method = helper.email_or_phone(rcpt.contact);
				const tis_owner = is_owner(rcpt);   // contact와 login user를 비교해서 is_owner를 설정
				rcpt.is_owner = tis_owner;   // contact와 login user를 비교해서 is_owner를 설정
				if (tis_owner) {
					rcpt.sign_url = await helper.get_default_sign();
					rcpt.rcpt_agree = 1;		// 0:unknown, 1:agree, 2:reject
				} else {
					rcpt.sign_url = '';			//서명이 비어 있으면 서버에서 save_recipient할 때, '{name}의 서명위치' 라는 이미지를 만들어서 삽입함.
				}

				newlist.push(rcpt);
			}
			await helper.save_recipient(docuId, newlist, 'DOCU_STATUS.AddRcpt');   // new_state=2
		}

		props.history.push(`/home/make/vcontract/vcagree/${docuId}`);
	}

	const renderCards = RecipientList && RecipientList.map((recipient, index) => {
		return (
			<RecipientCard recipient={recipient} index={index} key={index} setValue={setValue} AddressBook={AddressBook} onClickRemove={onClickRemove} />
		);
	})


	return (
		<div className="stepForm">
			{/* <Title level={2} className="stepFormTitle">Step 2</Title> */}
			<div className="stepFormCard">
				<Title className="formCardTitle" level={5}>{t("Configuring Contractors (Optional)")} </Title>
				<Text className="formCardSubTitle">{t("If you would like to specify a list of contractors, please fill out the list below. At the end of the contract, an email is automatically sent to each person.")}</Text>
				<div className="addRecipientWrap">
					{renderCards}
				</div>

				<div onClick={onClickAddItem} className="recepientsAdd"><PlusOutlined /></div>
			</div>

			<div className="stepFormBtn">
				<Button onClick={onClickPrev} className="btn preBtn"><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")}</Button>
				<Button onClick={onClickNext} className="btn nextBtn" disabled={!EnableNextButton}>{t("Next")} <ArrowRightOutlined className="arrowIcon" /></Button>
			</div>
		</div>
	);
}

export default withRouter(ProcVCAddRcpt);

import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";

function ProcOutInitModal(props) {
    const { t } = useTranslation();
    const { Text } = Typography;
    function onCancel() {
        props.history.push('/');
    }

    function onOk() {
        props.setState('review');
    }

    return (
        <>
        <Modal
            title={t("Request to Sign")}
            open={true}
            centered ={true}
            cancelText={t("Cancel")}
            okText={t("Sign")}
            width={400}
            destroyOnClose={true}
            onOk={onOk}
            onCancel={onCancel}
            className="customPopup"
        >
            <Text className="signConfirmText">
                {t("You can sign documents on this webpage.")}  <br/>
                {t("Would you like to sign right away?")}
            </Text>
        </Modal>
        </>
    )
}

export default withRouter(ProcOutInitModal);


import React, { useState } from "react";
import axios from "axios";
import { Col, Row, Typography } from "antd";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import videoSignLogo from "../../../assets/images/VideoSign/video-sign-logo.svg";



function Footer() {
  const [Email, SetEmail] = useState("");
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const { i18n } = useTranslation('Home');

  function onChangeEmail(event) {
    SetEmail(event.currentTarget.value);
  }

  function onClickSubscribe() {
    const dataToSubmit = { email: Email };
    axios.post("/api/subscription_news/add_email", dataToSubmit).then((response) => {
      if (response.data.success) {
        alert("success");
      } else {
        alert("Failed to save Faq");
      }
    });
  }

  function onClickBlog(e) {
    e.preventDefault();
    document.location.href = i18n.language==='English'? "https://blog.vidiosign.com/" : "https://blog.jp.vidiosign.com/";
  }

  return (
    <div className="mainfooter">
      <div className="container">
        <Row gutter={16}>
          <Col md={8} xs={24} className="box">
            <Link to={`/`}>
              <img src={videoSignLogo} alt="Logo" className="logo" width={150} />
            </Link>
            <Text>{t("metas@metabox.land")}</Text>
            <Text> {t("Daito Ginza Building, Ginza, Tokyo, Japan")} </Text>
            <Text style={{ marginTop: 16 }}>{t("MetaBox Co., Ltd.")}</Text>
          </Col>

          <Col md={8} xs={12} className="box">
            <Title level={2} className="footerTitle"> {t("Company")} </Title>
            <Link to={"/about"} className="link">{t("About Us")}</Link>
            <Link to={"/faq"} className="link"> {t("FAQ")}</Link>
            <a onClick={onClickBlog} className="link">{t("Blog")}</a>

          </Col>

          <Col md={8} xs={12} className="box">
            <Title className="footerTitle" level={2}>{t("Services")}</Title>
            <Link to={"/eSignature"} className="link">{t("eSignature")}</Link>
            <Link to={"/video-signature"} className="link">{t("Video Signature")}</Link>
            <Link to={"/Video-contract"} className="link">{t("Video Contract")}</Link>
          </Col>

          {/* <Col md={6} xs={24} className="box">
            <Title className="footerTitle" level={4}>Recent Tweets</Title>
            <Title className="recetTweetsTitle" level={5}>May 20, 20222</Title>
            <Text>Lorem ipsum dolor sit amet, <br/> consectetur adipiscing elit</Text>

            <Title className="recetTweetsTitle" level={5}>May 20, 20222</Title>
            <Text>consectetur adipiscing elit</Text>

            <Title className="recetTweetsTitle" level={5}>May 20, 20222</Title>
            <Text>consectetur adipiscing elit</Text>
          </Col> */}
        </Row>
        <Row className="footer-bottom">
          <Col sm={12} xs={24} align="left">
            <Text className="footer-copyright">
              {t("Copyright VidioSign 2023")}
            </Text>
          </Col>
          <Col sm={12} xs={24}>
            <div className="copyRight-link">
              <Link to={"/terms"} className="link">{t("Terms and Conditions")}</Link>
              <Link to={"/privacy"} className="link">{t("Privacy Policy")}</Link>
            </div>
          </Col>

          {/* <Col sm={12} xs={24}>
            <Text className="footerSocial"><FacebookFilled /> <TwitterSquareFilled /> <LinkedinFilled /> <BehanceSquareFilled /></Text>
          </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default withRouter(Footer);

import React, { useState, useEffect } from "react";
import { Button, Spin, Typography } from "antd";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DrawSignPos from '../20_MakeContract/DrawSignPos';
import ModalReject from "./Sections/ModalReject";
import Swal from "sweetalert2";
import { IMG_DISPLAY_HEIGHT, IMG_DISPLAY_MARGIN, IMG_HEIGHT_WITH_PADDING } from "../../utils/size_constants";
import ModalSelectSign from "./Sections/ModalSelectSign";
import utilfunc from "../../utils/utilfunc";
import helper from "../../utils/Helper";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";



// MainOutStartSign-->ProcOutReview
// sign을 하는 페이지
function ProcOutReview(props) {
	const { Text } = Typography;
	const { t } = useTranslation();
	const { i18n } = useTranslation('Home');
	const [RecipientList, setRecipientList] = useState([]);
	const [PngUrlList, setPngUrlList] = useState([]);
    const [PdfLoadDone, setPdfLoadDone] = useState(false);
	const [SelIdx, setSelIdx] = useState(-1);
	const [ButtonList, setButtonList] = useState([]);			//[{index, posx, posy}]
	const [NumSigned, setNumSigned] = useState(0);
	const [NumUnsigned, setNumUnsigned] = useState(1);
	const [ModalRejectVisible, setModalRejectVisible] = useState(false);
	const [Loading, setLoading] = useState(false);
	const [img_display_height, set_img_display_height] = useState(IMG_DISPLAY_HEIGHT);
	const [SelectSignMode, setSelectSignMode] = useState(false);

	// useEffect(() => {
	// 	console.log('ProcOutReview:img_display_height=', img_display_height);
	// }, [img_display_height]);

	useEffect(() => {
		props.Docu.recipient_list && setRecipientList(props.Docu.recipient_list);
	}, [props.Docu.recipient_list]);

	useEffect(() => {
		props.Docu.file_url && showPdf(props.Docu.png_key_list);
	}, [props.Docu.file_url]);

	useEffect(() => {
		if (RecipientList && RecipientList.length > 0 && props.MyIndex >= 0) {
			const pos_info_list = RecipientList[props.MyIndex].pos_info;
			count_num_signed_unsigned(pos_info_list);
		}
	}, [RecipientList, props.MyIndex]);


	async function showPdf(png_key_list) {
        // png가 server에 없다면 s3에서 복사해 와서 준비
        const dataToSubmit = {
            png_key_list: png_key_list,
            password: null,
        };
        const res = await axios.post("/api/docu/prepare_png", dataToSubmit);
        if (!res.data.success) {
            console.log("error: prepare_png:", res.data.errmsg);
            return;
        }

        // download png
        const pnglist = [];
        for (let k = 0; k < png_key_list.length; k++) {
            const path = png_key_list[k];
            pnglist.push(`/api/${path}`);
        }
        setPngUrlList(pnglist);

        // scrollHeight가 반영될 때까지 최대 4초까지 기다림
        const img0 = document.getElementById("imgwh0");
        for (let k = 0; k < 20; k++) {
            if (img0?.clientHeight > 0) {
                console.log("height=", img0, img0.clientHeight);
                break;
            } else {
                // loading 이전
                console.log("First page is not loaded yet");
                await utilfunc.sleep(200);
            }
        }

        console.log("PdfLoadDone  -------------------- true");
        setPdfLoadDone(true);
    }

	function count_num_signed_unsigned(pos_info_list) {
		let num_signed = 0;
		let num_unsigned = 0;
		for (let k = 0; k < pos_info_list.length; k++) {
			if (pos_info_list[k].signed) {
				num_signed++;
			} else {
				num_unsigned++;
			}
		}
		setNumSigned(num_signed);
		setNumUnsigned(num_unsigned);
	}

	async function onClickNext() {
		setLoading(true);		//기다리는 spin 표시
		const tsplit = props.Docu.file_url.split('/');
		const folder_name = 'uploads/' + tsplit[tsplit.length - 1];
		const res = await axios.post('/api/gensign/remove_local_folder', { folder_name });
		if (!res.data.success) {
			alert('Fail to delete local folder');
		}

		setSelIdx(-1);
		setLoading(false);
		const rcpt = RecipientList[props.MyIndex];
		const result = props.final_agree(rcpt);
	}

	// if cancel in modal... modal 에서 취소하면
	function onCancelReject() {
		setModalRejectVisible(false);
	}

	// if OK in modal... modal 에서 OK하면
	async function onOkReject(value) {
		const name = props.Docu.recipient_list[props.MyIndex].name;
		const contact = props.Docu.recipient_list[props.MyIndex].contact;
		const action = 'reject';
		const ipaddr = 'unknown';
		const machine = 'unknown';

		const dataToSubmit = {
			docuId: props.Docu._id,
			myindex: props.MyIndex,
			reason: value,
			push_history: { name, contact, action, ipaddr, machine },
			lang : i18n.language
		}
		const res = await axios.post('/api/docuouter/reject', dataToSubmit);

		if (res.data.success) {
			Swal.fire({
				icon: "success",
				title: "Signing Denied.",
				text: `${name} refused to sign contract ${props.Docu.title}.`,
				confirmButtonText: t('Go to Home')
			});
			props.history.push('/');
		}
		return true;
	}

  //////////////////////////////////////////////////////////////

	const render_png_pc = PngUrlList && PngUrlList.map((pngurl, index) => {
		return (
			<div key={1000+index}>
			<img src={pngurl} height={IMG_HEIGHT_WITH_PADDING} 
                id={`imgwh${index}`}
				style={{ 
					margin: `${IMG_DISPLAY_MARGIN}px 0`,
					border: "1px solid #d3d3d3",
					}} 
				alt="signPdfImg">
			</img>
			</div>
		)
	})

	const render_png_mobile = PngUrlList && PngUrlList.map((pngurl, index) => {
		return (
			<div key={2000+index} className="pdfMobile">
			<img src={pngurl} onLoad={onLoadImg}
                id={`imgwh${index}`}

				style={{ 
					padding: `${IMG_DISPLAY_MARGIN}px 0 ${IMG_DISPLAY_MARGIN}px 0`, 
					width:'380px', 
					border: "1px solid #d3d3d3",
				}} 
				alt="signPdfImg">
			</img>
			</div>
		)
	})



	// function onLoadImg(e) {
	// 	const el=document.getElementById("scroll_window");
	// 	const timg_display_height = (el.scrollHeight/PngUrlList.length - 2*IMG_DISPLAY_MARGIN );
	// 	set_img_display_height(timg_display_height);
	// }

	function get_img_display_height() {
        const img0 = document.getElementById("imgwh0"); //todo: 현재는 모든 페이지의 높이가 0페이지와 같다고 가정하고 한 것임.  수정필요.
        const timg_display_height = img0? img0.clientHeight:0;
        return timg_display_height;
    }


	function onLoadImg(e) {
        const img0 = document.getElementById("imgwh0");
        const timg_display_height = img0? img0.clientHeight:0;
		set_img_display_height(timg_display_height);
	}

	// function onClickLink(pdf_posinfo) {
	// 	const el=document.getElementById("scroll_window");
	// 	// const timg_display_height = (el.scrollHeight/PngUrlList.length - 2*IMG_DISPLAY_MARGIN );
	// 	// console.log('link:', refContainer.current.scrollHeight/PngUrlList.length - 2*IMG_DISPLAY_MARGIN , timg_display_height);
	// 	// set_img_display_height(timg_display_height);
	// 	const [xpos, ypos, sign_height_screen] = docuutil.pdf_posinfo_to_image_pos(pdf_posinfo, img_display_height);
	// 	console.log('onClickLink', xpos, ypos, sign_height_screen, img_display_height);
	// 	el.scrollTo({ top: ypos, behavior: 'smooth' });
	// 	//document.getElementById('scroll_window').scrollTo({ top: ypos, behavior: 'smooth' });
	// }

	// function get_scroll_window() {
	// 	const el = (window.innerWidth<900)? document.getElementById("scroll_window_mobile") : document.getElementById("scroll_window_pc");
	// 	//console.log('get_scroll_window: scroll height = ', el.scrollHeight);
	// 	return el;
	// }

  ////////////// 서명할 위치 Button
	useEffect(() => {
	if (props?.Docu?.recipient_list && props?.MyIndex >= 0) {
		setButtonList(props.Docu.recipient_list[props.MyIndex].pos_info);
	}
	}, [props?.Docu?.recipient_list, props?.MyIndex]);



//   function button_list(key_prefix) { 
// 		return (
// 			ButtonList && ButtonList.map((buttonpos, index) => {
// 				const is_signed = buttonpos?.signed;
// 				const tkey = key_prefix + index;
// 				return (
// 					<div className="btnListWrap" key={tkey}>
// 						<Button onClick={(index) => onClickLink(buttonpos)} style={{ margin: '5px' }}>
// 						{index} (page:{buttonpos.sign_pos_page})
// 						</Button>
// 						{is_signed ? <CheckCircleTwoTone style={{ fontSize: '150%' }} /> : ''}
// 					</div>
// 				)
// 			})
// 		);
// 	}

	// 한번에 모두 서명 버튼을 누르면
	async function onClickOneClickSign() {
		//이 함수에서 사인을 바꾸어주고 서명했음을 저장해주면 됨.
		console.log('onClickOneClickSign called');

		const uidx = props.MyIndex;
		const rcpt = RecipientList[uidx];
		rcpt.sign_url = await helper.chooseSign(props.UserDbId);
		const pos_info_list = rcpt.pos_info;
		for (let k = 0; k < pos_info_list.length; k++) {
			//pos_info_list[k].sign_height = DEFAULT_SIGN_HEIGHT;		// 잘못된 계산이며 올바른 height가 이미 적혀져 있을 것임
			pos_info_list[k].signed = true;
		}
		const newlist = [...RecipientList.slice(0, uidx), rcpt, ...RecipientList.slice(uidx + 1, RecipientList.length)];
		setRecipientList(newlist);
	}

	// 서명 수정 버튼을 누르면
  	function onClickEditSign() {
		setSelectSignMode(true);
  	}

	//ModalSelectSign에서 sign을 바꾸면 이 함수가 불림
	function changeSign(sign_url) {
		if (sign_url===null){		// cancel인 경우 null로 들어옴
			setSelectSignMode(false);
		} else {
			setSelectSignMode(false);
			_change_sign_url(props.MyIndex, sign_url);
		}
	}
  
	function _change_sign_url(index, sign_url) {
		const rcpt = RecipientList[index];
		rcpt.sign_url = sign_url;
		const newlist = [...RecipientList.slice(0, index), rcpt, ...RecipientList.slice(index + 1, RecipientList.length)];
		setRecipientList(newlist);
	}

	function nth_term(num) {
		const tterm = 
			(num==1)?		'1st' :
			(num==2)?		'2nd' : `${num}-th`;
		return tterm;
	}

	const pcView = () => {
		return (
			<>
					<div className="docFileImg">
						<Spin spinning={PngUrlList.length == 0} size='large'>
							<div id='scroll_window' style={{ position: 'relative', height: '600px' }}>
								{render_png_pc}
				
								{PdfLoadDone && <DrawSignPos RecipientList={RecipientList}
									onClickDelete={null}
									UserDbId={props.UserDbId}
									MyIndex={props.MyIndex}
									img_display_height = {get_img_display_height()}
									isMobile = {isMobile}
								/>}
							</div>
						</Spin>
					</div>
					<div align="center" style={{marginTop:20}}>
						<Text className="textSecondSinger">{t("You are")} {nth_term(props.MyIndex+1)} {t("Contractor. If you agree with contract, click the 'Sign All' button.")}</Text>
						<div className="cardMultiBtn">
							<Button type='primary' className="primaryBtn smallBtn" onClick={onClickOneClickSign}>{t("Sign All")}</Button>
							<Button type='primary' className="primaryBtn smallBtn" onClick={onClickEditSign}>{t("Edit Sign")}</Button>
							<Button type='primary' className="primaryBtn smallBtn" onClick={() => setModalRejectVisible(true)}>{t("Deny")}</Button>
						</div>
					</div>
			</>
		);
	  }

	  const mobileView = () => {
		return (
			<>
					<div className="docFileImgMobile">
						<Spin spinning={PngUrlList.length == 0} size='large'>
							<div id='scroll_window' style={{ position: 'relative', height: '600px' }}>
								{render_png_mobile}
				
								<DrawSignPos RecipientList={RecipientList}
									onClickDelete={null}
									UserDbId={props.UserDbId}
									MyIndex={props.MyIndex}
									img_display_height = {get_img_display_height()}
									isMobile = {isMobile}
								/>
							</div>
						</Spin>
					</div>
					<div align="center" style={{marginTop:20}}>
						<Text className="textSecondSinger">{t("You are")} {nth_term(props.MyIndex+1)} {t("Contractor. If you agree with contract, click the 'Sign All' button.")}</Text>
						<div className="cardMultiBtn">
							<Button type='primary' className="primaryBtn smallBtn" onClick={onClickOneClickSign}>{t("Sign All")}</Button>
							<Button type='primary' className="primaryBtn smallBtn" onClick={onClickEditSign}>{t("Edit Sign")}</Button>
							<Button type='primary' className="primaryBtn smallBtn" onClick={() => setModalRejectVisible(true)}>{t("Deny")}</Button>
						</div>
					</div>
			</>
		);
	  }

	return (
		<>
			<div className="stepForm setPOSPage">
				<div className="stepFormBtn smallHide">
					<Button className="btn preBtn" disabled={true}><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")}</Button>
				</div>
				<div className="stepFormCard">
					{isMobile? mobileView() : pcView()}
				</div>
				<div className="stepFormBtn smallHide">
					<div className="stepSpinBtn">
						<Spin spinning={Loading} delay={500}>
							<Button className="btn nextBtn" onClick={onClickNext} disabled={NumUnsigned > 0}>{t("Next")} <ArrowRightOutlined className="arrowIcon" /></Button>
						</Spin>
					</div>
				</div>
			</div>
			<div className="stepFormBtn smallShow">
				<Button className="btn preBtn" disabled={true}><ArrowLeftOutlined className="arrowIcon" /> {t("Previous")}</Button>
				<div className="stepSpinBtn">
					<Spin spinning={Loading} delay={500}>
						<Button className="btn nextBtn" onClick={onClickNext} disabled={NumUnsigned > 0}>{t("Next")} <ArrowRightOutlined className="arrowIcon" /></Button>
					</Spin>
				</div>
			</div>

		<ModalReject visible={ModalRejectVisible} onCancel={onCancelReject} onOk={onOkReject} explain={t("If you decline a signature request, the contract will be terminated and the reason for the refusal will be sent to all signing participants.")}/>
		<ModalSelectSign visible={SelectSignMode} changeSign={changeSign} UserDbId={props.UserDbId}/>
		</>
	);

}

export default withRouter(ProcOutReview);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, withRouter, Link } from "react-router-dom";
import { CloseOutlined, MenuOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from 'antd';
import videoSignLogo from "../../../../assets/images/VideoSign/video-sign-logo.svg";


function RightMenu(props) {

    const { t } = useTranslation();
    const { language } = props;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        if (!isDrawerOpen) return;
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsDrawerOpen(!isDrawerOpen);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, isDrawerOpen]);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const [isActive, setActive] = useState("false");
    const ShowShbMenu = () => setActive(!isActive);

    function onClickBlog(e) {
      e.preventDefault();
      document.location.href = language==='en'? "https://blog.vidiosign.com" : "https://blog.jp.vidiosign.com";
    }


  const items = [
    {
      key: '1',
      className: 'dropdownItems',
      label: (
        <NavLink rel="noopener noreferrer" to="eSignature">
          {t("eSignature")}
        </NavLink>
      ),
    },
    {
      key: '2',
      className: 'dropdownItems',
      label: (
        <NavLink rel="noopener noreferrer" to="video-signature">
          {t("Video Signature")}
        </NavLink>
      ),
    },
    {
      key: '3',
      className: 'dropdownItems',
      label: (
        <NavLink rel="noopener noreferrer" to="Video-contract">
          {t("Video Contract")}
        </NavLink>
      ),
    },
  ];

    return (
        <div>
            <div className="c-nav-bar">
                <NavLink exact className="c-nav-link" to={`/${language}/`}>{t("Home")}</NavLink>
                <NavLink exact className="c-nav-link" to={`/${language}/login`}>{t("Sign in")}</NavLink>
                <NavLink exact className="c-nav-link" to={`/${language}/register`}>{t("Sign up")}</NavLink>
                <Dropdown className="menuDropdown" placement="bottomRight" menu={{ items }}>
                    <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {t("Services")}
                        <DownOutlined />
                    </Space>
                    </a>
                </Dropdown>
                <a className="c-nav-link" onClick={onClickBlog}>{t("Blog")}</a>
                <NavLink exact className="c-nav-link" to={`/${language}/faq`}>{t("Faq")}</NavLink>
                <NavLink exact className="c-nav-link" to={`/${language}/plans`}>{t("Plans")}</NavLink>
            </div>

            <div className="c-menu-icon" onClick={toggleDrawer}>
                {
                    isDrawerOpen ? <CloseOutlined /> : <MenuOutlined />
                }
            </div>

            <div ref={menuRef} className={isDrawerOpen ? "c-m-nav-bar show" : "c-m-nav-bar"}>
                <Link className="mobileSideLogo" to={`/${language}/`}>
                    <img src={videoSignLogo} alt="Logo" className="logo" width={170} />
                </Link>
                <NavLink exact className="c-nav-link" to={`/${language}`}>{t("Home")}</NavLink>
                <NavLink exact className="c-nav-link" to={`/${language}/login`}>{t("Sign in")}</NavLink>
                <NavLink exact className="c-nav-link" to={`/${language}/register`}>{t("Sign up")}</NavLink>
                <div className="serviceMenuMobile">
                  <span className="serviceText" onClick={ShowShbMenu}>{t("Services")} <DownOutlined /></span>
                  <div className={isActive ? "servicesMenuWrap" : "servicesMenuWrap show"}>
                    <NavLink exact className="c-nav-link" to={`/${language}/eSignature`}>{t("eSignature")}</NavLink>
                    <NavLink exact className="c-nav-link" to={`/${language}/video-signature`}>{t("Video Signature")}</NavLink>
                    <NavLink exact className="c-nav-link" to={`/${language}/Video-contract`}>{t("Video Contract")}</NavLink>
                  </div>
                </div>
                <a className="c-nav-link" onClick={onClickBlog}>{t("Blog")}</a>
                <NavLink exact className="c-nav-link" to={`/${language}/faq`}>{t("Faq")}</NavLink>
                <NavLink exact className="c-nav-link" to={`/${language}/plans`}>{t("Plans")}</NavLink>
            </div>
        </div>
    );
}

export default withRouter(RightMenu);
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Typography, Tooltip } from "antd";
import axios from "axios";
import ModalShowDocuInfo from '../ModalShowDocuInfo';
import ModalInfoSign from '../ModalInfoSign';
import docuutil from '../../../utils/DocuUtil';
import { useTranslation } from "react-i18next";
import DisplayDocuList from "../../../utils/DisplayDocuList";
import { DownloadOutlined, MailOutlined, DeleteOutlined } from '@ant-design/icons';



// main page of document
// 현재 등록된 계약들을 보여주고 그 계약을 클릭하면 계속 진행하는 화면으로 전환
function DocuSigned(props) {
    const { Title } = Typography;
    const { t } = useTranslation();
    const {i18n} = useTranslation("Home");
    const lang = i18n.language;
    const {docutype} = props;
    const title = docutype==='signed'? t('Signed') : t('Pending');
    const [DocuList, setDocuList] = useState(null);     // 문서 리스트에서 현재 선택된 문서
    const [CurrentDocu, setCurrentDocu] = useState(null);     // 문서 리스트에서 현재 선택된 문서
    const [UserContact, setUserContact] = useState(null);   // 현재 사용자의 연락정보
    const [RefreshDocu, setRefreshDocu] = useState(true);       // 완료된 문서가 다 만들어졌는지 체크하기 위해서
    
  	// 현재 사용자의 email 과 phone을 저장
	useEffect(()=>{
        async function get_user_contact() {
            const res = await axios.post('/api/users/get_user_fields', {fields:'email phone'});
            if (res.data.success) {
                const user_contact = {email:res.data.result.email, phone:res.data.result.phone};
                setUserContact(user_contact);
            } else {
                console.log('ERROR:get_user_contact');
                setUserContact(null);
            }
        }
        get_user_contact();
	}, []);

    useEffect(() => {
        async function f() {
            if (!RefreshDocu){
                return;
            }
            console.log('docu refreshing............');
            const doculist = await docuutil.get_doculist_signed_pending(docutype); //현재 user가 생성한 docu와 recipient에 속한 docu를 내림차순으로 sorting하여 return        
            if (doculist){
                setDocuList(doculist);
            }
            setRefreshDocu(false);
        }
        f();
    }, [RefreshDocu]); 

    function refresh() {
        console.log('setRefreshDocu(true)');
        setRefreshDocu(true);
    }

    const render_docu = DocuList && DocuList.map(function(docu, index) {
        const myidx = docuutil.get_my_index(docu, UserContact);
        //const show_detail = (docu.status!=='DOCU_STATUS.Upload' && docu.status!=='DOCU_STATUS.AddRcpt' && docu.status!=='DOCU_STATUS.SetPos');
        return (
        <div className="docCard" key={index}>
           
                <div className="docInner">
                 
                    <div className="docCardContent">
                        <DisplayDocuList docu={docu} refresh={refresh}/>
                    </div>
                
                    <div className="actionBtnWrap">
                        <Tooltip placement="top" title={t("Download")}>
                            <Button className="actionDownload" onClick={(e) => docuutil.onClickDownload(e, docu)}>
                                <DownloadOutlined />
                            </Button>
                        </Tooltip>
                        
                        {docu.status==='DOCU_STATUS.Completed' && 
                        <Tooltip placement="top" title={t("Mail")}>
                            <Button className="actionMail" onClick={(e) => docuutil.onClickEmail(e, docu, t, lang)}>
                                <MailOutlined />
                            </Button>
                        </Tooltip>
                        }

                        <Tooltip placement="top" title={t("Delete")}>
                            <Button className="actionDelete" onClick={(e) => docuutil.onClickDelete(e, docu, refresh, t)}>
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </div>

                    <div className="docCardWrap">
                        <ModalShowDocuInfo docu={docu} onClickMore={()=>setCurrentDocu(docu)} MyIndex={myidx}/>
                    </div>
              </div>
        </div>)}
    );

    return (
        <div className="tabContent">
            <Title className="tabTitle" level={2}>{title}</Title>
            {/* <div dangerouslySetInnerHTML={{__html:'<h1>aaa</h1><h5>bbb</h5>'}}></div> */}
            {render_docu}

            <ModalInfoSign onCancel={()=>setCurrentDocu(null)} docu={CurrentDocu}/>
        </div>
    );

}

export default withRouter(DocuSigned);

import React, { useState, Fragment } from 'react';
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';

const LandingLogin = () => {

    const location = useLocation();

    const { Text, Title, Paragraph } = Typography;
    const { t } = useTranslation();

    const [counter, setCounter] = useState(0);

    const [expandIndex, setExpandIndex] = useState({
        0: false,
        1: false,
        2: false
    });

    const onExpand = (e, index) => {

        e.preventDefault();

        setExpandIndex({ ...expandIndex, [index]: true });
        setCounter(!expandIndex[index] ? counter + 0 : counter + 1);
    };

    const onClose = (e, index) => {

        e.preventDefault();

        setExpandIndex({ ...expandIndex, [index]: false });
        setCounter(!expandIndex[index] ? counter + 0 : counter + 1);
    };

    const records = [
        {
            title: t("E-Sign Contract"),
            toUrl: `${location.pathname}/make/esign/upload/docuId`,
            description: t("VidioSign is the easiest and fastest electronic contract. The contract is completed with one click. The completed contract is converted into NFT and can be received together with a certificate. "),
            moreDescription: t("Because NFT contracts cannot be tampered with, they can receive stronger legal protection than any other electronic contracts.")
        },
        {
            title: t("Video Sign Contract"),
            toUrl: `${location.pathname}/make/vsign/upload/docuId`,
            description: t("This contract is used when stronger legal protection is required. An electronic contract in which the signature includes a video of the contractors. Includes videos of contractors saying "),
            moreDescription: t("'I accept this agreement' as they sign the contract. Video consent is the strongest legal evidence in any country. Sometimes it can be presented as stronger evidence than notarization.")
        },
        {
            title: t("Video Contract"),
            toUrl: `${location.pathname}/make/vcontract/vcrecord/docuId`,
            description: t("No need to write a contract. Contractors gather and easily record the contents of the contract with a mobile phone and upload it as a video. The video is converted into "),
            moreDescription: t("a confidential NFT and becomes a contract. This video is sent to each contractor by email after being converted into an electronic contract certificate. Contractors can use this video contract because it is cumbersome to fill out paper contracts or electronic contracts every time. It is one of the fastest, easiest and most reliable means of legal protection with a new concept contract method.")
        }
    ];

    return (
        <div className="dashboardContentWrap">
            <div className='dashboardContent'>
                <div className="darboartTitleWrap">
                    <Title align="center" className="dashBoardPageTitle" level={2}>{t("Please Select the Type of Contract")}</Title>
                </div>
                <Row gutter={24}>
                    {
                        records.map((record, index) => {

                            const { title, toUrl, description, moreDescription } = record;

                            return (
                                <Col md={8} align="center" key={index}>
                                    <div className='dashboardCard' key={counter}>
                                        <Link className='fullLink' to={toUrl}></Link>
                                        <div className="cardTitle">{title}</div>
                                        <Text className="cardDesWrap">
                                            <Paragraph className="cardDes">
                                                {description}
                                                
                                                {
                                                    (expandIndex[index] === true) ? <Fragment>
                                                        {moreDescription}
                                                        <a className='readMore' href='/#' onClick={(e) => onClose(e, index)}>{t('Read less')}</a>
                                                    </Fragment> : <a className='readMore' href='/#' onClick={(e) => onExpand(e, index)}>{t('Read more')}</a>
                                                }

                                            </Paragraph>

                                        </Text>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </div>
        </div>
    );
};

export default LandingLogin;